import React, { FC } from "react";
import {
  List,
  ListItemButton,
  ListItemContent,
  ListItemDecorator,
  Sheet,
  styled,
} from "@mui/joy";
import { NavLink, useLocation } from "react-router-dom";
import EmojiEventsOutlinedIcon from "@mui/icons-material/EmojiEventsOutlined";
import SupervisedUserCircleOutlined from "@mui/icons-material/SupervisedUserCircleOutlined";
import StarsOutlined from "@mui/icons-material/StarsOutlined";
import HomeWorkOutlined from "@mui/icons-material/HomeWorkOutlined";

const ROUTES = [
  {
    title: "Challenges",
    icon: <EmojiEventsOutlinedIcon />,
    link: "/challenges",
  },
  {
    title: "People",
    icon: <SupervisedUserCircleOutlined />,
    link: "/people",
  },
  {
    title: "Rewards",
    icon: <StarsOutlined />,
    link: "/rewards",
  },
  {
    title: "Organization",
    icon: <HomeWorkOutlined />,
    link: "/organization",
  },
];

export const Tabs: FC = () => {
  const { pathname } = useLocation();

  return (
    <Container>
      <List orientation="horizontal" size="sm" sx={{ px: 0 }}>
        {ROUTES.map((route) => {
          const selected = pathname === route.link;

          return (
            <ListItemButton
              orientation="vertical"
              key={`sidebar-link-${route.link}`}
              color={selected ? "primary" : "neutral"}
              selected={selected}
              sx={{ flex: 1, gap: 2, px: 0, py: 2 }}
              component={NavLink}
              to={route.link}
            >
              <ListItemDecorator>{route.icon} </ListItemDecorator>
              <ListItemContent>{route.title}</ListItemContent>
            </ListItemButton>
          );
        })}
      </List>
    </Container>
  );
};

const Container = styled(Sheet)`
  width: 100vw;
  position: fixed;
  bottom: 0;
  z-index: 999;
  background-color: white;
  border-top: 1px solid ${({ theme }) => theme.palette.neutral["200"]};

  ${({ theme }) => theme.breakpoints.up("md")} {
    display: none;
  }
`;
