import React, { FC, useCallback, useState } from "react";
import { DEFAULT_LIMIT } from "@constants";
import useLoadData from "@/hooks/useLoadData";
import { ITeam } from "@store/team/model";
import { useAppSelector } from "@store/hooks";
import { TableBody, TableContainer } from "@mui/material";
import { Header } from "@components/table/Header";
import {
  Avatar,
  Button,
  Sheet,
  Stack,
  styled,
  Table,
  Typography,
} from "@mui/joy";
import { Footer } from "@components/table/Footer";
import Box from "@mui/joy/Box";
import IconButton from "@mui/joy/IconButton";
import {
  AddCircleOutlineOutlined,
  DeleteOutlined,
  GroupAddOutlined,
  RemoveCircleOutlineOutlined,
  SearchOutlined,
} from "@mui/icons-material";
import { DebounceInput } from "@components/form/DebounceInput";
import Divider from "@mui/joy/Divider";
import { useDispatch } from "react-redux";
import { Autocomplete } from "@components/Autocomplete";
import { IUserTeam } from "@store/user/model";
import {
  getUserTeams,
  joinUserTeams,
  leaveUserTeams,
} from "@store/user/actions";
import {
  selectUserTeams,
  selectUserTeamsPagination,
} from "@store/user/selectors";
import { getTeams } from "@services/api/team";
import { Placeholder } from "@components/table/Placeholder";
import { EmptyState } from "@components/EmptyState";

type TeamsTableProps = {
  id: string;
};

const headCells = ["Team"];
const headCellsSize = headCells.length + 1;

export const ManageTeamsTable: FC<TeamsTableProps> = (props) => {
  const { id } = props;
  const [search, setSearch] = useState("");
  const [isAdding, setIsAdding] = useState(false);
  const [loading, setLoading] = useState<string | boolean>(false);
  const [selectedToJoinTeams, setSelectedToJoinTeams] = useState<ITeam[]>([]);
  const dispatch = useDispatch<any>();
  const { loaded, handlePaginate } = useLoadData<IUserTeam>(
    getUserTeams,
    search,
    {
      userId: id,
    },
  );

  const teams = useAppSelector(selectUserTeams);
  const { totalPages, page } = useAppSelector(selectUserTeamsPagination);

  const handleLeaveMember = useCallback((team: IUserTeam) => {
    setLoading(team.id);
    dispatch(
      leaveUserTeams(
        {
          userId: id,
          teams: [team.id],
        },
        () => {
          setLoading(false);
        },
      ),
    );
  }, []);

  const handleJoinMembers = useCallback(() => {
    setLoading(true);
    dispatch(
      joinUserTeams(
        {
          userId: id,
          teams: selectedToJoinTeams.map((team: ITeam) => team.id),
        },
        () => {
          setLoading(false);
          setIsAdding(false);
          setSelectedToJoinTeams([]);
        },
      ),
    );
  }, [selectedToJoinTeams, id]);

  const handleToggleAdd = useCallback(() => {
    setIsAdding(!isAdding);
  }, [isAdding]);

  return (
    <Stack gap={6}>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography fontSize={24} fontWeight="bold">
          Teams
        </Typography>
        <IconButton
          size="lg"
          sx={{ boxShadow: "sm" }}
          color="neutral"
          onClick={handleToggleAdd}
        >
          {isAdding ? (
            <RemoveCircleOutlineOutlined />
          ) : (
            <AddCircleOutlineOutlined />
          )}
        </IconButton>
      </Stack>
      {isAdding ? (
        <Stack gap={4}>
          <Autocomplete
            action={getTeams}
            getOptionLabel={(team: ITeam) => team.name}
            getOptionImage={(team: ITeam) => team.logo}
            value={selectedToJoinTeams}
            onChange={setSelectedToJoinTeams}
            multiple
          />
          <Stack gap={4} direction="row">
            <Button
              color="primary"
              variant="solid"
              loading={!!loading}
              sx={{ boxShadow: "sm", flex: 1 }}
              startDecorator={<GroupAddOutlined />}
              onClick={handleJoinMembers}
            >
              Add
            </Button>
            <Button
              color="neutral"
              variant="plain"
              sx={{ boxShadow: "sm", flex: 1 }}
              onClick={handleToggleAdd}
            >
              Cancel
            </Button>
          </Stack>
        </Stack>
      ) : null}
      {loaded && teams?.items?.length === 0 ? (
        <EmptyState description="No teams has been added yet." />
      ) : (
        <Sheet
          variant="outlined"
          sx={{
            width: "100%",
            boxShadow: "sm",
            borderRadius: "xl",
            overflow: "hidden",
          }}
        >
          <Box p={6} sx={{ bgcolor: "white" }}>
            <Input
              variant="plain"
              handleDebounce={setSearch}
              placeholder="Search..."
              startDecorator={<SearchOutlined />}
              sx={{ boxShadow: "sm" }}
            />
          </Box>
          <Divider />
          <TableContainer>
            <Table>
              <Header headCells={headCells} />
              <TableBody>
                {teams.items?.map((team) => {
                  return (
                    <tr key={team.id}>
                      <td>
                        <Stack direction="row" gap={3} alignItems="center">
                          <Avatar alt={team.name} src={team.logo} />
                          <Box>
                            <Typography fontWeight="500" fontSize="sm">
                              {team.name}
                            </Typography>
                          </Box>
                        </Stack>
                      </td>
                      <td align="right">
                        <IconButton
                          size="lg"
                          sx={{ boxShadow: "sm" }}
                          color="neutral"
                          loading={loading === team.id}
                          onClick={() => handleLeaveMember(team)}
                        >
                          <DeleteOutlined />
                        </IconButton>
                      </td>
                    </tr>
                  );
                })}
                {!teams?.total ? (
                  <Placeholder headCellsSize={headCellsSize} />
                ) : null}
              </TableBody>
              <Footer
                headCellsSize={headCellsSize}
                currentPage={page}
                totalPages={totalPages}
                onPaginate={handlePaginate}
              />
            </Table>
          </TableContainer>
        </Sheet>
      )}
    </Stack>
  );
};
const Input = styled(DebounceInput)`
  flex: 1;
`;
