import {
  ActionCallback,
  ActionTypeOnly,
  FetchCallback,
  IDataList,
  Nullable,
} from "@store/model";
import {
  ActionType,
  IDepartment,
  IDepartmentsParams,
  IDepartmentInfoParams,
  ICreateDepartmentPayload,
  IDeleteDepartmentParams,
  IDepartmentMembersParams,
  IDepartmentMember,
  IUpdateDepartmentPayload,
  IJoinDepartmentMembersPayload,
} from "@store/department/model";

export const getDepartments = (
  payload: IDepartmentsParams,
  callback?: FetchCallback,
): ActionCallback<IDepartmentsParams> => ({
  type: ActionType.GET_DEPARTMENTS,
  payload,
  callback,
});

export const getDepartmentInfo = (
  payload: IDepartmentInfoParams,
  callback?: FetchCallback,
): ActionCallback<IDepartmentInfoParams> => ({
  type: ActionType.GET_DEPARTMENT_INFO,
  payload,
  callback,
});

export const getDepartmentMembers = (
  payload: IDepartmentMembersParams,
  callback?: FetchCallback,
): ActionCallback<IDepartmentMembersParams> => ({
  type: ActionType.GET_DEPARTMENT_MEMBERS,
  payload,
  callback,
});

export const createDepartment = (
  payload: ICreateDepartmentPayload,
  callback?: FetchCallback,
): ActionCallback<ICreateDepartmentPayload> => ({
  type: ActionType.CREATE_DEPARTMENT,
  payload,
  callback,
});

export const updateDepartment = (
  payload: IUpdateDepartmentPayload,
  callback?: FetchCallback,
): ActionCallback<IUpdateDepartmentPayload> => ({
  type: ActionType.UPDATE_DEPARTMENT,
  payload,
  callback,
});

export const deleteDepartment = (
  payload: IDeleteDepartmentParams,
  callback?: FetchCallback,
): ActionCallback<IDeleteDepartmentParams> => ({
  type: ActionType.DELETE_DEPARTMENT,
  payload,
  callback,
});

export const joinDepartmentMembers = (
  payload: IJoinDepartmentMembersPayload,
  callback?: FetchCallback,
): ActionCallback<IJoinDepartmentMembersPayload> => ({
  type: ActionType.JOIN_DEPARTMENT_MEMBERS,
  payload,
  callback,
});

export const leaveDepartmentMembers = (
  payload: IJoinDepartmentMembersPayload,
  callback?: FetchCallback,
): ActionCallback<IJoinDepartmentMembersPayload> => ({
  type: ActionType.LEAVE_DEPARTMENT_MEMBERS,
  payload,
  callback,
});

export const setDepartments = (
  payload: Nullable<IDataList<IDepartment>>,
  callback?: FetchCallback,
): ActionCallback<IDataList<IDepartment>> => ({
  type: ActionType.SET_DEPARTMENTS,
  payload,
  callback,
});

export const setDepartmentInfo = (
  payload: Nullable<IDepartment>,
  callback?: FetchCallback,
): ActionCallback<IDepartment> => ({
  type: ActionType.SET_DEPARTMENT_INFO,
  payload,
  callback,
});

export const setDepartmentMembers = (
  payload: Nullable<IDataList<IDepartmentMember>>,
  callback?: FetchCallback,
): ActionCallback<IDataList<IDepartmentMember>> => ({
  type: ActionType.SET_DEPARTMENT_MEMBERS,
  payload,
  callback,
});

export const resetDepartment = (): ActionTypeOnly => ({
  type: ActionType.RESET,
});
