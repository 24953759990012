import React, { FC } from "react";
import { Stack, styled, Typography } from "@mui/joy";
import Card from "@mui/joy/Card";
import CardContent from "@mui/joy/CardContent";

type EmptyStateProps = {
  description: string;
};

export const EmptyState: FC<EmptyStateProps> = (props) => {
  const { description } = props;

  return (
    <Card sx={{ p: 6, borderRadius: "xl" }}>
      <CardContent>
        <Stack gap={3} justifyContent="center" alignItems="center">
          <Image src="/assets/images/empty.png" />
          <Typography level="title-md" fontWeight="400">
            {description}
          </Typography>
        </Stack>
      </CardContent>
    </Card>
  );
};

const Image = styled("img")`
  width: 80px;
  height: 80px;
`;
