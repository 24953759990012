import React, { FC, useCallback, useEffect, useState } from "react";
import { capitalize } from "lodash";
import { useDispatch } from "react-redux";
import {
  DialogContent,
  Option,
  Drawer,
  FormControl,
  FormLabel,
  IconButton,
  Select,
  Sheet,
  Stack,
  Typography,
  RadioGroup,
  Radio,
  Grid,
  Button,
  FormHelperText,
  styled,
} from "@mui/joy";
import { Close, DeleteOutlined } from "@mui/icons-material";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { JoyDateRangePicker } from "@components/form/DateRangePicker";
import { ChallengeType } from "@constants";
import moment, { Moment } from "moment";
import {
  createChallenge,
  deleteChallenge,
  updateChallenge,
} from "@store/challenge/actions";
import { DateRange } from "@mui/x-date-pickers-pro";
import { TextInput } from "@components/form/TextInput";
import { IChallenge } from "@store/challenge/model";
import { TextareaInput } from "@components/form/TextareaInput";
import Box from "@mui/joy/Box";
import { useConfirm } from "mui-joy-confirm";
import { SelectInput } from "@components/form/SelectInput";

type ChallengeDrawerProps = {
  open: boolean;
  editing?: IChallenge;
  onClose: (type?: "edit" | "delete") => void;
};

type Recurring = "none" | "repeated";

type Inputs = {
  name: string;
  type: ChallengeType;
  description?: string;
  recurring: Recurring;
  dates: DateRange<Moment>;
};

const validationSchema = yup
  .object({
    name: yup.string().required("Name is required"),
    description: yup.string(),
    type: yup.string<ChallengeType>().required("Type is required"),
    recurring: yup.string<Recurring>().required("Recurring is required"),
    dates: yup
      .array()
      .of(yup.mixed<Moment>().required("Date is required"))
      .length(2)
      .required("Dates are required"),
  })
  .required();

export const ChallengeDrawer: FC<ChallengeDrawerProps> = (props) => {
  const { editing } = props;
  const [loading, setLoading] = useState(false);
  const confirm = useConfirm();
  const dispatch = useDispatch<any>();
  const {
    register,
    reset,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>({
    // @ts-ignore
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    if (props.open) {
      let initialValues: Partial<Inputs> = {
        type: ChallengeType.STEPS,
        recurring: "none",
        name: "",
        description: "",
        dates: [null, null],
      };

      if (editing) {
        const dates = [
          moment(editing?.startAt),
          moment(editing?.startAt).add(editing.duration, "minutes"),
        ] as [Moment, Moment];

        initialValues = {
          type: editing.type,
          name: editing.name,
          description: editing.description,
          recurring: editing.repeat ? "repeated" : "none",
          dates,
        };
      }

      reset(initialValues);
    }
  }, [props.open, editing]);

  const onSubmit: SubmitHandler<Inputs> = useCallback(
    (data) => {
      setLoading(true);
      const [startDate, endDate] = data.dates;
      const duration = endDate.diff(startDate, "minutes");

      const challengeData = {
        name: data.name,
        description: data.description,
        duration,
        repeat: data.recurring === "repeated",
        startDate: startDate.toDate(),
      };

      if (editing) {
        dispatch(
          updateChallenge(
            {
              challengeId: editing.challengeId,
              ...challengeData,
            },
            () => {
              setLoading(false);
              props.onClose("edit");
            },
          ),
        );
      } else {
        dispatch(
          createChallenge(
            {
              type: data.type,
              ...challengeData,
            },
            () => {
              setLoading(false);
              props.onClose();
            },
          ),
        );
      }
    },
    [editing],
  );

  const handleClose = useCallback(() => {
    props.onClose();
  }, []);

  const handleDelete = useCallback(async () => {
    confirm({
      title: "Delete this challenge?",
      description: "This action is permanent!",
      confirmationText: "Delete",
      cancellationText: "Cancel",
      confirmationButtonProps: {
        color: "danger",
      },
      cancellationButtonProps: {
        variant: "outlined",
        color: "neutral",
      },
    })
      .then(() => {
        dispatch(
          deleteChallenge(
            { challengeId: editing.challengeId },
            ({ success }) => {
              if (success) {
                props.onClose("delete");
              }
            },
          ),
        );
      })
      .catch(() => {});
  }, [editing]);

  return (
    <Drawer anchor="right" size="md" open={props.open} onClose={handleClose}>
      {props.open ? (
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Sheet sx={{ px: 6, pt: 8, bgcolor: "white", flex: 1 }}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              mb={6}
            >
              <Typography level="h2" fontWeight="600">
                {editing ? "Edit Challenge" : "Add Challenge"}
              </Typography>
              <IconButton size="lg" onClick={handleClose}>
                <Close />
              </IconButton>
            </Stack>
          </Sheet>
          <DialogContent>
            <Sheet
              sx={{
                px: 6,
                pb: 8,
                bgcolor: "white",
                flex: 1,
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <Stack gap={6}>
                {!editing ? (
                  <Controller
                    name="type"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <SelectInput
                        label="Challenge Type"
                        options={Object.values(ChallengeType).map((type) => ({
                          value: type,
                          label: capitalize(type),
                        }))}
                        value={value}
                        onChange={onChange}
                        required
                      />
                    )}
                  />
                ) : null}
                <TextInput
                  {...register("name")}
                  label="Name"
                  placeholder="Select"
                  error={!!errors.name}
                  errorMessage={errors.name?.message}
                  required
                />
                <TextareaInput
                  {...register("description")}
                  label="Description"
                  placeholder="Description"
                  minRows={4}
                />
                <Controller
                  name="dates"
                  control={control}
                  render={({ field: { onChange, value, ref, ...field } }) => (
                    <FormControl error={!!errors.dates}>
                      <JoyDateRangePicker
                        inputRef={ref}
                        slotProps={{
                          textField: { required: true, error: !!errors.dates },
                        }}
                        format="DD/MM/YYYY"
                        value={value}
                        onChange={onChange}
                        minDate={moment()}
                      />
                      {errors.dates ? (
                        <FormHelperText>
                          Please select start and end dates
                        </FormHelperText>
                      ) : null}
                    </FormControl>
                  )}
                />

                <Controller
                  name="recurring"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <FormControl>
                      <FormLabel>Frequency</FormLabel>
                      <RadioGroup name="recurring">
                        <Grid container rowSpacing={3} sx={{ flexGrow: 1 }}>
                          <Grid xs={12}>
                            <Radio
                              value="none"
                              label="One-off"
                              color="primary"
                              variant="outlined"
                              checked={value === "none"}
                              onChange={onChange}
                            />
                          </Grid>
                          <Grid xs={12}>
                            <Radio
                              value="repeated"
                              label="Recurring"
                              color="primary"
                              variant="outlined"
                              checked={value === "repeated"}
                              onChange={onChange}
                            />
                          </Grid>
                        </Grid>
                      </RadioGroup>
                    </FormControl>
                  )}
                />
              </Stack>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                pt={4}
              >
                <Box>
                  <Typography fontWeight="bold">Delete challenge?</Typography>
                  <Typography>This action can not be reversed.</Typography>
                </Box>
                <Button
                  color="danger"
                  variant="plain"
                  sx={{ boxShadow: "sm" }}
                  startDecorator={<DeleteOutlined />}
                  onClick={handleDelete}
                >
                  Delete
                </Button>
              </Stack>
            </Sheet>
          </DialogContent>
          <Sheet
            sx={{
              px: 8,
              py: 5,
              bgcolor: "white",
              borderTopWidth: 1,
              borderTopStyle: "solid",
              borderTopColor: "neutral.200",
            }}
          >
            <Grid container spacing={4}>
              <Grid xs={6}>
                <Button
                  size="lg"
                  color="neutral"
                  variant="plain"
                  sx={{ boxShadow: "sm", width: "100%" }}
                  onClick={handleClose}
                >
                  Discard
                </Button>
              </Grid>
              <Grid xs={6}>
                <Button
                  type="submit"
                  size="lg"
                  color="primary"
                  variant="solid"
                  sx={{ boxShadow: "sm", width: "100%" }}
                  onClick={handleSubmit(onSubmit)}
                  loading={loading}
                >
                  {editing ? "Save" : "Add"}
                </Button>
              </Grid>
            </Grid>
          </Sheet>
        </Form>
      ) : null}
    </Drawer>
  );
};

const Form = styled("form")`
  display: flex;
  flex-direction: column;
  flex: 1;
`;
