import {
  ActionCallback,
  ActionCallbackOnly,
  ActionTypeOnly,
  FetchCallback,
  IDataList,
  Nullable,
} from "@store/model";
import {
  ActionType,
  IUpdateManagerPayload,
  IManager,
} from "@store/manager/model";

export const getManager = (callback?: FetchCallback): ActionCallbackOnly => ({
  type: ActionType.GET_MANAGER,
  callback,
});

export const updateManager = (
  payload: IUpdateManagerPayload,
  callback?: FetchCallback,
): ActionCallback<IUpdateManagerPayload> => ({
  type: ActionType.UPDATE_MANAGER,
  payload,
  callback,
});

export const setManager = (
  payload: Nullable<IDataList<IManager>>,
  callback?: FetchCallback,
): ActionCallback<IDataList<IManager>> => ({
  type: ActionType.SET_MANAGER,
  payload,
  callback,
});

export const resetManager = (): ActionTypeOnly => ({
  type: ActionType.RESET,
});
