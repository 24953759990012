import { Button, styled, Typography } from "@mui/joy";
import React, { FC, useCallback, useEffect, useState } from "react";
import { useAppSelector } from "@store/hooks";
import { selectManager } from "@store/manager/selectors";
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { updateManager } from "@store/manager/actions";
import { useDispatch } from "react-redux";
import { TextInput } from "@components/form/TextInput";

const validationSchema = yup
  .object({
    name: yup.string().required("Name is required"),
  })
  .required();

type Inputs = {
  name: string;
};

export const ManagerProfileForm: FC = () => {
  const [loading, setLoading] = useState(false);
  const manager = useAppSelector(selectManager);
  const dispatch = useDispatch<any>();

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>({
    // @ts-ignore
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    reset({
      name: manager.name,
    });
  }, [manager]);

  const onSubmit: SubmitHandler<Inputs> = useCallback(async (data) => {
    setLoading(true);

    dispatch(
      updateManager(data, () => {
        setLoading(false);
      }),
    );
  }, []);

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Typography level="h3">Profile Settings</Typography>
      <TextInput
        {...register("name")}
        label="Name"
        error={!!errors.name}
        errorMessage={errors.name?.message}
        required
      />
      <Button
        color="primary"
        sx={{ boxShadow: "sm" }}
        loading={loading}
        onClick={handleSubmit(onSubmit)}
      >
        Save Changes
      </Button>
    </Form>
  );
};

const Form = styled("form")`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(6)};
`;
