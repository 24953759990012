import React, { FC, useCallback, useMemo, useState } from "react";
import { DEFAULT_LIMIT, MembersType } from "@constants";
import {
  getTeamMembers,
  joinTeamMembers,
  leaveTeamMembers,
} from "@store/team/actions";
import useLoadData from "@/hooks/useLoadData";
import { ITeamMember } from "@store/team/model";
import { IDepartmentMember } from "@store/department/model";
import { useAppSelector } from "@store/hooks";
import {
  selectDepartmentMembers,
  selectDepartmentMembersPagination,
} from "@store/department/selectors";
import {
  selectTeamMembers,
  selectTeamMembersPagination,
} from "@store/team/selectors";
import { TableBody, TableContainer } from "@mui/material";
import { Header } from "@components/table/Header";
import {
  Avatar,
  Button,
  Sheet,
  Stack,
  styled,
  Table,
  Typography,
} from "@mui/joy";
import { Footer } from "@components/table/Footer";
import Box from "@mui/joy/Box";
import IconButton from "@mui/joy/IconButton";
import {
  AddCircleOutlineOutlined,
  DeleteOutlined,
  GroupAddOutlined,
  RemoveCircleOutlineOutlined,
  SearchOutlined,
} from "@mui/icons-material";
import { DebounceInput } from "@components/form/DebounceInput";
import Divider from "@mui/joy/Divider";
import { useDispatch } from "react-redux";
import {
  getDepartmentMembers,
  joinDepartmentMembers,
  leaveDepartmentMembers,
} from "@store/department/actions";
import { Autocomplete } from "@components/Autocomplete";
import { getUsers } from "@services/api/user";
import { IUser } from "@store/user/model";
import { IMember } from "@store/common/model";
import { Placeholder } from "@components/table/Placeholder";
import { EmptyState } from "@components/EmptyState";

type Member = ITeamMember | IDepartmentMember;

const mapTypeToAction = {
  [MembersType.DEPARTMENT]: getDepartmentMembers,
  [MembersType.TEAM]: getTeamMembers,
};

const mapTypeToJoinAction = {
  [MembersType.DEPARTMENT]: joinDepartmentMembers,
  [MembersType.TEAM]: joinTeamMembers,
};

const mapTypeToLeaveAction = {
  [MembersType.DEPARTMENT]: leaveDepartmentMembers,
  [MembersType.TEAM]: leaveTeamMembers,
};

const mapTypeToSelector = {
  [MembersType.DEPARTMENT]: selectDepartmentMembers,
  [MembersType.TEAM]: selectTeamMembers,
};

const mapTypeToPaginationSelector = {
  [MembersType.DEPARTMENT]: selectDepartmentMembersPagination,
  [MembersType.TEAM]: selectTeamMembersPagination,
};

type MembersTableProps = {
  type: MembersType;
  id: string;
};

export const ManageMembersTable: FC<MembersTableProps> = (props) => {
  const { type, id } = props;
  const [search, setSearch] = useState("");
  const [isAdding, setIsAdding] = useState(false);
  const [loading, setLoading] = useState<string | boolean>(false);
  const [selectedToJoinUsers, setSelectedToJoinUsers] = useState<IUser[]>([]);
  const dispatch = useDispatch<any>();
  const { loaded, handlePaginate } = useLoadData<Member>(
    mapTypeToAction[type],
    search,
    { [`${type}Id`]: id },
  );

  const members = useAppSelector(mapTypeToSelector[type]);
  const { totalPages, page } = useAppSelector(
    mapTypeToPaginationSelector[type],
  );

  const { headCells, headCellsSize } = useMemo(() => {
    const headCells = [`${members.total} Users`, "Phone Number"];

    return {
      headCells,
      headCellsSize: headCells.length + 1,
    };
  }, [members.total]);

  const handleLeaveMember = useCallback((user: IMember) => {
    setLoading(user.user.id);
    dispatch(
      mapTypeToLeaveAction[type](
        {
          id,
          users: [user.user.id],
        },
        () => {
          setLoading(false);
        },
      ),
    );
  }, []);

  const handleJoinMembers = useCallback(() => {
    setLoading(true);
    dispatch(
      mapTypeToJoinAction[type](
        {
          id,
          users: selectedToJoinUsers.map((user: IUser) => user.user.id),
        },
        () => {
          setLoading(false);
          setIsAdding(false);
          setSelectedToJoinUsers([]);
        },
      ),
    );
  }, [selectedToJoinUsers, type, id]);

  const handleToggleAdd = useCallback(() => {
    setIsAdding(!isAdding);
  }, [isAdding]);

  return (
    <Stack gap={6}>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography fontSize={24} fontWeight="bold">
          Employees
        </Typography>
        <IconButton
          size="lg"
          sx={{ boxShadow: "sm" }}
          color="neutral"
          onClick={handleToggleAdd}
        >
          {isAdding ? (
            <RemoveCircleOutlineOutlined />
          ) : (
            <AddCircleOutlineOutlined />
          )}
        </IconButton>
      </Stack>
      {isAdding ? (
        <Stack gap={4}>
          <Autocomplete
            action={getUsers}
            getOptionLabel={(user: IUser) =>
              `${user.profile ? `${user.profile.firstName} ${user.profile.lastName}` : user.user?.phoneNumber}`
            }
            getOptionImage={(user: IUser) => user.profile?.avatar}
            value={selectedToJoinUsers}
            onChange={setSelectedToJoinUsers}
            multiple
          />
          <Stack gap={4} direction="row">
            <Button
              color="primary"
              variant="solid"
              loading={!!loading}
              sx={{ boxShadow: "sm", flex: 1 }}
              startDecorator={<GroupAddOutlined />}
              onClick={handleJoinMembers}
            >
              Add
            </Button>
            <Button
              color="neutral"
              variant="plain"
              sx={{ boxShadow: "sm", flex: 1 }}
              onClick={handleToggleAdd}
            >
              Cancel
            </Button>
          </Stack>
        </Stack>
      ) : null}
      {loaded && members?.items?.length === 0 ? (
        <EmptyState description="No teams has been added yet." />
      ) : (
        <Sheet
          variant="outlined"
          sx={{
            width: "100%",
            boxShadow: "sm",
            borderRadius: "xl",
            overflow: "hidden",
          }}
        >
          <Box p={6} sx={{ bgcolor: "white", borderRadius: "xl" }}>
            <Input
              variant="plain"
              handleDebounce={setSearch}
              placeholder="Search..."
              startDecorator={<SearchOutlined />}
              sx={{ boxShadow: "sm" }}
            />
          </Box>
          <Divider />
          <TableContainer>
            <Table>
              <Header headCells={headCells} />
              <TableBody>
                {members.items?.map((employee) => {
                  return (
                    <tr key={employee.user.id}>
                      <td>
                        <Stack direction="row" gap={3} alignItems="center">
                          <Avatar
                            alt={
                              employee.profile
                                ? `${employee.profile.firstName} ${employee.profile.lastName}`
                                : "User"
                            }
                            src={employee.profile?.avatar}
                          />
                          <Box>
                            <Typography fontWeight="500" fontSize="sm">
                              {employee.profile?.firstName}{" "}
                              {employee.profile?.lastName}
                            </Typography>
                          </Box>
                        </Stack>
                      </td>
                      <td>{employee.user.phoneNumber}</td>
                      <td align="right">
                        <IconButton
                          size="lg"
                          sx={{ boxShadow: "sm" }}
                          color="neutral"
                          loading={loading === employee.user.id}
                          onClick={() => handleLeaveMember(employee)}
                        >
                          <DeleteOutlined />
                        </IconButton>
                      </td>
                    </tr>
                  );
                })}
                {!members?.total ? (
                  <Placeholder headCellsSize={headCellsSize} />
                ) : null}
              </TableBody>
              <Footer
                headCellsSize={headCellsSize}
                currentPage={page}
                totalPages={totalPages}
                onPaginate={handlePaginate}
              />
            </Table>
          </TableContainer>
        </Sheet>
      )}
    </Stack>
  );
};
const Input = styled(DebounceInput)`
  flex: 1;
`;
