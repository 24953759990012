import { call, fork, put, takeLatest } from "redux-saga/effects";
import { ActionCallback, ActionCallbackOnly } from "@store/model";
import { setManager } from "@store/manager/actions";
import { ActionType, IUpdateManagerPayload } from "@store/manager/model";
import * as api from "@services/api/manager";

function* getManagerSaga(): Generator {
  yield takeLatest(
    ActionType.GET_MANAGER,
    function* (action: ActionCallbackOnly) {
      const { callback } = action;
      try {
        const data = yield call(api.getManager);

        yield put(setManager(data));

        callback?.({
          success: true,
          data,
        });
      } catch (e) {
        callback?.({
          success: false,
        });
      }
    },
  );
}

function* updateManagerSaga(): Generator {
  yield takeLatest(
    ActionType.UPDATE_MANAGER,
    function* (action: ActionCallback<IUpdateManagerPayload>) {
      const { payload, callback } = action;
      try {
        const data = yield call(api.updateManager, payload);

        yield put(setManager(data));
        callback?.({
          success: true,
          data,
        });
      } catch (e) {
        callback?.({
          success: false,
        });
      }
    },
  );
}

export default [fork(getManagerSaga), fork(updateManagerSaga)];
