import React, { FC, useCallback } from "react";
import AspectRatio from "@mui/joy/AspectRatio";
import Card from "@mui/joy/Card";
import CardContent from "@mui/joy/CardContent";
import CardOverflow from "@mui/joy/CardOverflow";
import Typography from "@mui/joy/Typography";
import { Avatar, IconButton, Stack } from "@mui/joy";
import { EditOutlined } from "@mui/icons-material";
import Divider from "@mui/joy/Divider";
import { IUser } from "@store/user/model";

type EmployeeCardProps = {
  employee: IUser;
  onEdit: (employee: IUser) => void;
};

export const EmployeeCard: FC<EmployeeCardProps> = (props) => {
  const { employee, onEdit } = props;

  const handleEdit = useCallback(() => {
    onEdit(employee);
  }, [employee]);

  return (
    <Card
      sx={{
        textAlign: "center",
        alignItems: "center",
        flex: 1,
        borderRadius: "xl",
        "--icon-size": "100px",
      }}
    >
      <CardOverflow
        variant="solid"
        sx={
          employee.profile?.avatar && {
            background: `url(${employee.profile.avatar}) no-repeat center center`,
            backgroundSize: "cover",
          }
        }
      >
        <AspectRatio
          variant="outlined"
          ratio="1"
          sx={{
            m: "auto",
            transform: "translateY(50%)",
            borderRadius: "50%",
            width: "var(--icon-size)",
            bgcolor: "background.surface",
            boxSizing: "border-box",
            position: "relative",
            borderStyle: "solid",
            borderColor: "background.surface",
            borderWidth: 4,
          }}
        >
          <Avatar
            alt={
              employee.profile
                ? `${employee.profile.firstName} ${employee.profile.lastName}`
                : "User"
            }
            src={employee.profile?.avatar}
          />
        </AspectRatio>
      </CardOverflow>
      <Typography level="title-lg" sx={{ mt: "calc(var(--icon-size) / 2)" }}>
        {employee.profile?.firstName} {employee.profile?.lastName}
      </Typography>
      <CardContent>
        <Typography>{employee.user.phoneNumber}</Typography>
        <Typography>{employee.profile?.email}</Typography>
        <Typography color="neutral">{employee.department?.name}</Typography>
      </CardContent>
      <CardOverflow>
        <Divider inset="context" />
        <Stack direction="row" gap={2} justifyContent="flex-end">
          <IconButton size="lg" onClick={handleEdit}>
            <EditOutlined />
          </IconButton>
        </Stack>
      </CardOverflow>
    </Card>
  );
};
