import { PayloadAction } from "@reduxjs/toolkit";
import createReducer from "@store/createReducer";
import { IDataList } from "@store/model";
import { ActionType, IManager } from "@store/manager/model";

export interface ManagerState {
  manager: IManager;
}

const defaultState: ManagerState = {
  manager: null,
};

export const managerReducer = createReducer<ManagerState>(defaultState, {
  [ActionType.SET_MANAGER](
    state: ManagerState,
    action: PayloadAction<IDataList<IManager>>,
  ) {
    return { ...state, manager: action.payload };
  },
  [ActionType.RESET]() {
    return defaultState;
  },
});
