import { AxiosResponse } from "axios"
import { axiosInstance } from "@services/api"
import {
  ApiConfirmPayload,
  ApiOtpReferenceResponse,
  ApiProfilePayload,
  ApiProfileResponse,
} from "./profile.types"

export const getProfile = async (): Promise<ApiProfileResponse> => {
  const response: AxiosResponse<ApiProfileResponse> = await axiosInstance.get(`/profile`)

  return response.data
}

export const saveProfile = async (data: ApiProfilePayload): Promise<ApiProfileResponse> => {
  const response: AxiosResponse<ApiProfileResponse> = await axiosInstance.post(`/profile`, data)

  return response.data
}

export const deleteProfileRequest = async (): Promise<ApiOtpReferenceResponse> => {
  const response: AxiosResponse<ApiOtpReferenceResponse> =
    await axiosInstance.post(`/profile/delete`)

  return response.data
}

export const deleteProfileConfirm = async (data: ApiConfirmPayload): Promise<void> => {
  const response: AxiosResponse<void> = await axiosInstance.post(`/profile/delete/confirm`, data)

  return response.data
}

export const confirmEmailRequest = async (): Promise<ApiOtpReferenceResponse> => {
  const response: AxiosResponse<ApiOtpReferenceResponse> =
    await axiosInstance.post(`/profile/email`)

  return response.data
}

export const confirmEmailConfirm = async (data: ApiConfirmPayload): Promise<void> => {
  const response: AxiosResponse<void> = await axiosInstance.post(`/profile/email/confirm`, data)

  return response.data
}
