import { AxiosResponse } from "axios";
import { axiosInstance } from "@services/api";
import { ApiSignedUrlResponse } from "@services/api/upload/upload.types";

export const getLogoSignedUrl = async (
  contentType: string,
): Promise<ApiSignedUrlResponse> => {
  const response: AxiosResponse<ApiSignedUrlResponse> = await axiosInstance.get(
    `/upload/signed-url/logo`,
    {
      params: {
        contentType,
      },
    },
  );

  return response.data;
};

export const fetchFileFromUri = async (uri: string): Promise<Blob> => {
  const response = await fetch(uri);
  return response.blob();
};

export const uploadToSignedUrl = async (
  signedUrl: string,
  fileType: string,
  fileBlob: Blob,
): Promise<void> => {
  await fetch(signedUrl, {
    method: "PUT",
    body: fileBlob,
    headers: {
      "Content-Type": fileType,
      "Content-Length": fileBlob.size.toString(),
    },
  });
};
