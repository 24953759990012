import { PayloadAction } from "@reduxjs/toolkit";
import {
  ActionType,
  IChallenge,
  IChallengeActivity,
  IChallengeHistory,
  IChallengeSummary,
  IChallengeTeamLeaderboard,
  IChallengeUserLeaderboard,
} from "@store/challenge/model";
import createReducer from "@store/createReducer";
import { IDataList } from "@store/model";

export interface ChallengeState {
  challenges: IDataList<IChallenge>;
  challengeInfo: IChallenge;
  activity: IChallengeActivity[];
  history: IDataList<IChallengeHistory>;
  teamLeaderboard: IDataList<IChallengeTeamLeaderboard>;
  userLeaderboard: IDataList<IChallengeUserLeaderboard>;
  summary: IChallengeSummary;
}

const defaultState: ChallengeState = {
  challenges: null,
  challengeInfo: null,
  activity: null,
  history: null,
  teamLeaderboard: null,
  userLeaderboard: null,
  summary: null,
};

export const challengeReducer = createReducer<ChallengeState>(defaultState, {
  [ActionType.SET_CHALLENGES](
    state: ChallengeState,
    action: PayloadAction<IDataList<IChallenge>>,
  ) {
    return {
      ...state,
      challenges: action.payload,
    };
  },
  [ActionType.SET_CHALLENGE_INFO](
    state: ChallengeState,
    action: PayloadAction<IChallenge>,
  ) {
    return {
      ...state,
      challenges: state.challenges
        ? {
            ...state.challenges,
            items: state.challenges.items.map((challenge) =>
              challenge.challengeId === action.payload.challengeId
                ? action.payload
                : challenge,
            ),
          }
        : null,
      challengeInfo: action.payload,
      summary: null,
    };
  },
  [ActionType.SET_CHALLENGE_ACTIVITY](
    state: ChallengeState,
    action: PayloadAction<IChallengeActivity[]>,
  ) {
    return {
      ...state,
      activity: action.payload,
    };
  },
  [ActionType.SET_CHALLENGE_HISTORY](
    state: ChallengeState,
    action: PayloadAction<IDataList<IChallengeHistory>>,
  ) {
    return {
      ...state,
      history: {
        ...action.payload,
        items:
          action.payload.offset === 0
            ? action.payload.items
            : [...state.history.items, ...action.payload.items],
      },
    };
  },
  [ActionType.SET_CHALLENGE_TEAM_LEADERBOARD](
    state: ChallengeState,
    action: PayloadAction<IDataList<IChallengeTeamLeaderboard>>,
  ) {
    return {
      ...state,
      teamLeaderboard: {
        ...action.payload,
        items:
          action.payload.offset === 0
            ? action.payload.items
            : [...state.teamLeaderboard.items, ...action.payload.items],
      },
    };
  },
  [ActionType.SET_CHALLENGE_USER_LEADERBOARD](
    state: ChallengeState,
    action: PayloadAction<IDataList<IChallengeUserLeaderboard>>,
  ) {
    return {
      ...state,
      userLeaderboard: {
        ...action.payload,
        items:
          action.payload.offset === 0
            ? action.payload.items
            : [...state.userLeaderboard.items, ...action.payload.items],
      },
    };
  },
  [ActionType.SET_CHALLENGE_SUMMARY](
    state: ChallengeState,
    action: PayloadAction<IChallengeSummary>,
  ) {
    return {
      ...state,
      summary: action.payload,
    };
  },
  [ActionType.RESET]() {
    return defaultState;
  },
});
