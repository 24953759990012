import { AxiosResponse } from "axios";
import { axiosInstance } from "@services/api";
import {
  ApiGetUserParams,
  ApiGetUsersParams,
  ApiDropUserParams,
  ApiUserResponse,
  ApiUsersResponse,
  ApiGetUserTeamsParams,
  ApiUserTeamsResponse,
  ApiJoinUserTeamsPayload,
  ApiJoinUserTeamsResponse,
} from "./user.types";

export const getUsers = async (
  params: ApiGetUsersParams,
): Promise<ApiUsersResponse> => {
  const response: AxiosResponse<ApiUsersResponse> = await axiosInstance.get(
    `/user`,
    {
      params,
    },
  );

  return response.data;
};

export const getUser = async (
  params: ApiGetUserParams,
): Promise<ApiUserResponse> => {
  const response: AxiosResponse<ApiUserResponse> = await axiosInstance.get(
    `/user/${params.userId}`,
    {
      params,
    },
  );

  return response.data;
};

export const getUserTeams = async (
  params: ApiGetUserTeamsParams,
): Promise<ApiUserTeamsResponse> => {
  const { userId, ...restParams } = params;

  const response: AxiosResponse<ApiUserTeamsResponse> = await axiosInstance.get(
    `/user/${userId}/teams`,
    {
      params: restParams,
    },
  );

  return response.data;
};

export const joinUserTeams = async (
  data: ApiJoinUserTeamsPayload,
): Promise<ApiJoinUserTeamsResponse> => {
  const { userId, teams } = data;
  const response: AxiosResponse<ApiJoinUserTeamsResponse> =
    await axiosInstance.post(`/user/${userId}/teams/join`, teams);

  return response.data;
};

export const dropUser = async (
  params: ApiDropUserParams,
): Promise<undefined> => {
  const response: AxiosResponse<undefined> = await axiosInstance.post(
    `/user/${params.userId}/drop`,
  );

  return response.data;
};
