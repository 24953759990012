import React, {
  forwardRef,
  Ref,
  useCallback,
  useImperativeHandle,
  useState,
} from "react";
import {
  IconButton,
  Stack,
  Card,
  AspectRatio,
  CardOverflow,
  Button,
  styled,
} from "@mui/joy";
import { DeleteOutlined, RefreshOutlined } from "@mui/icons-material";
import { UploadType } from "@constants";
import { getCroppedImg, readFile } from "@services/utils/form";
import Cropper, { Area } from "react-easy-crop";
import Box from "@mui/joy/Box";
import {
  fetchFileFromUri,
  getLogoSignedUrl,
  uploadToSignedUrl,
} from "@services/api/upload";
import moment from "moment";

type UploadInputProps = {
  image?: string;
  type: UploadType;
  onChange?: (image: string) => void;
  readOnly?: boolean;
};

type UploadInputRef = {
  handleUpload: () => void;
};

const getSignedUrlActions = {
  [UploadType.LOGO]: getLogoSignedUrl,
};

export const UploadInput = forwardRef<UploadInputRef, UploadInputProps>(
  (props, ref: Ref<any>) => {
    const { image, readOnly } = props;
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState<number>(1);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area>();
    const [selectedImage, setSelectedImage] = useState<string>(image);
    const [cropping, setCropping] = useState<boolean>(false);

    const handleUpload = useCallback(async (): Promise<string> => {
      if (selectedImage && selectedImage !== image) {
        const imageType = "image/jpeg";
        const [{ signedUrl, fileName }, imageBlob] = await Promise.all([
          getSignedUrlActions[props.type](imageType),
          fetchFileFromUri(selectedImage),
        ]);
        await uploadToSignedUrl(signedUrl, imageType, imageBlob);
        return `${fileName}#${moment().format("x")}`;
      }
      return selectedImage;
    }, [selectedImage, image]);

    useImperativeHandle(ref, () => ({
      handleUpload,
    }));

    const handleSelectFile = useCallback(async (e) => {
      if (e.target.files && e.target.files.length > 0) {
        const file = e.target.files[0];
        const imageDataUrl = await readFile(file);
        setSelectedImage(imageDataUrl);
        setCropping(true);
      }
    }, []);

    const handleCrop = (croppedArea: Area, croppedAreaPixels: Area) => {
      setCroppedAreaPixels(croppedAreaPixels);
    };

    const handleSaveCrop = useCallback(async () => {
      const croppedImage = await getCroppedImg(
        selectedImage,
        croppedAreaPixels,
      );
      setSelectedImage(croppedImage);
      setCropping(false);
    }, [selectedImage, croppedAreaPixels]);

    const handleCancelCrop = useCallback(() => {
      setCropping(false);
    }, []);

    const handleDeleteImage = useCallback(() => {
      setSelectedImage(null);
    }, []);

    return (
      <Card
        sx={{
          textAlign: "center",
          alignItems: "center",
          border: "none",
          bgcolor: "white",
          borderRadius: "xl",
          "--icon-size": "128px",
        }}
      >
        {cropping ? (
          <CropperContainer>
            <Cropper
              image={selectedImage}
              crop={crop}
              zoom={zoom}
              aspect={1}
              onCropChange={setCrop}
              onCropComplete={handleCrop}
              onZoomChange={setZoom}
            />
          </CropperContainer>
        ) : (
          <CardOverflow
            variant="solid"
            sx={{
              bgcolor: "neutral.100",
              ...(selectedImage && {
                background: `url(${selectedImage}) no-repeat center center`,
                backgroundSize: "cover",
              }),
            }}
          >
            <AspectRatio
              variant="plain"
              color="warning"
              ratio="1"
              objectFit="cover"
              sx={{
                m: "auto",
                transform: "translateY(50%)",
                borderRadius: "xl",
                width: "var(--icon-size)",
                bgcolor: "neutral.100",
                boxSizing: "border-box",
                position: "relative",
                borderStyle: "dashed",
                borderColor: "neutral.200",
                borderWidth: 2,
              }}
            >
              <img src={selectedImage || "/assets/images/placeholder.png"} />
            </AspectRatio>
          </CardOverflow>
        )}
        {cropping ? (
          <Stack
            sx={{
              width: "var(--icon-size)",
            }}
            gap={4}
            direction="row"
          >
            <Button color="primary" onClick={handleSaveCrop}>
              Save
            </Button>
            <Button variant="plain" color="neutral" onClick={handleCancelCrop}>
              Cancel
            </Button>
          </Stack>
        ) : (
          <Stack
            sx={{
              mt: "calc(var(--icon-size) / 2)",
              width: "var(--icon-size)",
            }}
            gap={4}
            direction="row"
          >
            {readOnly ? null : selectedImage ? (
              <>
                <IconButton
                  variant="plain"
                  color="neutral"
                  size="lg"
                  component="label"
                  tabIndex={-1}
                  sx={{ ml: "auto", flex: 1, boxShadow: "sm" }}
                >
                  <RefreshOutlined />
                  <VisuallyHiddenInput
                    type="file"
                    onChange={handleSelectFile}
                    multiple={false}
                    accept="image/*"
                  />
                </IconButton>
                <IconButton
                  variant="plain"
                  color="neutral"
                  size="lg"
                  sx={{ ml: "auto", flex: 1, boxShadow: "sm" }}
                  onClick={handleDeleteImage}
                >
                  <DeleteOutlined />
                </IconButton>
              </>
            ) : (
              <Button
                component="label"
                variant="plain"
                color="neutral"
                size="lg"
                tabIndex={-1}
                sx={{ ml: "auto", flex: 1, boxShadow: "sm" }}
              >
                Upload
                <VisuallyHiddenInput
                  type="file"
                  onChange={handleSelectFile}
                  multiple={false}
                  accept="image/*"
                />
              </Button>
            )}
          </Stack>
        )}
      </Card>
    );
  },
);

const VisuallyHiddenInput = styled("input")`
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  bottom: 0;
  left: 0;
  white-space: nowrap;
  width: 1px;
`;

const CropperContainer = styled(Box)`
  width: 300px;
  height: 300px;
  border-radius: ${({ theme }) => theme.radius.xl};
  overflow: hidden;
  position: relative;
`;
