import { Pagination } from "@services/api/api.types";
import { ChallengeState, ChallengeType } from "@constants";

export enum ActionType {
  GET_CHALLENGES = "GET_CHALLENGES",
  GET_CHALLENGE_INFO = "GET_CHALLENGE_INFO",
  GET_CHALLENGE_ACTIVITY = "GET_CHALLENGE_ACTIVITY",
  GET_CHALLENGE_HISTORY = "GET_CHALLENGE_HISTORY",
  GET_CHALLENGE_TEAM_LEADERBOARD = "GET_CHALLENGE_TEAM_LEADERBOARD",
  GET_CHALLENGE_USER_LEADERBOARD = "GET_CHALLENGE_USER_LEADERBOARD",
  GET_CHALLENGE_SUMMARY = "GET_CHALLENGE_SUMMARY",
  CREATE_CHALLENGE = "CREATE_CHALLENGE",
  UPDATE_CHALLENGE = "UPDATE_CHALLENGE",
  DELETE_CHALLENGE = "DELETE_CHALLENGE",
  SET_CHALLENGES = "SET_CHALLENGES",
  SET_CHALLENGE_INFO = "SET_CHALLENGE_INFO",
  SET_CHALLENGE_ACTIVITY = "SET_CHALLENGE_ACTIVITY",
  SET_CHALLENGE_HISTORY = "SET_CHALLENGE_HISTORY",
  SET_CHALLENGE_TEAM_LEADERBOARD = "SET_CHALLENGE_TEAM_LEADERBOARD",
  SET_CHALLENGE_USER_LEADERBOARD = "SET_CHALLENGE_USER_LEADERBOARD",
  SET_CHALLENGE_SUMMARY = "SET_CHALLENGE_SUMMARY",
  RESET = "RESET_CHALLENGE",
}

export interface IChallengesParams extends Pagination {
  search?: string;
}

export interface IChallengeInfoParams {
  challengeId: string;
}

export interface IChallengeActivityParams {
  challengeId: string;
}

export interface IChallengeHistoryParams extends Pagination {
  challengeId: string;
}

export interface IChallengeLeaderboardParams extends Pagination {
  challengeId: string;
}

export interface IChallengeSummaryParams {
  challengeId: string;
  challengeItemId: string;
}

export interface IDeleteChallengeParams {
  challengeId: string;
}

export interface ICreateChallengePayload {
  name: string;
  description: string;
  duration: number;
  repeat: boolean;
  startDate: Date;
  type: ChallengeType;
}

export interface IUpdateChallengePayload {
  challengeId: string;
  name: string;
  description: string;
  duration: number;
  repeat: boolean;
  startDate: Date;
}

export interface IParticipant {
  userId: string;
  active: boolean;
  firstName: string;
  lastName: string;
  avatar: string;
}

export interface IChallenge {
  challengeId: string;
  challengeItemId: string;
  type: ChallengeType;
  state: ChallengeState;
  name: string;
  description: string;
  backgroundColor: string;
  image: string;
  unit: string;
  duration: number;
  closed: boolean;
  repeat: boolean;
  startAt: Date;
  endAt: Date;
  participantsCount: number;
  iteration: number;
  participants: IParticipant[];
}

export interface IChallengeActivity {
  date: string;
  value: number;
}

export interface IChallengeHistory {
  challengeId: string;
  challengeItemId: string;
  type: ChallengeType;
  name: string;
  description: string;
  backgroundColor: string;
  image: string;
  unit: string;
  duration: number;
  repeat: boolean;
  closed: boolean;
  startAt: Date;
  endAt: Date;
}

export interface IChallengeTeamLeaderboard {
  id: string;
  name: string;
  description: string;
  logo: string;
  membersCount: number;
  teamAverageValue: number;
  teamValue: number;
  teamOrder: number;
}

export interface IChallengeUserLeaderboard {
  userId: string;
  department: {
    name: string;
    description: string;
    logo: string;
    id: string;
    workspaceId: string;
    createdAt: Date;
    updatedAt: Date;
  };
  teams: [
    {
      id: string;
      name: string;
      description: string;
      logo: string;
    },
  ];
  active: boolean;
  avatar: string;
  firstName: string;
  lastName: string;
  userAverageValue: number;
  userValue: number;
  userPoint: number;
  userOrder: number;
}

export interface IChallengeLeaderboard {
  name: string;
  order: number;
  value: number;
  averageValue: number;
  image: string;
  sub?: string;
}

export interface IChallengeSummary {
  status: string;
  challengeItem: IChallenge;
  participants: IChallengeUserLeaderboard[];
  teams: IChallengeTeamLeaderboard[];
  activity: IChallengeActivity[];
}
