import {
  ActionCallback,
  ActionCallbackOnly,
  ActionTypeOnly,
  FetchCallback,
  Nullable,
} from "@store/model"
import {
  ActionType,
  IConfirmPayload,
  IFullProfile,
  IOtpReference,
  IProfilePayload,
} from "@store/profile/model"

export const getProfile = (callback?: FetchCallback): ActionCallbackOnly => ({
  type: ActionType.GET_PROFILE,
  callback,
})

export const saveProfile = (
  payload: IProfilePayload,
  callback?: FetchCallback,
): ActionCallback<IProfilePayload> => ({
  type: ActionType.SAVE_PROFILE,
  payload,
  callback,
})

export const deleteProfileRequest = (callback?: FetchCallback): ActionCallbackOnly => ({
  type: ActionType.DELETE_PROFILE_REQUEST,
  callback,
})

export const deleteProfileConfirm = (
  payload: IConfirmPayload,
  callback?: FetchCallback,
): ActionCallback<IConfirmPayload> => ({
  type: ActionType.DELETE_PROFILE_CONFIRM,
  payload,
  callback,
})

export const confirmEmailRequest = (callback?: FetchCallback): ActionCallbackOnly => ({
  type: ActionType.CONFIRM_EMAIL_REQUEST,
  callback,
})

export const confirmEmailConfirm = (
  payload: IConfirmPayload,
  callback?: FetchCallback,
): ActionCallback<IConfirmPayload> => ({
  type: ActionType.CONFIRM_EMAIL_CONFIRM,
  payload,
  callback,
})

export const setOtpReference = (
  payload: IOtpReference,
  callback?: FetchCallback,
): ActionCallback<IOtpReference> => ({
  type: ActionType.SET_OTP_REFERENCE,
  payload,
  callback,
})

export const setProfile = (
  payload: Nullable<IFullProfile>,
  callback?: FetchCallback,
): ActionCallback<IFullProfile> => ({
  type: ActionType.SET_PROFILE,
  payload,
  callback,
})

export const resetProfile = (): ActionTypeOnly => ({
  type: ActionType.RESET,
})
