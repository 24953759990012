import * as React from "react";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import Typography from "@mui/joy/Typography";
import Input, { InputProps } from "@mui/joy/Input";
import { FormHelperText } from "@mui/joy";

interface JoyFieldProps extends InputProps {
  label?: React.ReactNode;
  InputProps?: {
    ref?: React.Ref<any>;
    endAdornment?: React.ReactNode;
    startAdornment?: React.ReactNode;
  };
  formControlSx?: InputProps["sx"];
  errorMessage?: string;
}

type JoyFieldComponent = ((
  props: JoyFieldProps & React.RefAttributes<HTMLDivElement>,
) => React.JSX.Element) & { propTypes?: any };

export const TextInput = React.forwardRef(
  (props: JoyFieldProps, ref: React.Ref<HTMLDivElement>) => {
    const {
      disabled,
      required,
      id,
      error,
      label,
      InputProps: { ref: containerRef, startAdornment, endAdornment } = {},
      endDecorator,
      startDecorator,
      slotProps,
      errorMessage,
      ...other
    } = props;

    return (
      <FormControl
        disabled={disabled}
        id={id}
        ref={ref}
        sx={{ flex: 1 }}
        error={error}
      >
        <FormLabel>
          {label}
          {required ? <Typography color="danger">*</Typography> : null}
        </FormLabel>
        <Input
          ref={ref}
          disabled={disabled}
          variant="soft"
          size="lg"
          endDecorator={
            <React.Fragment>
              {endAdornment}
              {endDecorator}
            </React.Fragment>
          }
          slotProps={{
            ...slotProps,
            root: { ...slotProps?.root, ref: containerRef },
          }}
          {...other}
        />
        {errorMessage ? <FormHelperText>{errorMessage}</FormHelperText> : null}
      </FormControl>
    );
  },
) as JoyFieldComponent;
