import React, { FC, useCallback, useMemo } from "react";
import {
  Button,
  Stack,
  Tab,
  TabList,
  TabPanel,
  Tabs,
  Typography,
} from "@mui/joy";
import {
  GroupAddOutlined,
  PersonAddAlt1Outlined,
  Diversity1Outlined,
} from "@mui/icons-material";
import { Employees } from "@containers/Employees";
import { Teams } from "@containers/Teams";
import { Departments } from "@containers/Departments";
import { DepartmentDrawer } from "@drawers/DepartmentDrawer";
import { TeamDrawer } from "@drawers/TeamDrawer";
import useDrawer from "@/hooks/useDrawer";
import { ITeam } from "@store/team/model";
import { IDepartment } from "@store/department/model";
import { IInvite } from "@store/invite/model";
import { InviteDrawer } from "@drawers/InviteDrawer";
import { Invites } from "@containers/Invites";
import { EmployeeDrawer } from "@drawers/EmployeeDrawer";
import { IUser } from "@store/user/model";

const teamsEnabled = false;

export const People: FC = () => {
  const employeeDrawer = useDrawer<IUser>();
  const teamDrawer = useDrawer<ITeam>();
  const departmentDrawer = useDrawer<IDepartment>();
  const inviteDrawer = useDrawer<IInvite>();

  const handleInviteUser = useCallback(() => {
    inviteDrawer.handleOpen();
  }, []);

  const handleCreateTeam = useCallback(() => {
    teamDrawer.handleOpen();
  }, []);

  const handleCreateDepartment = useCallback(() => {
    departmentDrawer.handleOpen();
  }, []);

  const tabs = useMemo(() => {
    return [
      {
        key: "employees",
        label: "Employees",
        component: <Employees onEdit={employeeDrawer.handleEdit} />,
      },
      ...(teamsEnabled
        ? [
            {
              key: "teams",
              label: "Teams",
              component: <Teams onEdit={teamDrawer.handleEdit} />,
            },
          ]
        : []),
      {
        key: "departments",
        label: "Departments",
        component: <Departments onEdit={departmentDrawer.handleEdit} />,
      },
      {
        key: "invites",
        label: "Invites",
        component: <Invites />,
      },
    ];
  }, [teamsEnabled]);

  return (
    <>
      <EmployeeDrawer
        open={employeeDrawer.open}
        onClose={employeeDrawer.handleClose}
        user={employeeDrawer.editing}
      />
      {teamsEnabled ? (
        <TeamDrawer
          open={teamDrawer.open}
          onClose={teamDrawer.handleClose}
          editing={teamDrawer.editing}
        />
      ) : null}
      <DepartmentDrawer
        open={departmentDrawer.open}
        onClose={departmentDrawer.handleClose}
        editing={departmentDrawer.editing}
      />
      <InviteDrawer
        open={inviteDrawer.open}
        onClose={inviteDrawer.handleClose}
      />
      <Stack
        gap={4}
        direction={{ xs: "column", md: "row" }}
        alignItems={{ xs: "stretch", md: "center" }}
      >
        <Typography level="h2" flex={1}>
          People
        </Typography>
        {teamsEnabled ? (
          <Button
            size="md"
            startDecorator={<GroupAddOutlined />}
            color="neutral"
            variant="plain"
            sx={{ boxShadow: "sm" }}
            onClick={handleCreateTeam}
          >
            Add Team
          </Button>
        ) : null}
        <Button
          size="md"
          startDecorator={<Diversity1Outlined />}
          color="neutral"
          variant="plain"
          sx={{ boxShadow: "sm" }}
          onClick={handleCreateDepartment}
        >
          Add Department
        </Button>
        <Button
          size="md"
          startDecorator={<PersonAddAlt1Outlined />}
          color="primary"
          sx={{ boxShadow: "sm" }}
          onClick={handleInviteUser}
        >
          Invite / Add Employee
        </Button>
      </Stack>
      <Tabs
        aria-label="People tabs"
        defaultValue={0}
        sx={{ "--Tabs-spacing": 0, gap: 6 }}
      >
        <TabList>
          {tabs.map((tab) => (
            <Tab key={`${tab.key}-label`} color="primary">
              {tab.label}
            </Tab>
          ))}
        </TabList>
        {tabs.map((tab, index) => (
          <TabPanel key={`${tab.key}-content`} value={index}>
            {tab.component}
          </TabPanel>
        ))}
      </Tabs>
    </>
  );
};
