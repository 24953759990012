import { Pagination } from "@services/api/api.types";
import { Gender } from "@constants";

export enum ActionType {
  GET_USERS = "GET_USERS",
  GET_USER = "GET_USER",
  GET_USER_TEAMS = "GET_USER_TEAMS",
  JOIN_USER_TEAMS = "JOIN_USER_TEAMS",
  LEAVE_USER_TEAMS = "LEAVE_USER_TEAMS",
  SET_USER_DEPARTMENT = "SET_USER_DEPARTMENT",
  DROP_USER = "DROP_USER",
  SET_USERS = "SET_USERS",
  SET_USER = "SET_USER",
  SET_USER_TEAMS = "SET_USER_TEAMS",
  RESET = "RESET_USER",
}

export interface IUsersParams extends Pagination {
  search?: string;
}

export interface IUserParams {
  userId: string;
}

export interface IUserTeamsParams extends Pagination {
  userId: string;
}

export interface IJoinUserTeamsPayload {
  userId: string;
  teams: string[];
}

export interface ISetUserDepartmentPayload {
  userId: string;
  departmentId: string;
}

export interface IDropUserParams {
  userId: string;
}

export interface IUser {
  user: {
    id: string;
    active: boolean;
    phoneNumber: string;
    lastSeen: Date;
    createdAt: Date;
  };
  profile: {
    firstName: string;
    lastName: string;
    avatar: string;
    email: string;
    gender: Gender;
    dateOfBirth: string;
    emailConfirmed: Date;
    createdAt: Date;
    updatedAt: Date;
  };
  department: {
    name: string;
    description: string;
    logo: string;
    id: string;
    workspaceId: string;
    createdAt: Date;
    updatedAt: Date;
  };
  membership: {
    active: boolean;
    createdAt: Date;
  };
}

export interface IUserTeam {
  id: string;
  workspaceId: string;
  name: string;
  description: string;
  logo: string;
  createdAt: Date;
  updatedAt: Date;
  membership: {
    createdAt: Date;
  };
}

export interface IJoinUserTeam {
  teamId: string;
  success: boolean;
}
