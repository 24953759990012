export enum ActionType {
  SIGN_IN = "SIGN_IN",
  SIGN_OUT = "SIGN_OUT",
  SET_AUTH = "SET_AUTH",
  SET_LOGGED_IN = "SET_LOGGED_IN",
  RESET = "RESET_AUTH",
}

export interface ISignInPayload {
  token: string;
}

export interface ITokens {
  accessToken: string;
  refreshToken: string;
}
