import { AxiosResponse } from "axios";
import { axiosInstance } from "@services/api";
import {
  ApiGetDepartmentsParams,
  ApiDepartmentResponse,
  ApiDepartmentsResponse,
  ApiGetDepartmentInfoParams,
  ApiDeleteDepartmentParams,
  ApiCreateDepartmentPayload,
  ApiDepartmentUsersResponse,
  ApiGetDepartmentUsersParams,
  ApiUpdateDepartmentPayload,
  ApiJoinDepartmentUsersPayload,
  ApiJoinDepartmentUsersResponse,
} from "./department.types";

export const getDepartments = async (
  params: ApiGetDepartmentsParams,
): Promise<ApiDepartmentsResponse> => {
  const response: AxiosResponse<ApiDepartmentsResponse> =
    await axiosInstance.get(`/department`, {
      params,
    });

  return response.data;
};

export const getDepartmentInfo = async (
  params: ApiGetDepartmentInfoParams,
): Promise<ApiDepartmentResponse> => {
  const response: AxiosResponse<ApiDepartmentResponse> =
    await axiosInstance.get(`/department/${params.departmentId}`);

  return response.data;
};

export const getDepartmentUsers = async (
  params: ApiGetDepartmentUsersParams,
): Promise<ApiDepartmentUsersResponse> => {
  const { departmentId, ...restParams } = params;

  const response: AxiosResponse<ApiDepartmentUsersResponse> =
    await axiosInstance.get(`/department/${departmentId}/users`, {
      params: restParams,
    });

  return response.data;
};

export const createDepartment = async (
  data: ApiCreateDepartmentPayload,
): Promise<undefined> => {
  const response: AxiosResponse<undefined> = await axiosInstance.post(
    `/department`,
    data,
  );

  return response.data;
};

export const updateDepartment = async (
  data: ApiUpdateDepartmentPayload,
): Promise<undefined> => {
  const { departmentId, ...restData } = data;

  const response: AxiosResponse<undefined> = await axiosInstance.put(
    `/department/${departmentId}`,
    restData,
  );

  return response.data;
};

export const deleteDepartment = async (
  params: ApiDeleteDepartmentParams,
): Promise<undefined> => {
  const response: AxiosResponse<undefined> = await axiosInstance.delete(
    `/department/${params.departmentId}`,
    {
      params,
    },
  );

  return response.data;
};

export const joinDepartmentUsers = async (
  data: ApiJoinDepartmentUsersPayload,
): Promise<ApiJoinDepartmentUsersResponse> => {
  const { id, users } = data;
  const response: AxiosResponse<ApiJoinDepartmentUsersResponse> =
    await axiosInstance.post(`/department/${id}/join`, users);

  return response.data;
};

export const leaveDepartmentUsers = async (
  data: ApiJoinDepartmentUsersPayload,
): Promise<ApiJoinDepartmentUsersResponse> => {
  const { id, users } = data;
  const response: AxiosResponse<ApiJoinDepartmentUsersResponse> =
    await axiosInstance.post(`/department/${id}/leave`, users);

  return response.data;
};
