import * as React from "react";
import Box from "@mui/joy/Box";
import Table from "@mui/joy/Table";
import Typography from "@mui/joy/Typography";
import Sheet from "@mui/joy/Sheet";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import IconButton from "@mui/joy/IconButton";
import Link from "@mui/joy/Link";
import Select from "@mui/joy/Select";
import Option from "@mui/joy/Option";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { visuallyHidden } from "@mui/utils";
import { FC } from "react";
import moment from "moment";
import { Avatar, Chip, Stack } from "@mui/joy";

interface Data {
  id: string;
  date: string;
  name: string;
  phoneNumber: string;
  avatar: string;
  description: string;
  amount: number;
  points: number;
  approvedBy: string;
}

function createData(
  id: string,
  date: string,
  name: string,
  phoneNumber: string,
  avatar: string,
  description: string,
  amount: number,
  points: number,
  approvedBy: string,
): Data {
  return {
    id,
    date,
    name,
    phoneNumber,
    avatar,
    description,
    amount,
    points,
    approvedBy,
  };
}

const rows = [
  createData(
    "1",
    new Date().toString(),
    "Nolan Herwits",
    "+1-437-431-5527",
    "",
    "Amazon Gift Card",
    20,
    10,
    "Automatic",
  ),
  createData(
    "2",
    new Date().toString(),
    "Nolan Herwits",
    "+1-437-431-5527",
    "",
    "Amazon Gift Card",
    20,
    10,
    "Automatic",
  ),
  createData(
    "3",
    new Date().toString(),
    "Nolan Herwits",
    "+1-437-431-5527",
    "",
    "Amazon Gift Card",
    20,
    10,
    "Automatic",
  ),
  createData(
    "4",
    new Date().toString(),
    "Nolan Herwits",
    "+1-437-431-5527",
    "",
    "Amazon Gift Card",
    20,
    10,
    "Automatic",
  ),
  createData(
    "5",
    new Date().toString(),
    "Nolan Herwits",
    "+1-437-431-5527",
    "",
    "Amazon Gift Card",
    20,
    10,
    "Automatic",
  ),
  createData(
    "5",
    new Date().toString(),
    "Nolan Herwits",
    "+1-437-431-5527",
    "",
    "Amazon Gift Card",
    20,
    10,
    "Automatic",
  ),
  createData(
    "5",
    new Date().toString(),
    "Nolan Herwits",
    "+1-437-431-5527",
    "",
    "Amazon Gift Card",
    20,
    10,
    "Automatic",
  ),
  createData(
    "5",
    new Date().toString(),
    "Nolan Herwits",
    "+1-437-431-5527",
    "",
    "Amazon Gift Card",
    20,
    10,
    "Automatic",
  ),
  createData(
    "5",
    new Date().toString(),
    "Nolan Herwits",
    "+1-437-431-5527",
    "",
    "Amazon Gift Card",
    20,
    10,
    "Automatic",
  ),
  createData(
    "5",
    new Date().toString(),
    "Nolan Herwits",
    "+1-437-431-5527",
    "",
    "Amazon Gift Card",
    20,
    10,
    "Automatic",
  ),
  createData(
    "5",
    new Date().toString(),
    "Nolan Herwits",
    "+1-437-431-5527",
    "",
    "Amazon Gift Card",
    20,
    10,
    "Automatic",
  ),
  createData(
    "5",
    new Date().toString(),
    "Nolan Herwits",
    "+1-437-431-5527",
    "",
    "Amazon Gift Card",
    20,
    10,
    "Automatic",
  ),
  createData(
    "5",
    new Date().toString(),
    "Nolan Herwits",
    "+1-437-431-5527",
    "",
    "Amazon Gift Card",
    20,
    10,
    "Automatic",
  ),
];

function labelDisplayedRows({
  from,
  to,
  count,
}: {
  from: number;
  to: number;
  count: number;
}) {
  return `${from}–${to} of ${count !== -1 ? count : `more than ${to}`}`;
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = "asc" | "desc";

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string },
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(
  array: readonly T[],
  comparator: (a: T, b: T) => number,
) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
  id: keyof Data;
  label: string;
}

const headCells: readonly HeadCell[] = [
  {
    id: "date",
    label: "Date",
  },
  {
    id: "name",
    label: "User",
  },
  {
    id: "description",
    label: "Description",
  },
  {
    id: "amount",
    label: "Amount",
  },
  {
    id: "points",
    label: "Points",
  },
  {
    id: "approvedBy",
    label: "Approved By",
  },
];

interface EnhancedTableProps {
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof Data,
  ) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler =
    (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <thead>
      <tr>
        {headCells.map((headCell) => {
          const active = orderBy === headCell.id;
          return (
            <th
              key={headCell.id}
              aria-sort={
                active
                  ? ({ asc: "ascending", desc: "descending" } as const)[order]
                  : undefined
              }
            >
              <Link
                underline="none"
                color="neutral"
                textColor={active ? "primary.plainColor" : undefined}
                component="button"
                onClick={createSortHandler(headCell.id)}
                fontWeight="lg"
                endDecorator={
                  <ArrowDownwardIcon sx={{ opacity: active ? 1 : 0 }} />
                }
                sx={{
                  "& svg": {
                    transition: "0.2s",
                    transform:
                      active && order === "desc"
                        ? "rotate(0deg)"
                        : "rotate(180deg)",
                  },
                  "&:hover": { "& svg": { opacity: 1 } },
                }}
              >
                {headCell.label}
                {active ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </Link>
            </th>
          );
        })}
      </tr>
    </thead>
  );
}

export const RedemptionsTable: FC = () => {
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<keyof Data>("date");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data,
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any, newValue: number | null) => {
    setRowsPerPage(parseInt(newValue!.toString(), 10));
    setPage(0);
  };

  const getLabelDisplayedRowsTo = () => {
    if (rows.length === -1) {
      return (page + 1) * rowsPerPage;
    }
    return rowsPerPage === -1
      ? rows.length
      : Math.min(rows.length, (page + 1) * rowsPerPage);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  return (
    <Sheet
      variant="outlined"
      sx={{ width: "100%", boxShadow: "sm", borderRadius: "xl" }}
    >
      <Table
        aria-labelledby="redemptions-table"
        sx={{ "--TableCell-paddingX": "24px", "--TableCell-paddingY": "16px" }}
      >
        <EnhancedTableHead
          order={order}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
          rowCount={rows.length}
        />
        <tbody>
          {stableSort(rows, getComparator(order, orderBy))
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((row, index) => {
              return (
                <tr key={row.id}>
                  <th scope="row">{moment(row.date).format("MMM DD, YYYY")}</th>
                  <td>
                    <Stack direction="row" gap={3} alignItems="center">
                      <Avatar
                        alt="Remy Sharp"
                        src="/static/images/avatar/1.jpg"
                      />
                      <Box>
                        <Typography fontWeight="500" fontSize="sm">
                          {row.name}
                        </Typography>
                        <Typography color="neutral" fontSize="sm">
                          {row.phoneNumber}
                        </Typography>
                      </Box>
                    </Stack>
                  </td>
                  <td>{row.description}</td>
                  <td>${row.amount}</td>
                  <td>
                    <Chip>{row.points}</Chip>
                  </td>
                  <td>{row.approvedBy}</td>
                </tr>
              );
            })}
          {emptyRows > 0 && (
            <tr
              style={
                {
                  height: `calc(${emptyRows} * 40px)`,
                } as React.CSSProperties
              }
            >
              <td colSpan={6} aria-hidden />
            </tr>
          )}
        </tbody>
        <tfoot>
          <tr>
            <td colSpan={6}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 2,
                  justifyContent: "flex-end",
                }}
              >
                <FormControl orientation="horizontal" size="sm">
                  <Select
                    onChange={handleChangeRowsPerPage}
                    value={rowsPerPage}
                  >
                    <Option value={5}>5</Option>
                    <Option value={10}>10</Option>
                    <Option value={25}>25</Option>
                  </Select>
                </FormControl>
                <Typography textAlign="center" sx={{ minWidth: 80 }}>
                  {labelDisplayedRows({
                    from: rows.length === 0 ? 0 : page * rowsPerPage + 1,
                    to: getLabelDisplayedRowsTo(),
                    count: rows.length === -1 ? -1 : rows.length,
                  })}
                </Typography>
                <Box sx={{ display: "flex", gap: 1 }}>
                  <IconButton
                    size="sm"
                    color="neutral"
                    variant="outlined"
                    disabled={page === 0}
                    onClick={() => handleChangePage(page - 1)}
                    sx={{ bgcolor: "background.surface" }}
                  >
                    <KeyboardArrowLeftIcon />
                  </IconButton>
                  <IconButton
                    size="sm"
                    color="neutral"
                    variant="outlined"
                    disabled={
                      rows.length !== -1
                        ? page >= Math.ceil(rows.length / rowsPerPage) - 1
                        : false
                    }
                    onClick={() => handleChangePage(page + 1)}
                    sx={{ bgcolor: "background.surface" }}
                  >
                    <KeyboardArrowRightIcon />
                  </IconButton>
                </Box>
              </Box>
            </td>
          </tr>
        </tfoot>
      </Table>
    </Sheet>
  );
};
