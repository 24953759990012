import { combineReducers } from "@reduxjs/toolkit";
import { AuthState, authReducer } from "@store/auth/reducer";
import { DepartmentState, departmentReducer } from "@store/department/reducer";
import { ProfileState, profileReducer } from "@store/profile/reducer";
import { TeamState, teamReducer } from "@store/team/reducer";
import { UserState, userReducer } from "@store/user/reducer";
import { WorkspaceState, workspaceReducer } from "@store/workspace/reducer";
import { ChallengeState, challengeReducer } from "@store/challenge/reducer";
import { InviteState, inviteReducer } from "@store/invite/reducer";
import { ManagerState, managerReducer } from "@store/manager/reducer";

export interface RootState {
  auth: AuthState;
  profile: ProfileState;
  workspace: WorkspaceState;
  team: TeamState;
  user: UserState;
  department: DepartmentState;
  challenge: ChallengeState;
  invite: InviteState;
  manager: ManagerState;
}

export default combineReducers({
  auth: authReducer,
  profile: profileReducer,
  workspace: workspaceReducer,
  team: teamReducer,
  user: userReducer,
  department: departmentReducer,
  challenge: challengeReducer,
  invite: inviteReducer,
  manager: managerReducer,
});
