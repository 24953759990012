import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import {
  Button,
  DialogContent,
  Drawer,
  Grid,
  IconButton,
  Sheet,
  Stack,
  styled,
  Typography,
} from "@mui/joy";
import { Close, DeleteOutlined } from "@mui/icons-material";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { SubmitHandler, useForm } from "react-hook-form";
import { createTeam, deleteTeam, updateTeam } from "@store/team/actions";
import { TextInput } from "@components/form/TextInput";
import { ITeam } from "@store/team/model";
import { TextareaInput } from "@components/form/TextareaInput";
import { UploadInput } from "@components/form/UploadInput";
import { MembersType, UploadType } from "@constants";
import Box from "@mui/joy/Box";
import { useConfirm } from "mui-joy-confirm";
import { ManageMembersTable } from "@components/ManageMembersTable";

type TeamDrawerProps = {
  open: boolean;
  editing?: ITeam;
  onClose: () => void;
};

type Inputs = {
  name: string;
  description?: string;
};

const validationSchema = yup
  .object({
    name: yup.string().required("Name is required"),
    description: yup.string(),
  })
  .required();

export const TeamDrawer: FC<TeamDrawerProps> = (props) => {
  const { editing } = props;
  const [loading, setLoading] = useState(false);
  const confirm = useConfirm();
  const dispatch = useDispatch<any>();
  const uploadInputRef = useRef(null);

  const {
    register,
    reset,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>({
    // @ts-ignore
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    if (props.open) {
      let initialValues: Partial<Inputs> = {
        name: "",
        description: "",
      };

      if (editing) {
        initialValues = {
          name: editing.name,
          description: editing.description,
        };
      }

      reset(initialValues);
    }
  }, [props.open, editing]);

  const onSubmit: SubmitHandler<Inputs> = useCallback(
    async (data) => {
      setLoading(true);

      const logo = await uploadInputRef.current.handleUpload();
      const teamData = {
        name: data.name,
        description: data.description,
        logo,
      };

      if (editing) {
        dispatch(
          updateTeam(
            {
              ...teamData,
              teamId: editing.id,
            },
            () => {
              setLoading(false);
              props.onClose();
            },
          ),
        );
      } else {
        dispatch(
          createTeam(teamData, () => {
            setLoading(false);
            props.onClose();
          }),
        );
      }
    },
    [editing],
  );

  const handleDelete = useCallback(async () => {
    confirm({
      title: "Delete this team?",
      description: "This action is permanent!",
      confirmationText: "Delete",
      cancellationText: "Cancel",
      confirmationButtonProps: {
        color: "danger",
      },
      cancellationButtonProps: {
        variant: "outlined",
        color: "neutral",
      },
    })
      .then(() => {
        dispatch(
          deleteTeam({ teamId: editing.id }, ({ success }) => {
            if (success) {
              props.onClose();
            }
          }),
        );
      })
      .catch(() => {});
  }, [editing]);

  return (
    <Drawer anchor="right" size="md" open={props.open} onClose={props.onClose}>
      {props.open ? (
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Sheet sx={{ px: 6, pt: 8, bgcolor: "white", flex: 1 }}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              mb={6}
            >
              <Typography level="h2" fontWeight="600">
                {editing ? "Edit Team" : "Add Team"}
              </Typography>
              <IconButton size="lg" onClick={props.onClose}>
                <Close />
              </IconButton>
            </Stack>
          </Sheet>
          <DialogContent>
            <Sheet
              sx={{
                px: 6,
                pb: 8,
                bgcolor: "white",
                flex: 1,
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <Stack gap={6}>
                <UploadInput
                  ref={uploadInputRef}
                  type={UploadType.LOGO}
                  image={editing?.logo}
                />
                <TextInput
                  {...register("name")}
                  label="Name"
                  placeholder="Select"
                  error={!!errors.name}
                  errorMessage={errors.name?.message}
                  required
                />
                <TextareaInput
                  {...register("description")}
                  label="Description"
                  placeholder="Description"
                  minRows={4}
                />
                {editing ? (
                  <>
                    <ManageMembersTable
                      type={MembersType.TEAM}
                      id={editing.id}
                    />
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      pt={4}
                    >
                      <Box>
                        <Typography fontWeight="bold">Delete team?</Typography>
                        <Typography>
                          This action can not be reversed.
                        </Typography>
                      </Box>
                      <Button
                        color="danger"
                        variant="plain"
                        sx={{ boxShadow: "sm" }}
                        startDecorator={<DeleteOutlined />}
                        onClick={handleDelete}
                      >
                        Delete
                      </Button>
                    </Stack>
                  </>
                ) : null}
              </Stack>
            </Sheet>
          </DialogContent>
          <Sheet
            sx={{
              px: 8,
              py: 5,
              bgcolor: "white",
              borderTopWidth: 1,
              borderTopStyle: "solid",
              borderTopColor: "neutral.200",
            }}
          >
            <Grid container spacing={4}>
              <Grid xs={6}>
                <Button
                  size="lg"
                  color="neutral"
                  variant="plain"
                  sx={{ boxShadow: "sm", width: "100%" }}
                  onClick={props.onClose}
                >
                  Discard
                </Button>
              </Grid>
              <Grid xs={6}>
                <Button
                  type="submit"
                  size="lg"
                  color="primary"
                  variant="solid"
                  sx={{ boxShadow: "sm", width: "100%" }}
                  onClick={handleSubmit(onSubmit)}
                  loading={loading}
                >
                  {editing ? "Edit" : "Add"}
                </Button>
              </Grid>
            </Grid>
          </Sheet>
        </Form>
      ) : null}
    </Drawer>
  );
};

const Form = styled("form")`
  display: flex;
  flex-direction: column;
  flex: 1;
`;
