import React, { FC, useCallback } from "react";
import Box from "@mui/joy/Box";
import Table from "@mui/joy/Table";
import Typography from "@mui/joy/Typography";
import Sheet from "@mui/joy/Sheet";
import IconButton from "@mui/joy/IconButton";
import { Avatar, Stack } from "@mui/joy";
import { EditOutlined } from "@mui/icons-material";
import { IDataList } from "@store/model";
import { IDepartment } from "@store/department/model";
import { TableBody, TableContainer } from "@mui/material";
import { Footer } from "@components/table/Footer";
import { Header } from "@components/table/Header";
import { Placeholder } from "@components/table/Placeholder";

const headCells = ["Department", "Description", "Members Count"];

const headCellsSize = headCells.length + 1;

type DepartmentsTableProps = {
  onPaginate: (page: number, limit?: number) => void;
  onEdit: (department: IDepartment) => void;
  currentPage: number;
  totalPages: number;
  departments: IDataList<IDepartment>;
};

export const DepartmentsTable: FC<DepartmentsTableProps> = (props) => {
  const { onPaginate, onEdit, departments, currentPage, totalPages } = props;

  const handleEdit = useCallback((department: IDepartment) => {
    onEdit(department);
  }, []);

  return (
    <Sheet
      variant="outlined"
      sx={{
        width: "100%",
        boxShadow: "sm",
        borderRadius: "xl",
        overflow: "hidden",
      }}
    >
      <TableContainer>
        <Table>
          <Header headCells={headCells} />
          <TableBody>
            {departments.items?.map((department) => {
              return (
                <tr key={department.id}>
                  <td>
                    <Stack direction="row" gap={3} alignItems="center">
                      <Avatar alt={department.name} src={department.logo} />
                      <Box>
                        <Typography fontWeight="500" fontSize="sm">
                          {department.name}
                        </Typography>
                      </Box>
                    </Stack>
                  </td>
                  <td>{department.description}</td>
                  <td>{department.membersCount}</td>
                  <td>
                    <Stack direction="row" gap={4} justifyContent="flex-end">
                      <IconButton
                        size="lg"
                        sx={{ boxShadow: "sm" }}
                        onClick={() => handleEdit(department)}
                      >
                        <EditOutlined />
                      </IconButton>
                    </Stack>
                  </td>
                </tr>
              );
            })}
            {!departments?.total ? (
              <Placeholder headCellsSize={headCellsSize} />
            ) : null}
          </TableBody>
          <Footer
            headCellsSize={headCellsSize}
            currentPage={currentPage}
            totalPages={totalPages}
            onPaginate={onPaginate}
          />
        </Table>
      </TableContainer>
    </Sheet>
  );
};
