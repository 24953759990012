import { PayloadAction } from "@reduxjs/toolkit";
import createReducer from "@store/createReducer";
import { IDataList } from "@store/model";
import {
  ActionType,
  IDepartment,
  IDepartmentMember,
} from "@store/department/model";
import { DEFAULT_LIMIT } from "@constants";

export interface DepartmentState {
  departments: IDataList<IDepartment>;
  departmentMembers: IDataList<IDepartmentMember>;
  departmentInfo: IDepartment | null;
}

const defaultState: DepartmentState = {
  departments: {
    total: 0,
    offset: 0,
    limit: DEFAULT_LIMIT,
    items: [],
  },
  departmentMembers: {
    total: 0,
    offset: 0,
    limit: DEFAULT_LIMIT,
    items: [],
  },
  departmentInfo: null,
};

export const departmentReducer = createReducer<DepartmentState>(defaultState, {
  [ActionType.SET_DEPARTMENTS](
    state: DepartmentState,
    action: PayloadAction<IDataList<IDepartment>>,
  ) {
    return {
      ...state,
      departments: action.payload,
    };
  },
  [ActionType.SET_DEPARTMENT_MEMBERS](
    state: DepartmentState,
    action: PayloadAction<IDataList<IDepartmentMember>>,
  ) {
    return {
      ...state,
      departmentMembers: action.payload,
    };
  },
  [ActionType.SET_DEPARTMENT_INFO](
    state: DepartmentState,
    action: PayloadAction<IDepartment>,
  ) {
    return {
      ...state,
      departmentInfo: action.payload,
    };
  },
  [ActionType.RESET]() {
    return defaultState;
  },
});
