import Card from "@mui/joy/Card";
import { Grid } from "@mui/joy";
import CardContent from "@mui/joy/CardContent";
import React, { FC } from "react";
import { ManagerProfileForm } from "@components/ManagerProfileForm";
import { ManagerPasswordForm } from "@components/ManagerPasswordForm";

export const ManagerProfile: FC = () => {
  return (
    <Card sx={{ bgcolor: "white", p: 0, borderRadius: "xl" }}>
      <CardContent sx={{ p: 6 }}>
        <Grid container spacing={6}>
          <Grid xs={12} lg={6}>
            <ManagerProfileForm />
          </Grid>
          <Grid xs={12} lg={6}>
            <ManagerPasswordForm />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
