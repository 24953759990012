import { call, fork, put, select, takeLatest } from "redux-saga/effects";
import { ActionCallback } from "@store/model";
import { resetTeam } from "@store/team/actions";
import {
  getWorkspaces,
  setActiveWorkspace,
  setWorkspaceManagers,
  setWorkspaces,
} from "@store/workspace/actions";
import {
  ActionType,
  IGetWorkspacesPayload,
  IUpdateWorkspacePayload,
  IWorkspace,
  IWorkspaceManagersParams,
} from "@store/workspace/model";
import * as api from "@services/api/workspace";

function* getWorkspacesSaga(): Generator {
  yield takeLatest(
    ActionType.GET_WORKSPACES,
    function* (action: ActionCallback<IGetWorkspacesPayload>) {
      const { payload, callback } = action;
      try {
        const data = yield call(api.getWorkspaces);
        let activeWorkspace = yield select(
          (state) => state.workspace.activeWorkspace,
        );

        if (payload.workspaceId) {
          activeWorkspace = data.items.find(
            (item: IWorkspace) => item.workspace.id === payload.workspaceId,
          );
        } else {
          activeWorkspace = data.items.find(
            (item: IWorkspace) =>
              item.workspace.id === activeWorkspace?.workspace.id,
          );
        }

        if (!activeWorkspace) {
          yield put(setActiveWorkspace(data.items[0]));
        } else {
          yield put(setActiveWorkspace(activeWorkspace));
        }

        yield put(setWorkspaces(data));

        callback?.({
          success: true,
          data,
        });
      } catch (e) {
        callback?.({
          success: false,
        });
      }
    },
  );
}

function* getWorkspaceManagersSaga(): Generator {
  yield takeLatest(
    ActionType.GET_WORKSPACE_MANAGERS,
    function* (action: ActionCallback<IWorkspaceManagersParams>) {
      const { payload, callback } = action;
      try {
        const data = yield call(api.getWorkspaceManagers, payload);

        yield put(setWorkspaceManagers(data));

        callback?.({
          success: true,
          data,
        });
      } catch (e) {
        callback?.({
          success: false,
        });
      }
    },
  );
}

function* updateWorkspaceSaga(): Generator {
  yield takeLatest(
    ActionType.UPDATE_WORKSPACE,
    function* (action: ActionCallback<IUpdateWorkspacePayload>) {
      const { payload, callback } = action;
      try {
        yield call(api.updateWorkspace, payload);

        yield put(getWorkspaces({}, callback));
      } catch (e) {
        callback?.({
          success: false,
        });
      }
    },
  );
}

function* setActiveWorkspaceSaga(): Generator {
  yield takeLatest(ActionType.SET_ACTIVE_WORKSPACE, function* () {
    try {
      yield put(resetTeam());
    } catch (e) {}
  });
}

export default [
  fork(getWorkspacesSaga),
  fork(getWorkspaceManagersSaga),
  fork(updateWorkspaceSaga),
  fork(setActiveWorkspaceSaga),
];
