import React, { FC } from "react";
import { CircularProgress, Stack, styled } from "@mui/joy";

export const Loader: FC = () => {
  return (
    <LoaderContainer>
      <CircularProgress />
    </LoaderContainer>
  );
};

const LoaderContainer = styled(Stack)`
  justify-content: center;
  align-items: center;
  flex: 1;
`;
