import { AxiosResponse } from "axios";
import { axiosInstance } from "@services/api";
import {
  ApiChallengeActivityResponse,
  ApiChallengeHistoriesResponse,
  ApiChallengeResponse,
  ApiChallengesResponse,
  ApiChallengeSummaryResponse,
  ApiChallengeTeamLeaderboardResponse,
  ApiChallengeUserLeaderboardResponse,
  ApiCreateChallengePayload,
  ApiDeleteChallengeParams,
  ApiGetChallengeActivityParams,
  ApiGetChallengeHistoryParams,
  ApiGetChallengeInfoParams,
  ApiGetChallengeLeaderboardParams,
  ApiGetChallengesParams,
  ApiGetChallengeSummaryParams,
  ApiUpdateChallengePayload,
} from "./challenge.types";

export const getChallenges = async (
  params: ApiGetChallengesParams,
): Promise<ApiChallengesResponse> => {
  const response: AxiosResponse<ApiChallengesResponse> =
    await axiosInstance.get(`/challenge/list`, { params });

  return response.data;
};

export const getChallengeInfo = async (
  params: ApiGetChallengeInfoParams,
): Promise<ApiChallengeResponse> => {
  const response: AxiosResponse<ApiChallengeResponse> = await axiosInstance.get(
    `/challenge/${params.challengeId}/info`,
  );

  return response.data;
};

export const getChallengeActivity = async (
  params: ApiGetChallengeActivityParams,
): Promise<ApiChallengeActivityResponse> => {
  const response: AxiosResponse<ApiChallengeActivityResponse> =
    await axiosInstance.get(`/challenge/${params.challengeId}/activity`);

  return response.data;
};

export const getChallengeHistory = async (
  params: ApiGetChallengeHistoryParams,
): Promise<ApiChallengeHistoriesResponse> => {
  const { challengeId, ...restParams } = params;

  const response: AxiosResponse<ApiChallengeHistoriesResponse> =
    await axiosInstance.get(`/challenge/${challengeId}/history`, {
      params: restParams,
    });

  return response.data;
};

export const getChallengeTeamLeaderboard = async (
  params: ApiGetChallengeLeaderboardParams,
): Promise<ApiChallengeTeamLeaderboardResponse> => {
  const { challengeId, ...restParams } = params;
  const response: AxiosResponse<ApiChallengeTeamLeaderboardResponse> =
    await axiosInstance.get(`/challenge/${challengeId}/leaderboard/team`, {
      params: restParams,
    });

  return response.data;
};

export const getChallengeUserLeaderboard = async (
  params: ApiGetChallengeLeaderboardParams,
): Promise<ApiChallengeUserLeaderboardResponse> => {
  const { challengeId, ...restParams } = params;
  const response: AxiosResponse<ApiChallengeUserLeaderboardResponse> =
    await axiosInstance.get(`/challenge/${challengeId}/leaderboard/user`, {
      params: restParams,
    });

  return response.data;
};

export const getChallengeSummary = async (
  params: ApiGetChallengeSummaryParams,
): Promise<ApiChallengeSummaryResponse> => {
  const response: AxiosResponse<ApiChallengeSummaryResponse> =
    await axiosInstance.get(
      `/challenge/${params.challengeId}/summary/${params.challengeItemId}`,
    );

  return response.data;
};

export const createChallenge = async (
  data: ApiCreateChallengePayload,
): Promise<undefined> => {
  const response: AxiosResponse<undefined> = await axiosInstance.post(
    `/challenge`,
    data,
  );

  return response.data;
};

export const updateChallenge = async (
  data: ApiUpdateChallengePayload,
): Promise<undefined> => {
  const { challengeId, ...restData } = data;

  const response: AxiosResponse<undefined> = await axiosInstance.post(
    `/challenge/${challengeId}`,
    restData,
  );

  return response.data;
};

export const deleteChallenge = async (
  params: ApiDeleteChallengeParams,
): Promise<undefined> => {
  const response: AxiosResponse<undefined> = await axiosInstance.delete(
    `/challenge/${params.challengeId}`,
  );

  return response.data;
};
