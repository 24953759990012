import { RootState } from "@store";
import { DEFAULT_LIMIT } from "@constants";
import { createSelector } from "@reduxjs/toolkit";
import { IDataList } from "@store/model";
import { IInvite } from "@store/invite/model";

export const selectInvites = (state: RootState) => {
  return state.invite.invites;
};

export const selectPagination = createSelector(
  (state: RootState) => state.invite.invites,
  (invites: IDataList<IInvite>) => ({
    limit: invites?.limit || DEFAULT_LIMIT,
    offset: invites?.offset || 0,
    totalPages: Math.ceil(invites?.total / invites?.limit) || 0,
    page: Math.ceil(invites?.offset / invites?.limit) + 1 || 0,
  }),
);
