import React, { FC, useCallback, useState } from "react";
import Box from "@mui/joy/Box";
import FormControl from "@mui/joy/FormControl";
import Select from "@mui/joy/Select";
import Option from "@mui/joy/Option";
import IconButton from "@mui/joy/IconButton";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import TableFooter from "@mui/material/TableFooter";
import { DEFAULT_LIMIT } from "@constants";

type FooterProps = {
  headCellsSize: number;
  currentPage: number;
  totalPages: number;
  onPaginate: (page: number, limit: number) => void;
};

export const Footer: FC<FooterProps> = (props) => {
  const { headCellsSize, currentPage, totalPages, onPaginate } = props;
  const [limit, setLimit] = useState<number>(DEFAULT_LIMIT);

  const handleChangePage = useCallback(
    (page: number) => {
      onPaginate(page, limit);
    },
    [limit, onPaginate],
  );

  const handleChangeRowsPerPage = useCallback(
    (event: any, limit: number | null) => {
      setLimit(limit);
      onPaginate(1, limit);
    },
    [onPaginate],
  );

  return (
    <TableFooter>
      <tr>
        <td colSpan={headCellsSize}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 2,
            }}
          >
            <FormControl orientation="horizontal" size="sm">
              <Select onChange={handleChangeRowsPerPage} value={limit}>
                <Option value={5}>5</Option>
                <Option value={10}>10</Option>
                <Option value={25}>25</Option>
              </Select>
            </FormControl>
            <Box sx={{ display: "flex", gap: 1 }}>
              <IconButton
                size="sm"
                color="neutral"
                variant="outlined"
                disabled={currentPage === 1}
                onClick={() => handleChangePage(currentPage - 1)}
                sx={{ bgcolor: "background.surface" }}
              >
                <KeyboardArrowLeftIcon />
              </IconButton>
              <IconButton
                size="sm"
                color="neutral"
                variant="outlined"
                disabled={currentPage === totalPages || totalPages === 0}
                onClick={() => handleChangePage(currentPage + 1)}
                sx={{ bgcolor: "background.surface" }}
              >
                <KeyboardArrowRightIcon />
              </IconButton>
            </Box>
          </Box>
        </td>
      </tr>
    </TableFooter>
  );
};
