import { all } from "redux-saga/effects";
import authSagas from "@store/auth/saga";
import departmentSagas from "@store/department/saga";
import profileSagas from "@store/profile/saga";
import teamSagas from "@store/team/saga";
import userSagas from "@store/user/saga";
import workspaceSagas from "@store/workspace/saga";
import challengeSagas from "@store/challenge/saga";
import inviteSagas from "@store/invite/saga";
import managerSagas from "@store/manager/saga";

export default function* rootSaga() {
  yield all([
    ...authSagas,
    ...profileSagas,
    ...workspaceSagas,
    ...teamSagas,
    ...userSagas,
    ...departmentSagas,
    ...challengeSagas,
    ...inviteSagas,
    ...managerSagas,
  ]);
}
