import React, { FC, useCallback } from "react";
import Card from "@mui/joy/Card";
import { Button, Stack, styled, Typography } from "@mui/joy";
import { TopWinner } from "@components/TopWinner";
import {
  IChallenge,
  IChallengeLeaderboard,
  IChallengeTeamLeaderboard,
  IChallengeUserLeaderboard,
} from "@store/challenge/model";
import { useAppSelector } from "@store/hooks";
import {
  getChallengeTeamLeaderboard,
  getChallengeUserLeaderboard,
} from "@store/challenge/actions";
import { Loader } from "@components/Loader";
import useLoadData from "@/hooks/useLoadData";
import { selectChallengeLeaderboard } from "@store/challenge/selectors";
import {
  mapChallengeTeamLeaderboard,
  mapChallengeUserLeaderboard,
} from "@services/utils/challenge";
import { LeaderboardType } from "@constants";
import { capitalize, slice } from "lodash";
import { LeaderboardItem } from "@components/challenge/LeaderboardItem";

type LeaderboardProps = {
  challenge: IChallenge;
  type: LeaderboardType;
};

type ChallengeLeaderboard =
  | IChallengeUserLeaderboard
  | IChallengeTeamLeaderboard;

const mapTypeToAction = {
  [LeaderboardType.USER]: getChallengeUserLeaderboard,
  [LeaderboardType.TEAM]: getChallengeTeamLeaderboard,
};

const mapTypeToMapper = {
  [LeaderboardType.USER]: (data) =>
    mapChallengeUserLeaderboard(data as IChallengeUserLeaderboard[]),
  [LeaderboardType.TEAM]: (data) =>
    mapChallengeTeamLeaderboard(data as IChallengeTeamLeaderboard[]),
};

export const Leaderboard: FC<LeaderboardProps> = (props, ref) => {
  const { challenge, type } = props;
  const { loaded, loading, handlePaginate } = useLoadData<ChallengeLeaderboard>(
    mapTypeToAction[type],
    undefined,
    { challengeId: challenge.challengeId },
  );

  const leaderboard = useAppSelector((state) =>
    selectChallengeLeaderboard(state, type),
  );

  const mappedLeaderboardData = mapTypeToMapper[type](leaderboard?.items || []);
  const hasNextPage =
    leaderboard?.total > leaderboard?.offset + leaderboard?.limit;

  const handleLoadMore = useCallback(() => {
    const page = Math.ceil(leaderboard.offset / leaderboard.limit) + 2;
    handlePaginate(page, leaderboard.limit);
  }, [leaderboard, handlePaginate]);

  const [first, second, third] = mappedLeaderboardData;

  return (
    <Stack gap={4}>
      <Card sx={{ bgcolor: "white", borderRadius: "xl", p: 6, gap: 6 }}>
        {!loaded && loading ? (
          <Loader />
        ) : (
          <>
            <Typography fontWeight="600" fontSize={16}>
              Leaderboard by {capitalize(type)}
            </Typography>
            <Stack direction="row" gap={4}>
              <Stack flex={1} justifyContent="flex-end" gap={3}>
                <TopWinner leaderboard={second} unit={challenge.unit} />
                <SecondBar>
                  <Order fontWeight="700" fontSize={20}>
                    2
                  </Order>
                </SecondBar>
              </Stack>
              <Stack flex={1} justifyContent="flex-end" gap={3}>
                <TopWinner leaderboard={first} unit={challenge.unit} />
                <Bar>
                  <Order fontWeight="700" fontSize={20}>
                    1
                  </Order>
                </Bar>
              </Stack>
              <Stack flex={1} justifyContent="flex-end" gap={3}>
                <TopWinner leaderboard={third} unit={challenge.unit} />
                <ThirdBar>
                  <Order fontWeight="700" fontSize={20}>
                    3
                  </Order>
                </ThirdBar>
              </Stack>
            </Stack>
            <Stack gap={4}>
              {slice(mappedLeaderboardData, 3).map(
                (leaderboard: IChallengeLeaderboard, index: number) => (
                  <LeaderboardItem
                    key={`${type}-leaderboard-${index}`}
                    leaderboard={leaderboard}
                    unit={challenge.unit}
                  />
                ),
              )}
            </Stack>
          </>
        )}
      </Card>
      {hasNextPage ? (
        <Button
          size="lg"
          color="neutral"
          variant="plain"
          sx={{
            boxShadow: "sm",
            width: "100%",
            bgcolor: "white",
          }}
          onClick={handleLoadMore}
          loading={loading}
        >
          Show More
        </Button>
      ) : null}
    </Stack>
  );
};

const Bar = styled(Stack)`
  border-top-left-radius: ${({ theme }) => theme.radius.lg};
  border-top-right-radius: ${({ theme }) => theme.radius.lg};
  height: 108px;
  background-color: ${({ theme }) => theme.palette.primary["700"]};
  align-items: center;
  justify-content: center;
`;

const SecondBar = styled(Bar)`
  height: 60px;
  background-color: ${({ theme }) => theme.palette.primary["500"]};
`;

const ThirdBar = styled(Bar)`
  height: 48px;
  background-color: ${({ theme }) => theme.palette.primary["400"]};
`;

const Order = styled(Typography)`
  color: ${({ theme }) => theme.palette.common.white};
`;
