import React, { FC } from "react";
import {
  List,
  ListItemButton,
  ListItemContent,
  ListItemDecorator,
  Sheet,
  Stack,
  styled,
} from "@mui/joy";
import { NavLink, useLocation } from "react-router-dom";
import EmojiEventsOutlinedIcon from "@mui/icons-material/EmojiEventsOutlined";
import SupervisedUserCircleOutlined from "@mui/icons-material/SupervisedUserCircleOutlined";
import StarsOutlined from "@mui/icons-material/StarsOutlined";
import HomeWorkOutlined from "@mui/icons-material/HomeWorkOutlined";
import { Workspaces } from "@components/Workspaces";

const ROUTES = [
  {
    title: "Challenges",
    icon: <EmojiEventsOutlinedIcon />,
    link: "/challenges",
  },
  {
    title: "People",
    icon: <SupervisedUserCircleOutlined />,
    link: "/people",
  },
  {
    title: "Rewards",
    icon: <StarsOutlined />,
    link: "/rewards",
  },
  {
    title: "Organization",
    icon: <HomeWorkOutlined />,
    link: "/organization",
  },
];

export const Sidebar: FC = () => {
  const { pathname } = useLocation();

  return (
    <Container>
      <Workspaces />
      <Nav px={2} py={1}>
        <List sx={{ gap: 1 }} size="sm">
          {ROUTES.map((route) => {
            const selected = pathname === route.link;

            return (
              <ListItemButton
                key={`sidebar-link-${route.link}`}
                color={selected ? "primary" : "neutral"}
                selected={selected}
                sx={{ gap: 4, px: 4, py: 2, borderRadius: "md" }}
                component={NavLink}
                to={route.link}
              >
                <ListItemDecorator>{route.icon} </ListItemDecorator>
                <ListItemContent>{route.title}</ListItemContent>
              </ListItemButton>
            );
          })}
        </List>
      </Nav>
    </Container>
  );
};

const Container = styled(Sheet)`
  flex: 0 0 260px;

  ${({ theme }) => theme.breakpoints.down("lg")} {
    max-width: 200px;
  }
  ${({ theme }) => theme.breakpoints.down("md")} {
    border-bottom: 1px solid ${({ theme }) => theme.palette.neutral["200"]};
    min-width: 100vw;
    position: absolute;
    top: 0;
    z-index: 999;
  }
`;

const Nav = styled(Stack)`
  ${({ theme }) => theme.breakpoints.down("md")} {
    display: none;
  }
`;
