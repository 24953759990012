import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import {
  Button,
  DialogContent,
  Drawer,
  FormControl,
  FormLabel,
  Grid,
  IconButton,
  Sheet,
  Stack,
  styled,
  Typography,
} from "@mui/joy";
import { Close, DeleteOutlined } from "@mui/icons-material";
import { TextInput } from "@components/form/TextInput";
import { UploadInput } from "@components/form/UploadInput";
import { UploadType } from "@constants";
import Box from "@mui/joy/Box";
import { useConfirm } from "mui-joy-confirm";
import { IUser } from "@store/user/model";
import { PhoneInput } from "@components/form/PhoneInput";
import { capitalize } from "lodash";
import { dropUser, setUserDepartment } from "@store/user/actions";
import { ManageTeamsTable } from "@components/ManagerTeamsTable";
import { Autocomplete } from "@components/Autocomplete";
import { getDepartments } from "@services/api/department";
import { IDepartment } from "@store/department/model";

const teamsEnabled = false;

type TeamDrawerProps = {
  open: boolean;
  user?: IUser;
  onClose: () => void;
};

export const EmployeeDrawer: FC<TeamDrawerProps> = (props) => {
  const [user, setUser] = useState(props.user);
  const [loading, setLoading] = useState(false);
  const confirm = useConfirm();
  const dispatch = useDispatch<any>();
  const uploadInputRef = useRef(null);

  useEffect(() => {
    setUser(props.user);
  }, [props.user]);

  const handleDelete = useCallback(async () => {
    confirm({
      title: "Remove this user from workspace?",
      description: "This action is permanent!",
      confirmationText: "Remove",
      cancellationText: "Cancel",
      confirmationButtonProps: {
        color: "danger",
      },
      cancellationButtonProps: {
        variant: "outlined",
        color: "neutral",
      },
    })
      .then(() => {
        dispatch(
          dropUser({ userId: user.user.id }, ({ success }) => {
            if (success) {
              props.onClose();
            }
          }),
        );
      })
      .catch(() => {});
  }, [user]);

  const handleChangeDepartment = useCallback(
    (department: IDepartment) => {
      setLoading(true);
      dispatch(
        setUserDepartment(
          {
            userId: user.user.id,
            departmentId: department.id,
          },
          () => {
            setLoading(false);
          },
        ),
      );
      setUser({
        ...user,
        department,
      });
    },
    [user],
  );

  return (
    <Drawer anchor="right" size="md" open={props.open} onClose={props.onClose}>
      {props.open && user ? (
        <Form>
          <Sheet sx={{ px: 6, pt: 8, bgcolor: "white", flex: 1 }}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              mb={6}
            >
              <Typography level="h2" fontWeight="600">
                Employee Profile
              </Typography>
              <IconButton size="lg" onClick={props.onClose}>
                <Close />
              </IconButton>
            </Stack>
          </Sheet>
          <DialogContent>
            <Sheet
              sx={{
                px: 6,
                pb: 8,
                bgcolor: "white",
                flex: 1,
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <Stack gap={6}>
                <UploadInput
                  ref={uploadInputRef}
                  type={UploadType.LOGO}
                  image={user.profile?.avatar}
                  readOnly
                />
                <Grid container spacing={6}>
                  <Grid xs={12} lg={6}>
                    <TextInput
                      label="First Name"
                      value={user.profile?.firstName}
                      readOnly
                    />
                  </Grid>
                  <Grid xs={12} lg={6}>
                    <TextInput
                      label="Last Name"
                      value={user.profile?.lastName}
                      readOnly
                    />
                  </Grid>
                </Grid>
                <TextInput
                  label="Gender"
                  value={capitalize(user.profile?.gender)}
                  readOnly
                />
                <TextInput label="Email" value={user.profile?.email} readOnly />
                <TextInput
                  label="Date of Birth"
                  value={user.profile?.dateOfBirth}
                  readOnly
                />
                <PhoneInput
                  value={user.user.phoneNumber}
                  label="Phone Number"
                  readOnly
                />

                <FormControl>
                  <FormLabel>Department</FormLabel>
                  <Autocomplete
                    action={getDepartments}
                    getOptionLabel={(department: IDepartment) =>
                      department.name
                    }
                    getOptionImage={(department: IDepartment) =>
                      department.logo
                    }
                    value={user.department}
                    onChange={handleChangeDepartment}
                    loading={loading}
                  />
                </FormControl>

                {teamsEnabled ? <ManageTeamsTable id={user.user.id} /> : null}

                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  pt={4}
                >
                  <Box>
                    <Typography fontWeight="bold">
                      Remove employee from workspace?
                    </Typography>
                    <Typography>This action can not be reversed.</Typography>
                  </Box>
                  <Button
                    color="danger"
                    variant="plain"
                    sx={{ boxShadow: "sm" }}
                    startDecorator={<DeleteOutlined />}
                    onClick={handleDelete}
                  >
                    Remove
                  </Button>
                </Stack>
              </Stack>
            </Sheet>
          </DialogContent>
          <Sheet
            sx={{
              px: 8,
              py: 5,
              bgcolor: "white",
              borderTopWidth: 1,
              borderTopStyle: "solid",
              borderTopColor: "neutral.200",
            }}
          >
            <Button
              size="lg"
              color="neutral"
              variant="plain"
              sx={{ boxShadow: "sm", width: "100%" }}
              onClick={props.onClose}
            >
              Close
            </Button>
          </Sheet>
        </Form>
      ) : null}
    </Drawer>
  );
};

const Form = styled("form")`
  display: flex;
  flex-direction: column;
  flex: 1;
`;
