import pluralize from "pluralize";
import React from "react";
import { IChallengeLeaderboard } from "@store/challenge/model";
import { Avatar, Stack, styled, Typography } from "@mui/joy";

type LeaderboardItemProps = {
  leaderboard: IChallengeLeaderboard;
  unit: string;
};

export const LeaderboardItem = (props: LeaderboardItemProps) => {
  const { leaderboard, unit } = props;

  return (
    <Participant flexDirection="row" gap={2} px={4} py={3} alignItems="center">
      <Position
        fontSize={14}
        color="neutral"
        fontWeight="600"
        textAlign="center"
      >
        {leaderboard.order}
      </Position>
      <Avatar size="lg" src={leaderboard.image} />
      <Stack justifyContent="center">
        <Typography fontSize={14} fontWeight="500">
          {leaderboard.name}
        </Typography>
        <Typography fontSize={14} color="neutral">
          {leaderboard.sub}
        </Typography>
      </Stack>
      <Typography fontSize={14} textAlign="right" ml="auto">
        {leaderboard.averageValue} {pluralize(unit, leaderboard.averageValue)}
      </Typography>
    </Participant>
  );
};

const Participant = styled(Stack)`
  border-radius: ${({ theme }) => theme.radius.lg};
  background-color: ${({ theme }) => theme.palette.neutral["100"]};
`;

const Position = styled(Typography)`
  width: 20px;
`;
