import { call, fork, put, takeLatest } from "redux-saga/effects";
import { resetAuth, setAuth, setLoggedIn } from "@store/auth/actions";
import { ActionType, ISignInPayload } from "@store/auth/model";
import { resetDepartment } from "@store/department/actions";
import { ActionCallback, ActionCallbackOnly } from "@store/model";
import { resetProfile } from "@store/profile/actions";
import { resetTeam } from "@store/team/actions";
import { resetWorkspace } from "@store/workspace/actions";
import { signIn } from "@services/api/auth";
import { LocalStorageKeys } from "@constants";
import { resetChallenge } from "@store/challenge/actions";
import { resetInvite } from "@store/invite/actions";
import { resetUser } from "@store/user/actions";

function* signInSaga(): Generator {
  yield takeLatest(
    ActionType.SIGN_IN,
    function* (action: ActionCallback<ISignInPayload>) {
      const { payload, callback } = action;
      try {
        const data = yield call(signIn, payload);

        yield call(
          [localStorage, localStorage.setItem],
          LocalStorageKeys.ACCESS_TOKEN,
          data.accessToken,
        );
        yield call(
          [localStorage, localStorage.setItem],
          LocalStorageKeys.REFRESH_TOKEN,
          data.refreshToken,
        );

        yield put(setAuth(data));
        yield put(setLoggedIn(true));

        callback?.({
          success: true,
        });
      } catch (e) {
        callback?.({
          success: false,
        });
      }
    },
  );
}

function* signOutSaga(): Generator {
  yield takeLatest(ActionType.SIGN_OUT, function* (action: ActionCallbackOnly) {
    const { callback } = action;
    try {
      yield put(resetAuth());
      yield put(resetWorkspace());
      yield put(resetTeam());
      yield put(resetDepartment());
      yield put(resetProfile());
      yield put(resetChallenge());
      yield put(resetUser());
      yield put(resetInvite());
      yield call(localStorage.removeItem, LocalStorageKeys.ACCESS_TOKEN);
      yield call(localStorage.removeItem, LocalStorageKeys.REFRESH_TOKEN);
      callback?.({ success: true });
    } catch (e) {
      callback?.({ success: false });
    }
  });
}

export default [fork(signInSaga), fork(signOutSaga)];
