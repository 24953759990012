import React, { FC, useCallback, useState } from "react";
import useLoadApiData from "@/hooks/useLoadApiData";
import useInfiniteScroll from "react-infinite-scroll-hook";
import {
  CircularProgress,
  ListItem,
  styled,
  Autocomplete as JoyAutocomplete,
  Avatar,
} from "@mui/joy";

type AutocompleteProps = {
  action: (params: any) => void;
  getOptionLabel: (option: any) => string;
  getOptionImage: (option: any) => string;
  getOptionImageBackgroundColor?: (option: any) => string;
  multiple?: boolean;
  value: any;
  onChange: (value: any) => void;
  loading?: boolean;
};

export const Autocomplete: FC<AutocompleteProps> = (props) => {
  const {
    action,
    onChange,
    value,
    multiple,
    getOptionLabel,
    getOptionImage,
    getOptionImageBackgroundColor,
  } = props;

  const [search, setSearch] = useState("");
  const { data, loaded, loading, handleLoadMore, hasNextPage } = useLoadApiData(
    action,
    search,
  );

  const [sentryRef, { rootRef }] = useInfiniteScroll({
    loading,
    hasNextPage,
    onLoadMore: handleLoadMore,
  });

  const handleChange = useCallback((event, value) => {
    onChange(value);
  }, []);

  const handleSearch = useCallback((event, value) => {
    setSearch(value);
  }, []);

  const handleRenderSentry = useCallback(() => {
    return hasNextPage || loading ? (
      <ListItem ref={sentryRef} key="sentry">
        <Loader size="sm" />
      </ListItem>
    ) : null;
  }, [hasNextPage, loading]);

  const handleRenderOption = useCallback((optionProps, option) => {
    const { id } = option;

    if (id === "sentry") {
      return handleRenderSentry();
    }

    const label = getOptionLabel(option);
    const image = getOptionImage(option);
    const backgroundColor = getOptionImageBackgroundColor?.(option);

    return (
      <ListItem {...optionProps} key={optionProps.id}>
        <Avatar
          alt={label}
          src={image}
          sx={backgroundColor && { backgroundColor, padding: 2 }}
        />
        {label}
      </ListItem>
    );
  }, []);

  const handleFilterOptions = useCallback(
    (filterOptions) => {
      return [...filterOptions, ...(hasNextPage ? [{ id: "sentry" }] : [])];
    },
    [hasNextPage],
  );

  const handleRenderLoading = useCallback(() => {
    return (
      <ListItem>
        <Loader size="sm" />
      </ListItem>
    );
  }, []);

  return (
    <JoyAutocomplete
      slotProps={{
        listbox: {
          ref: rootRef,
        },
      }}
      slots={{
        loading: handleRenderLoading,
      }}
      filterOptions={handleFilterOptions}
      renderOption={handleRenderOption}
      options={data.items || []}
      getOptionLabel={getOptionLabel}
      onChange={handleChange}
      value={value}
      multiple={multiple}
      inputValue={search}
      onInputChange={handleSearch}
      size="lg"
      type="search"
      endDecorator={props.loading ? <Loader size="sm" /> : null}
      disableCloseOnSelect={multiple}
      disableClearable={!multiple}
      filterSelectedOptions
      loading={loading}
    />
  );
};

const Loader = styled(CircularProgress)`
  margin: 0 auto;
`;
