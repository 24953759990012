import React, { FC, useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  Button,
  DialogContent,
  Drawer,
  FormControl,
  FormLabel,
  Grid,
  IconButton,
  Sheet,
  Stack,
  styled,
  Typography,
} from "@mui/joy";
import { Close, DeleteOutlined, Add } from "@mui/icons-material";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  Controller,
  SubmitHandler,
  useFieldArray,
  useForm,
} from "react-hook-form";
import { createInvite } from "@store/invite/actions";
import { TextareaInput } from "@components/form/TextareaInput";
import Box from "@mui/joy/Box";
import { Autocomplete } from "@components/Autocomplete";
import { getChallenges } from "@services/api/challenge";
import { IChallenge } from "@store/challenge/model";
import { ITeam } from "@store/team/model";
import { IDepartment } from "@store/department/model";
import { getTeams } from "@services/api/team";
import { getDepartments } from "@services/api/department";
import { ICreateInvitePayload } from "@store/invite/model";
import { PhoneInput } from "@components/form/PhoneInput";

const teamsEnabled = false;

type InviteDrawerProps = {
  open: boolean;
  onClose: () => void;
};

type Inputs = {
  phoneNumbers: { value: string }[];
  challenges: IChallenge[];
  teams: ITeam[];
  department: IDepartment;
  message?: string;
};

const validationSchema = yup
  .object({
    phoneNumbers: yup
      .array()
      .of(
        yup
          .object({
            value: yup.string().required("Phone number is required"),
          })
          .required(),
      )
      .required("Phone number is required"),
    message: yup.string(),
  })
  .required();

export const InviteDrawer: FC<InviteDrawerProps> = (props) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch<any>();

  const {
    register,
    reset,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>({
    // @ts-ignore
    resolver: yupResolver(validationSchema),
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: "phoneNumbers",
  });

  useEffect(() => {
    if (props.open) {
      let initialValues: Partial<Inputs> = {
        phoneNumbers: [{ value: "" }],
        challenges: [],
        teams: [],
        department: null,
        message: "",
      };

      reset(initialValues);
    }
  }, [props.open]);

  const onSubmit: SubmitHandler<Inputs> = useCallback(async (data) => {
    setLoading(true);

    const inviteData = data.phoneNumbers.map((phoneNumber) => ({
      phoneNumber: phoneNumber.value.replace(/\s+/g, ""),
      message: data.message,
      actions: {
        departmentId: data.department?.id,
        teamIds: data.teams.map((team: ITeam) => team.id),
        challengeIds: data.challenges.map(
          (challenge: IChallenge) => challenge.challengeId,
        ),
      },
    })) as ICreateInvitePayload;

    dispatch(
      createInvite(inviteData, () => {
        setLoading(false);
        props.onClose();
      }),
    );
  }, []);

  return (
    <Drawer anchor="right" size="md" open={props.open} onClose={props.onClose}>
      {props.open ? (
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Sheet sx={{ px: 6, pt: 8, bgcolor: "white", flex: 1 }}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              mb={6}
            >
              <Typography level="h2" fontWeight="600">
                Invite Users
              </Typography>
              <IconButton size="lg" onClick={props.onClose}>
                <Close />
              </IconButton>
            </Stack>
          </Sheet>
          <DialogContent>
            <Sheet sx={{ px: 6, pb: 8, bgcolor: "white" }}>
              <Stack gap={6}>
                <Section py={4}>
                  <Typography fontSize={16} fontWeight="bold">
                    Invite Via Phone
                  </Typography>
                </Section>
                {fields.map((field, index) => (
                  <Controller
                    key={field.id}
                    control={control}
                    {...register(`phoneNumbers.${index}.value`)}
                    render={({ field: { onChange, value } }) => (
                      <PhoneInput
                        value={value}
                        onChange={onChange}
                        error={errors.phoneNumbers?.[index]?.value}
                        endDecorator={
                          index > 0 ? (
                            <IconButton
                              variant="plain"
                              color="neutral"
                              size="lg"
                              sx={{ boxShadow: "sm" }}
                              onClick={() => remove(index)}
                            >
                              <DeleteOutlined />
                            </IconButton>
                          ) : null
                        }
                      />
                    )}
                  />
                ))}
                <Button
                  size="lg"
                  color="neutral"
                  variant="plain"
                  sx={{
                    boxShadow: "sm",
                    width: "100%",
                    bgcolor: "white",
                  }}
                  startDecorator={<Add />}
                  onClick={() => append({ value: "" })}
                >
                  Add more
                </Button>
                <Section py={4}>
                  <Typography fontSize={16} fontWeight="bold">
                    Invite to
                  </Typography>
                </Section>
                <Controller
                  control={control}
                  {...register(`department`)}
                  render={({ field: { onChange, value } }) => (
                    <FormControl>
                      <FormLabel>Invite to department</FormLabel>
                      <Autocomplete
                        action={getDepartments}
                        getOptionLabel={(department: IDepartment) =>
                          department.name
                        }
                        getOptionImage={(department: IDepartment) =>
                          department.logo
                        }
                        value={value}
                        onChange={onChange}
                      />
                    </FormControl>
                  )}
                />
                {teamsEnabled ? (
                  <Controller
                    control={control}
                    {...register(`teams`)}
                    render={({ field: { onChange, value } }) => (
                      <FormControl>
                        <FormLabel>Invite to teams</FormLabel>
                        <Autocomplete
                          action={getTeams}
                          getOptionLabel={(team: ITeam) => team.name}
                          getOptionImage={(team: ITeam) => team.logo}
                          value={value}
                          multiple
                          onChange={onChange}
                        />
                      </FormControl>
                    )}
                  />
                ) : null}
                <Controller
                  control={control}
                  {...register(`challenges`)}
                  render={({ field: { onChange, value } }) => (
                    <FormControl>
                      <FormLabel>Invite to challenges</FormLabel>
                      <Autocomplete
                        action={getChallenges}
                        getOptionLabel={(challenge: IChallenge) =>
                          challenge.name
                        }
                        getOptionImage={(challenge: IChallenge) =>
                          challenge.image
                        }
                        getOptionImageBackgroundColor={(
                          challenge: IChallenge,
                        ) => challenge.backgroundColor}
                        value={value}
                        multiple
                        onChange={onChange}
                      />
                    </FormControl>
                  )}
                />
                <Section py={4}>
                  <Typography fontSize={16} fontWeight="bold">
                    Text Message
                  </Typography>
                </Section>
                <TextareaInput
                  {...register("message")}
                  placeholder="Message"
                  minRows={4}
                />
              </Stack>
            </Sheet>
          </DialogContent>
          <Sheet
            sx={{
              px: 8,
              py: 5,
              bgcolor: "white",
              borderTopWidth: 1,
              borderTopStyle: "solid",
              borderTopColor: "neutral.200",
            }}
          >
            <Grid container spacing={4}>
              <Grid xs={6}>
                <Button
                  size="lg"
                  color="neutral"
                  variant="plain"
                  sx={{ boxShadow: "sm", width: "100%" }}
                  onClick={props.onClose}
                >
                  Discard
                </Button>
              </Grid>
              <Grid xs={6}>
                <Button
                  type="submit"
                  size="lg"
                  color="primary"
                  variant="solid"
                  sx={{ boxShadow: "sm", width: "100%" }}
                  onClick={handleSubmit(onSubmit)}
                  loading={loading}
                >
                  Invite
                </Button>
              </Grid>
            </Grid>
          </Sheet>
        </Form>
      ) : null}
    </Drawer>
  );
};

const Form = styled("form")`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const Section = styled(Box)`
  border-bottom: 1px solid ${({ theme }) => theme.palette.neutral["200"]};
`;
