import {
  ActionCallback,
  ActionTypeOnly,
  FetchCallback,
  IDataList,
  Nullable,
} from "@store/model";
import {
  ActionType,
  IGetWorkspacesPayload,
  IUpdateWorkspacePayload,
  IWorkspace,
  IWorkspaceManager,
  IWorkspaceManagersParams,
} from "@store/workspace/model";

export const getWorkspaces = (
  payload: IGetWorkspacesPayload,
  callback?: FetchCallback,
): ActionCallback<IGetWorkspacesPayload> => ({
  type: ActionType.GET_WORKSPACES,
  payload,
  callback,
});

export const getWorkspaceManagers = (
  payload: IWorkspaceManagersParams,
  callback?: FetchCallback,
): ActionCallback<IWorkspaceManagersParams> => ({
  type: ActionType.GET_WORKSPACE_MANAGERS,
  payload,
  callback,
});

export const updateWorkspace = (
  payload: IUpdateWorkspacePayload,
  callback?: FetchCallback,
): ActionCallback<IUpdateWorkspacePayload> => ({
  type: ActionType.UPDATE_WORKSPACE,
  payload,
  callback,
});

export const setWorkspaces = (
  payload: Nullable<IDataList<IWorkspace>>,
  callback?: FetchCallback,
): ActionCallback<IDataList<IWorkspace>> => ({
  type: ActionType.SET_WORKSPACES,
  payload,
  callback,
});

export const setActiveWorkspace = (
  payload: IWorkspace,
  callback?: FetchCallback,
): ActionCallback<IWorkspace> => ({
  type: ActionType.SET_ACTIVE_WORKSPACE,
  payload,
  callback,
});

export const setWorkspaceManagers = (
  payload: Nullable<IDataList<IWorkspaceManager>>,
  callback?: FetchCallback,
): ActionCallback<IDataList<IWorkspaceManager>> => ({
  type: ActionType.SET_WORKSPACE_MANAGERS,
  payload,
  callback,
});

export const resetWorkspace = (): ActionTypeOnly => ({
  type: ActionType.RESET,
});
