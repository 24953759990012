import React, { FC, useCallback } from "react";
import Box from "@mui/joy/Box";
import Table from "@mui/joy/Table";
import Typography from "@mui/joy/Typography";
import Sheet from "@mui/joy/Sheet";
import IconButton from "@mui/joy/IconButton";
import { Avatar, Stack } from "@mui/joy";
import { CheckCircle, EditOutlined } from "@mui/icons-material";
import { IDataList } from "@store/model";
import { IUser } from "@store/user/model";
import moment from "moment";
import { TableBody, TableContainer } from "@mui/material";
import { Footer } from "@components/table/Footer";
import { Header } from "@components/table/Header";
import { Placeholder } from "@components/table/Placeholder";

const teamsEnabled = false;

const headCells = [
  "User",
  "Birthday",
  "Department",
  ...(teamsEnabled ? ["Teams"] : []),
  "Last Seen",
  "Email Confirmed",
];

const headCellsSize = headCells.length + 1;

type EmployeesTableProps = {
  onPaginate: (page: number, limit?: number) => void;
  currentPage: number;
  totalPages: number;
  employees: IDataList<IUser>;
  onEdit: (employee: IUser) => void;
};

export const EmployeesTable: FC<EmployeesTableProps> = (props) => {
  const { onPaginate, employees, currentPage, totalPages, onEdit } = props;

  const handleEdit = useCallback((employee: IUser) => {
    onEdit(employee);
  }, []);

  return (
    <Sheet
      variant="outlined"
      sx={{
        width: "100%",
        boxShadow: "sm",
        borderRadius: "xl",
        overflow: "hidden",
      }}
    >
      <TableContainer>
        <Table>
          <Header headCells={headCells} />
          <TableBody>
            {employees.items?.map((employee) => {
              return (
                <tr key={employee.user.id}>
                  <td>
                    <Stack direction="row" gap={3} alignItems="center">
                      <Avatar
                        alt={
                          employee.profile
                            ? `${employee.profile.firstName} ${employee.profile.lastName}`
                            : "User"
                        }
                        src={employee.profile?.avatar}
                      />
                      <Box>
                        <Typography fontWeight="500" fontSize="sm">
                          {employee.profile?.firstName}{" "}
                          {employee.profile?.lastName}
                        </Typography>
                      </Box>
                    </Stack>
                  </td>
                  <td>
                    {employee.profile
                      ? moment(employee.profile.dateOfBirth).format("ll")
                      : null}
                  </td>
                  <td>{employee.department?.name}</td>
                  {teamsEnabled ? <td></td> : null}
                  <td>
                    {employee.user.lastSeen
                      ? moment(employee.user.lastSeen).format("lll")
                      : "Never"}
                  </td>
                  <td>
                    {employee.profile?.emailConfirmed ? (
                      <CheckCircle color="success" />
                    ) : null}
                  </td>
                  <td align="right">
                    <IconButton
                      size="lg"
                      sx={{ boxShadow: "sm" }}
                      onClick={() => handleEdit(employee)}
                    >
                      <EditOutlined />
                    </IconButton>
                  </td>
                </tr>
              );
            })}
            {!employees?.total ? (
              <Placeholder headCellsSize={headCellsSize} />
            ) : null}
          </TableBody>
          <Footer
            headCellsSize={headCellsSize}
            currentPage={currentPage}
            totalPages={totalPages}
            onPaginate={onPaginate}
          />
        </Table>
      </TableContainer>
    </Sheet>
  );
};
