import React, { FC, ReactNode } from "react";
import {
  FormControl,
  FormHelperText,
  FormLabel,
  Autocomplete,
  Typography,
} from "@mui/joy";
import { FieldError } from "react-hook-form";

type PhoneInputProps = {
  label?: string;
  options: { label: string; value: string }[];
  value: any;
  error?: FieldError;
  onChange?: (value: any) => void;
  endDecorator?: ReactNode;
  readOnly?: boolean;
  required?: boolean;
};

export const AutocompleteInput: FC<PhoneInputProps> = (props) => {
  const {
    label,
    options,
    value,
    error,
    onChange,
    endDecorator,
    readOnly,
    required,
  } = props;

  return (
    <FormControl error={!!error} sx={{ flex: 1 }}>
      {label ? (
        <FormLabel>
          {label}
          {required ? <Typography color="danger">*</Typography> : null}
        </FormLabel>
      ) : null}
      <Autocomplete
        placeholder="Select"
        variant="soft"
        size="lg"
        value={value}
        onChange={(e, data) => {
          onChange(data);
        }}
        options={options}
        endDecorator={endDecorator}
        disabled={readOnly}
      />
      {error ? <FormHelperText>{error.message}</FormHelperText> : null}
    </FormControl>
  );
};
