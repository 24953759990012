import { call, fork, put, takeLatest } from "redux-saga/effects";
import { ActionCallback } from "@store/model";
import { setInvites, getInvites } from "@store/invite/actions";
import {
  ActionType,
  IInvitesParams,
  ICreateInvitePayload,
  IDeclineInviteParams,
} from "@store/invite/model";
import * as api from "@services/api/invite";

function* getInvitesSaga(): Generator {
  yield takeLatest(
    ActionType.GET_INVITES,
    function* (action: ActionCallback<IInvitesParams>) {
      const { payload, callback } = action;
      try {
        const data = yield call(api.getInvites, payload);

        yield put(setInvites(data));

        callback?.({
          success: true,
          data,
        });
      } catch (e) {
        callback?.({
          success: false,
        });
      }
    },
  );
}

function* createInviteSaga(): Generator {
  yield takeLatest(
    ActionType.CREATE_INVITE,
    function* (action: ActionCallback<ICreateInvitePayload>) {
      const { payload, callback } = action;
      try {
        yield call(api.createInvite, payload);

        yield put(getInvites({ offset: 0, limit: 10 }));
        callback?.({
          success: true,
        });
      } catch (e) {
        callback?.({
          success: false,
        });
      }
    },
  );
}

function* declineInviteSaga(): Generator {
  yield takeLatest(
    ActionType.DECLINE_INVITE,
    function* (action: ActionCallback<IDeclineInviteParams>) {
      const { payload, callback } = action;
      try {
        yield call(api.declineInvite, payload);

        yield put(getInvites({ offset: 0, limit: 10 }));
        callback?.({
          success: true,
        });
      } catch (e) {
        callback?.({
          success: false,
        });
      }
    },
  );
}

export default [
  fork(getInvitesSaga),
  fork(createInviteSaga),
  fork(declineInviteSaga),
];
