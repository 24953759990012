import { useCallback, useEffect, useState } from "react";
import { DEFAULT_LIMIT } from "@constants";
import { IDataList } from "@store/model";
import { debounce } from "lodash";

const initialData = {
  items: [],
  offset: 0,
  limit: DEFAULT_LIMIT,
  total: 0,
};

export default function useLoadApiData(
  action,
  search?: string,
  additionalParams?: { [key: string]: string },
) {
  const [data, setData] = useState<IDataList<any>>(initialData);
  const [loaded, setLoaded] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);

  const handleLoadData = useCallback(
    debounce(async (search) => {
      const result = await action({
        limit: DEFAULT_LIMIT,
        offset: 0,
        ...(search && { search }),
        ...(additionalParams || {}),
      });
      setData(result);
      setLoaded(true);
      setLoading(false);
    }, 500),
    [additionalParams],
  );

  useEffect(() => {
    setLoading(true);
    setData(initialData);
    handleLoadData(search);
  }, [search]);

  const handleLoadMore = useCallback(async () => {
    if (loading) {
      return;
    }

    setLoading(true);
    const result = await action({
      limit: data.limit,
      offset: data.limit + data.offset,
      ...(search && { search }),
      ...(additionalParams || {}),
    });
    setData(result);
    setLoading(false);
  }, [additionalParams, loading]);

  return {
    data,
    hasNextPage: data.total > data.offset + data.limit,
    loaded,
    loading,
    handleLoadMore,
  };
}
