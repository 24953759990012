import {
  Dropdown,
  ListItemDecorator,
  Menu,
  MenuButton,
  MenuItem,
  Avatar,
  Typography,
  Stack,
  styled,
} from "@mui/joy";
import React, { useCallback } from "react";
import { useAppSelector } from "@store/hooks";
import {
  selectActiveWorkspace,
  selectWorkspaces,
} from "@store/workspace/selectors";
import { useDispatch } from "react-redux";
import { setActiveWorkspace } from "@store/workspace/actions";
import { IWorkspace } from "@store/workspace/model";

export const Workspaces = () => {
  const dispatch = useDispatch<any>();
  const workspaces = useAppSelector(selectWorkspaces);
  const activeWorkspace = useAppSelector(selectActiveWorkspace);

  const handleSelectWorkspace = useCallback((workspace: IWorkspace) => {
    dispatch(setActiveWorkspace(workspace));
    window.location.href = "/";
  }, []);

  return (
    <Dropdown>
      <MenuButton
        component={Stack}
        sx={{
          px: 6,
          py: 4,
          gap: 3,
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-start",
          border: "none",
          flex: 1,
          width: "100%",
        }}
        disabled={workspaces.total <= 1}
      >
        <Logo
          src={activeWorkspace.info.logo}
          alt={activeWorkspace.workspace.name}
        />
        <WorkspaceName level="h4">
          {activeWorkspace.workspace.name}
        </WorkspaceName>
      </MenuButton>

      <Menu
        variant="solid"
        invertedColors
        sx={{
          "--ListItemDecorator-size": "48px",
        }}
      >
        {workspaces.items.map((workspace) => (
          <MenuItem
            key={workspace.workspace.id}
            orientation="horizontal"
            onClick={() => handleSelectWorkspace(workspace)}
          >
            <ListItemDecorator>
              <Logo src={workspace.info.logo} alt={workspace.workspace.name} />
            </ListItemDecorator>
            {workspace.workspace.name}
          </MenuItem>
        ))}
      </Menu>
    </Dropdown>
  );
};

const Logo = styled(Avatar)`
  border-radius: ${({ theme }) => theme.radius.md};
`;

const WorkspaceName = styled(Typography)`
  ${({ theme }) => theme.breakpoints.down("lg")} {
    font-size: 16px;
  }
`;
