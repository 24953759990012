import { Pagination } from "@services/api/api.types";
import { Gender } from "@constants";
import { IMember } from "@store/common/model";

export enum ActionType {
  GET_DEPARTMENTS = "GET_DEPARTMENTS",
  GET_DEPARTMENT_INFO = "GET_DEPARTMENT_INFO",
  GET_DEPARTMENT_MEMBERS = "GET_DEPARTMENT_MEMBERS",
  CREATE_DEPARTMENT = "CREATE_DEPARTMENT",
  UPDATE_DEPARTMENT = "UPDATE_DEPARTMENT",
  DELETE_DEPARTMENT = "DELETE_DEPARTMENT",
  JOIN_DEPARTMENT_MEMBERS = "JOIN_DEPARTMENT_MEMBERS",
  LEAVE_DEPARTMENT_MEMBERS = "LEAVE_DEPARTMENT_MEMBERS",
  SET_DEPARTMENTS = "SET_DEPARTMENTS",
  SET_DEPARTMENT_INFO = "SET_DEPARTMENT_INFO",
  SET_DEPARTMENT_MEMBERS = "SET_DEPARTMENT_MEMBERS",
  RESET = "RESET_DEPARTMENT",
}

export interface IDepartmentsParams extends Pagination {
  search?: string;
}

export interface IDepartmentMembersParams extends Pagination {
  departmentId: string;
}

export interface IDepartmentInfoParams {
  departmentId: string;
}

export interface IDeleteDepartmentParams {
  departmentId: string;
}

export interface ICreateDepartmentPayload {
  name: string;
  description: string;
  logo: string;
}

export interface IUpdateDepartmentPayload
  extends Partial<ICreateDepartmentPayload> {
  departmentId: string;
}

export interface IJoinDepartmentMembersPayload {
  id: string;
  users: string[];
}

export interface IDepartmentMember extends IMember {}

export interface IDepartment {
  id: string;
  workspaceId: string;
  name: string;
  description: string;
  logo: string;
  createdAt: Date;
  updatedAt: Date;
  membersCount: number;
  members: IDepartmentMember[];
}

export interface IJoinDepartmentMember {
  userId: string;
  success: boolean;
}
