import React, { FC } from "react";
import { Breadcrumbs, Sheet, styled, Link, Typography } from "@mui/joy";
import Card from "@mui/joy/Card";
import { RedemptionsTable } from "@components/RedemptionsTable";

export const Reward: FC = () => {
  return (
    <>
      <Breadcrumbs aria-label="breadcrumbs">
        <Link color="neutral" href="/rewards">
          Rewards
        </Link>
        <Typography>Reward Analytic</Typography>
      </Breadcrumbs>
      <Card sx={{ bgcolor: "white", borderRadius: "xl" }}></Card>
      <RedemptionsTable />
    </>
  );
};
