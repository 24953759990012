import React, { FC, ReactNode, useEffect, useState } from "react";
import { Box, CircularProgress, styled } from "@mui/joy";
import { useSearchParams } from "react-router-dom";
import TagManager from "react-gtm-module";
import { useDispatch } from "react-redux";
import { signIn } from "@store/auth/actions";
import { getWorkspaces } from "@store/workspace/actions";
import { useAppSelector } from "@store/hooks";
import { getManager } from "@store/manager/actions";

type AuthProps = {
  children: ReactNode;
};

export const AuthWrapper: FC<AuthProps> = ({ children }) => {
  const dispatch = useDispatch<any>();
  const [loading, setLoading] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();
  const isLoggedIn = useAppSelector((state) => state.auth.loggedIn);
  const token = searchParams.get("token");
  const selectedWorkspace = searchParams.get("workspaceId");

  useEffect(() => {
    const fetchWorkspaces = () => {
      dispatch(
        getWorkspaces({ workspaceId: selectedWorkspace }, ({ success }) => {
          setLoading(false);
        }),
      );
    };

    const fetchManager = () => {
      dispatch(
        getManager(({ success, data }) => {
          if (success) {
            TagManager.dataLayer({
              dataLayer: {
                userId: data.id,
              },
            });
            fetchWorkspaces();
          } else {
            window.location.href = `${process.env.REACT_APP_ONBOARDING_URL}/signin`;
          }
        }),
      );
    };

    if (token) {
      searchParams.delete("token");
      searchParams.delete("workspaceId");
      setSearchParams(searchParams);
      dispatch(
        signIn({ token }, ({ success }) => {
          if (!success) {
            window.location.href = `${process.env.REACT_APP_ONBOARDING_URL}/signin`;
            return;
          }

          fetchManager();
        }),
      );
    } else if (isLoggedIn) {
      fetchManager();
    } else {
      window.location.href = `${process.env.REACT_APP_ONBOARDING_URL}/signin`;
    }
  }, []);

  if (loading) {
    return (
      <LoaderContainer>
        <CircularProgress />
      </LoaderContainer>
    );
  }

  return <Container>{children}</Container>;
};

const Container = styled(Box)`
  display: flex;
  min-height: 100vh;
`;

const LoaderContainer = styled(Container)`
  justify-content: center;
  align-items: center;
`;
