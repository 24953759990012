import React, { FC } from "react";
import TableHead from "@mui/material/TableHead";

type HeaderProps = {
  headCells: string[];
};

export const Header: FC<HeaderProps> = (props) => {
  const { headCells } = props;

  return (
    <TableHead
      sx={{
        "& th": {
          width: "180px",
        },
        "& th:last-child": {
          width: "46px",
        },
      }}
    >
      <tr>
        {headCells.map((headCell, index) => {
          return <th key={`head-cell-${index}`}>{headCell}</th>;
        })}
        <th />
      </tr>
    </TableHead>
  );
};
