import React, { FC, useCallback } from "react";
import Box from "@mui/joy/Box";
import Table from "@mui/joy/Table";
import Typography from "@mui/joy/Typography";
import Sheet from "@mui/joy/Sheet";
import IconButton from "@mui/joy/IconButton";
import { Avatar, Stack } from "@mui/joy";
import { RemoveCircleOutline } from "@mui/icons-material";
import { IDataList } from "@store/model";
import { DEFAULT_LIMIT, InviteStatus } from "@constants";
import { TableBody, TableContainer } from "@mui/material";
import { IInvite } from "@store/invite/model";
import { capitalize } from "lodash";
import { useConfirm } from "mui-joy-confirm";
import { useDispatch } from "react-redux";
import { declineInvite } from "@store/invite/actions";
import { Footer } from "@components/table/Footer";
import { Header } from "@components/table/Header";
import { Placeholder } from "@components/table/Placeholder";

const headCells = ["User", "Message", "Status"];

const headCellsSize = headCells.length + 1;

type InvitesTableProps = {
  onPaginate: (page: number, limit?: number) => void;
  currentPage: number;
  totalPages: number;
  invites: IDataList<IInvite>;
};

export const InvitesTable: FC<InvitesTableProps> = (props) => {
  const { onPaginate, invites, currentPage, totalPages } = props;
  const confirm = useConfirm();
  const dispatch = useDispatch<any>();

  const handleDecline = useCallback((invite: IInvite) => {
    confirm({
      title: "Decline this invite?",
      description: "This action is permanent!",
      confirmationText: "Decline",
      cancellationText: "Cancel",
      confirmationButtonProps: {
        color: "danger",
      },
      cancellationButtonProps: {
        variant: "outlined",
        color: "neutral",
      },
    })
      .then(() => {
        dispatch(declineInvite({ inviteId: invite.id }));
      })
      .catch(() => {});
  }, []);

  return (
    <Sheet
      variant="outlined"
      sx={{
        width: "100%",
        boxShadow: "sm",
        borderRadius: "xl",
        overflow: "hidden",
      }}
    >
      <TableContainer>
        <Table>
          <Header headCells={headCells} />
          <TableBody>
            {invites.items?.map((invite: IInvite) => {
              return (
                <tr key={invite.id}>
                  <td>
                    <Stack direction="row" gap={3} alignItems="center">
                      <Avatar
                        alt={`${invite.user.firstName} ${invite.user.lastName}`}
                        src={invite.user.avatar}
                      />
                      <Box>
                        <Typography fontWeight="500" fontSize="sm">
                          {invite.user.firstName} {invite.user.lastName}
                        </Typography>
                        <Typography fontWeight="500" fontSize="sm">
                          {invite.user.phoneNumber}
                        </Typography>
                      </Box>
                    </Stack>
                  </td>
                  <td>{invite.message}</td>
                  <td>{capitalize(invite.status)}</td>
                  <td>
                    <Stack direction="row" gap={4} justifyContent="flex-end">
                      {invite.status === InviteStatus.CREATED ? (
                        <IconButton
                          size="lg"
                          sx={{ boxShadow: "sm" }}
                          color="danger"
                          onClick={() => handleDecline(invite)}
                        >
                          <RemoveCircleOutline />
                        </IconButton>
                      ) : null}
                    </Stack>
                  </td>
                </tr>
              );
            })}
            {!invites?.total ? (
              <Placeholder headCellsSize={headCellsSize} />
            ) : null}
          </TableBody>
          <Footer
            headCellsSize={headCellsSize}
            currentPage={currentPage}
            totalPages={totalPages}
            onPaginate={onPaginate}
          />
        </Table>
      </TableContainer>
    </Sheet>
  );
};
