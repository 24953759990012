import { GridViewRounded, ListAltOutlined } from "@mui/icons-material";

export enum LocalStorageKeys {
  ACCESS_TOKEN = "accessToken",
  REFRESH_TOKEN = "refreshToken",
}

export enum Language {
  EN = "en",
}

export const DEFAULT_LANGUAGE = Language.EN;

export const DEFAULT_LIMIT = 10;

export enum Gender {
  MALE = "male",
  FEMALE = "female",
  OTHER = "other",
}

export const genders = [
  {
    value: Gender.MALE,
    label: "Male",
  },
  {
    value: Gender.FEMALE,
    label: "Female",
  },
  {
    value: Gender.OTHER,
    label: "Other",
  },
];

export enum WorkspaceType {
  DEFAULT = "default",
  HOTEL = "hotel",
}

export const DEBOUNCE_TIMEOUT = 1000;

export enum ViewMode {
  GRID = "grid",
  LIST = "list",
}

export const ViewModeOptions = [
  {
    value: ViewMode.GRID,
    label: "Grid",
    icon: GridViewRounded,
  },
  {
    value: ViewMode.LIST,
    label: "List",
    icon: ListAltOutlined,
  },
];

export enum ChallengeType {
  BREATH = "breath",
  STEPS = "steps",
  SQUATS = "squats",
}

export enum ChallengeState {
  PENDING = "pending",
  FINISHED = "finished",
  IN_PROGRESS = "in-progress",
}

export enum UploadType {
  LOGO = "logo",
}

export const mapExtensionToMimeType = {
  jpg: "image/jpeg",
  jpeg: "image/jpeg",
  png: "image/png",
  mp4: "video/mp4",
  mov: "video/quicktime",
  webm: "video/webm",
  avi: "video/avi",
  flv: "video/x-flv",
  wmv: "video/x-ms-wmv",
  mpg: "video/mpeg",
};

export enum LeaderboardType {
  USER = "user",
  TEAM = "team",
}

export enum MembersType {
  TEAM = "team",
  DEPARTMENT = "department",
}

export enum InviteStatus {
  CREATED = "created",
  ACCEPTED = "accepted",
  DECLINED = "declined",
  EXPIRED = "expired",
}
