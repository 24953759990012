import React, { FC } from "react";
import { Button, Grid, Sheet, Stack, styled, Typography } from "@mui/joy";
import { AddCircleOutlineOutlined } from "@mui/icons-material";
import { ChallengeCard } from "@components/ChallengeCard";
import { ChallengeDrawer } from "@drawers/ChallengeDrawer";
import useLoadData from "@/hooks/useLoadData";
import { IChallenge } from "@store/challenge/model";
import { getChallenges } from "@store/challenge/actions";
import { selectChallenges, selectPagination } from "@store/challenge/selectors";
import { useAppSelector } from "@store/hooks";
import { Pagination } from "@components/Pagination";
import { Loader } from "@components/Loader";
import useDrawer from "@/hooks/useDrawer";
import { EmptyState } from "@components/EmptyState";

export const Challenges: FC = () => {
  const { open, editing, handleOpen, handleEdit, handleClose } =
    useDrawer<IChallenge>();

  const challenges = useAppSelector(selectChallenges);
  const { totalPages, page } = useAppSelector(selectPagination);
  const { loaded, loading, handlePaginate } =
    useLoadData<IChallenge>(getChallenges);

  return (
    <>
      <ChallengeDrawer open={open} onClose={handleClose} editing={editing} />
      <Stack
        gap={4}
        direction={{ xs: "column", md: "row" }}
        alignItems={{ xs: "stretch", md: "center" }}
      >
        <Typography level="h2" flex={1}>
          Challenges
        </Typography>
        <Button
          size="md"
          startDecorator={<AddCircleOutlineOutlined />}
          color="primary"
          sx={{ boxShadow: "sm" }}
          onClick={handleOpen}
        >
          Add Challenge
        </Button>
      </Stack>

      {loaded && challenges?.items?.length === 0 ? (
        <EmptyState description="No challenges has been added yet." />
      ) : null}

      {loading ? (
        <Loader />
      ) : (
        <Grid container spacing={6}>
          {challenges?.items?.map((challenge) => (
            <Grid xs={12} xl={3} lg={4} md={6} key={challenge.challengeId}>
              <ChallengeCard challenge={challenge} onEdit={handleEdit} />
            </Grid>
          ))}
        </Grid>
      )}
      <Stack alignItems="center" mt="auto">
        <Pagination
          count={totalPages}
          page={page}
          onChange={(event, page) => handlePaginate(page)}
          color="primary"
          disabled={loading}
        />
      </Stack>
    </>
  );
};
