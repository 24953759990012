import { call, fork, put, select, takeLatest } from "redux-saga/effects";
import { ActionCallback } from "@store/model";
import {
  setDepartments,
  setDepartmentInfo,
  getDepartments,
  getDepartmentMembers,
  setDepartmentMembers,
} from "@store/department/actions";
import {
  ActionType,
  IDepartmentsParams,
  IDepartmentInfoParams,
  IDepartmentMembersParams,
  ICreateDepartmentPayload,
  IDeleteDepartmentParams,
  IUpdateDepartmentPayload,
  IJoinDepartmentMembersPayload,
} from "@store/department/model";
import * as api from "@services/api/department";
import { selectDepartmentMembersPagination } from "@store/department/selectors";

function* getDepartmentsSaga(): Generator {
  yield takeLatest(
    ActionType.GET_DEPARTMENTS,
    function* (action: ActionCallback<IDepartmentsParams>) {
      const { payload, callback } = action;
      try {
        const data = yield call(api.getDepartments, payload);

        yield put(setDepartments(data));

        callback?.({
          success: true,
          data,
        });
      } catch (e) {
        callback?.({
          success: false,
        });
      }
    },
  );
}

function* getDepartmentInfoSaga(): Generator {
  yield takeLatest(
    ActionType.GET_DEPARTMENT_INFO,
    function* (action: ActionCallback<IDepartmentInfoParams>) {
      const { payload, callback } = action;
      try {
        const data = yield call(api.getDepartmentInfo, payload);

        yield put(setDepartmentInfo(data));

        callback?.({ success: true, data });
      } catch (e) {
        callback?.({ success: false });
      }
    },
  );
}

function* getDepartmentMembersSaga(): Generator {
  yield takeLatest(
    ActionType.GET_DEPARTMENT_MEMBERS,
    function* (action: ActionCallback<IDepartmentMembersParams>) {
      const { payload, callback } = action;
      try {
        const data = yield call(api.getDepartmentUsers, payload);

        yield put(setDepartmentMembers(data));

        callback?.({
          success: true,
          data,
        });
      } catch (e) {
        callback?.({
          success: false,
        });
      }
    },
  );
}

function* createDepartmentSaga(): Generator {
  yield takeLatest(
    ActionType.CREATE_DEPARTMENT,
    function* (action: ActionCallback<ICreateDepartmentPayload>) {
      const { payload, callback } = action;
      try {
        yield call(api.createDepartment, payload);

        yield put(getDepartments({ offset: 0, limit: 10 }));
        callback?.({
          success: true,
        });
      } catch (e) {
        callback?.({
          success: false,
        });
      }
    },
  );
}

function* updateDepartmentSaga(): Generator {
  yield takeLatest(
    ActionType.UPDATE_DEPARTMENT,
    function* (action: ActionCallback<IUpdateDepartmentPayload>) {
      const { payload, callback } = action;
      try {
        yield call(api.updateDepartment, payload);

        yield put(getDepartments({ offset: 0, limit: 10 }));
        callback?.({
          success: true,
        });
      } catch (e) {
        callback?.({
          success: false,
        });
      }
    },
  );
}

function* deleteDepartmentSaga(): Generator {
  yield takeLatest(
    ActionType.DELETE_DEPARTMENT,
    function* (action: ActionCallback<IDeleteDepartmentParams>) {
      const { payload, callback } = action;
      try {
        yield call(api.deleteDepartment, payload);

        yield put(getDepartments({ offset: 0, limit: 10 }));
        callback?.({
          success: true,
        });
      } catch (e) {
        callback?.({
          success: false,
        });
      }
    },
  );
}

function* joinDepartmentMembersSaga(): Generator {
  yield takeLatest(
    ActionType.JOIN_DEPARTMENT_MEMBERS,
    function* (action: ActionCallback<IJoinDepartmentMembersPayload>) {
      const { payload, callback } = action;
      const pagination = yield select(selectDepartmentMembersPagination);
      try {
        yield call(api.joinDepartmentUsers, payload);

        yield put(
          getDepartmentMembers(
            {
              departmentId: payload.id,
              ...pagination,
            },
            callback,
          ),
        );
      } catch (e) {
        callback?.({
          success: false,
        });
      }
    },
  );
}

function* leaveDepartmentMembersSaga(): Generator {
  yield takeLatest(
    ActionType.LEAVE_DEPARTMENT_MEMBERS,
    function* (action: ActionCallback<IJoinDepartmentMembersPayload>) {
      const { payload, callback } = action;
      const pagination = yield select(selectDepartmentMembersPagination);
      try {
        yield call(api.leaveDepartmentUsers, payload);

        yield put(
          getDepartmentMembers(
            {
              departmentId: payload.id,
              ...pagination,
            },
            callback,
          ),
        );
      } catch (e) {
        callback?.({
          success: false,
        });
      }
    },
  );
}

export default [
  fork(getDepartmentsSaga),
  fork(getDepartmentInfoSaga),
  fork(getDepartmentMembersSaga),
  fork(createDepartmentSaga),
  fork(updateDepartmentSaga),
  fork(deleteDepartmentSaga),
  fork(joinDepartmentMembersSaga),
  fork(leaveDepartmentMembersSaga),
];
