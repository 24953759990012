import {
  ActionCallback,
  ActionTypeOnly,
  FetchCallback,
  IDataList,
  Nullable,
} from "@store/model";
import {
  ActionType,
  IDropUserParams,
  IJoinUserTeamsPayload,
  ISetUserDepartmentPayload,
  IUser,
  IUserParams,
  IUsersParams,
  IUserTeam,
  IUserTeamsParams,
} from "@store/user/model";

export const getUsers = (
  payload: IUsersParams,
  callback?: FetchCallback,
): ActionCallback<IUsersParams> => ({
  type: ActionType.GET_USERS,
  payload,
  callback,
});

export const getUser = (
  payload: IUserParams,
  callback?: FetchCallback,
): ActionCallback<IUserParams> => ({
  type: ActionType.GET_USER,
  payload,
  callback,
});

export const getUserTeams = (
  payload: IUserTeamsParams,
  callback?: FetchCallback,
): ActionCallback<IUserTeamsParams> => ({
  type: ActionType.GET_USER_TEAMS,
  payload,
  callback,
});

export const setUserDepartment = (
  payload: ISetUserDepartmentPayload,
  callback?: FetchCallback,
): ActionCallback<ISetUserDepartmentPayload> => ({
  type: ActionType.SET_USER_DEPARTMENT,
  payload,
  callback,
});

export const joinUserTeams = (
  payload: IJoinUserTeamsPayload,
  callback?: FetchCallback,
): ActionCallback<IJoinUserTeamsPayload> => ({
  type: ActionType.JOIN_USER_TEAMS,
  payload,
  callback,
});

export const leaveUserTeams = (
  payload: IJoinUserTeamsPayload,
  callback?: FetchCallback,
): ActionCallback<IJoinUserTeamsPayload> => ({
  type: ActionType.LEAVE_USER_TEAMS,
  payload,
  callback,
});

export const dropUser = (
  payload: IDropUserParams,
  callback?: FetchCallback,
): ActionCallback<IDropUserParams> => ({
  type: ActionType.DROP_USER,
  payload,
  callback,
});

export const setUsers = (
  payload: Nullable<IDataList<IUser>>,
  callback?: FetchCallback,
): ActionCallback<IDataList<IUser>> => ({
  type: ActionType.SET_USERS,
  payload,
  callback,
});

export const setUser = (
  payload: IUser,
  callback?: FetchCallback,
): ActionCallback<IUser> => ({
  type: ActionType.SET_USER,
  payload,
  callback,
});

export const setUserTeams = (
  payload: Nullable<IDataList<IUserTeam>>,
  callback?: FetchCallback,
): ActionCallback<IDataList<IUserTeam>> => ({
  type: ActionType.SET_USER_TEAMS,
  payload,
  callback,
});

export const resetUser = (): ActionTypeOnly => ({
  type: ActionType.RESET,
});
