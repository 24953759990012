import { Button, styled, Typography } from "@mui/joy";
import React, { FC, useCallback, useEffect, useState } from "react";
import { useAppSelector } from "@store/hooks";
import { selectManager } from "@store/manager/selectors";
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { updateManager } from "@store/manager/actions";
import { useDispatch } from "react-redux";
import { TextInput } from "@components/form/TextInput";

const validationSchema = yup
  .object({
    password: yup
      .string()
      .required("Password is required")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character",
      ),
    newPassword: yup
      .string()
      .required("Password is required")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character",
      ),
  })
  .required();

type Inputs = {
  password: string;
  newPassword: string;
};

export const ManagerPasswordForm: FC = () => {
  const [loading, setLoading] = useState(false);
  const manager = useAppSelector(selectManager);
  const dispatch = useDispatch<any>();

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>({
    // @ts-ignore
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    reset({
      password: "",
      newPassword: "",
    });
  }, [manager]);

  const onSubmit: SubmitHandler<Inputs> = useCallback(async (data) => {
    setLoading(true);

    dispatch(
      updateManager(data, () => {
        setLoading(false);
      }),
    );
  }, []);

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Typography level="h3">Change Password</Typography>
      <TextInput
        {...register("password")}
        label="Current Password"
        type="password"
        error={!!errors.password}
        errorMessage={errors.password?.message}
        required
      />
      <TextInput
        {...register("newPassword")}
        label="New Password"
        type="password"
        error={!!errors.newPassword}
        errorMessage={errors.newPassword?.message}
        required
      />
      <Button
        color="primary"
        sx={{ boxShadow: "sm" }}
        loading={loading}
        onClick={handleSubmit(onSubmit)}
      >
        Change Password
      </Button>
    </Form>
  );
};

const Form = styled("form")`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(6)};
`;
