import { Pagination } from "@services/api/api.types";
import { IMember } from "@store/common/model";

export enum ActionType {
  GET_TEAMS = "GET_TEAMS",
  GET_TEAM_INFO = "GET_TEAM_INFO",
  GET_TEAM_MEMBERS = "GET_TEAM_MEMBERS",
  CREATE_TEAM = "CREATE_TEAM",
  UPDATE_TEAM = "UPDATE_TEAM",
  DELETE_TEAM = "DELETE_TEAM",
  JOIN_TEAM_MEMBERS = "JOIN_TEAM_MEMBERS",
  LEAVE_TEAM_MEMBERS = "LEAVE_TEAM_MEMBERS",
  SET_TEAMS = "SET_TEAMS",
  SET_TEAM_INFO = "SET_TEAM_INFO",
  SET_TEAM_MEMBERS = "SET_TEAM_MEMBERS",
  RESET = "RESET_TEAM",
}

export interface ITeamsParams extends Pagination {
  search?: string;
}

export interface ITeamInfoParams {
  teamId: string;
}

export interface ITeamMembersParams extends Pagination {
  teamId: string;
}

export interface IDeleteTeamParams {
  teamId: string;
}

export interface ICreateTeamPayload {
  name: string;
  description: string;
  logo: string;
}

export interface IUpdateTeamPayload extends Partial<ICreateTeamPayload> {
  teamId: string;
}

export interface IJoinTeamMembersPayload {
  id: string;
  users: string[];
}

export interface ITeamMember extends IMember {}

export interface ITeam {
  id: string;
  workspaceId: string;
  name: string;
  description: string;
  logo: string;
  createdAt: Date;
  updatedAt: Date;
  membersCount: number;
  members: ITeamMember[];
}

export interface IJoinTeamMember {
  userId: string;
  success: boolean;
}
