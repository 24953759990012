import { PayloadAction } from "@reduxjs/toolkit";
import createReducer from "@store/createReducer";
import { IDataList } from "@store/model";
import { ActionType, IUser, IUserTeam } from "@store/user/model";
import { DEFAULT_LIMIT } from "@constants";

export interface UserState {
  users: IDataList<IUser>;
  userTeams: IDataList<IUserTeam>;
  user: IUser;
}

const defaultState: UserState = {
  users: {
    total: 0,
    offset: 0,
    limit: DEFAULT_LIMIT,
    items: [],
  },
  userTeams: {
    total: 0,
    offset: 0,
    limit: DEFAULT_LIMIT,
    items: [],
  },
  user: null,
};

export const userReducer = createReducer<UserState>(defaultState, {
  [ActionType.SET_USERS](
    state: UserState,
    action: PayloadAction<IDataList<IUser>>,
  ) {
    return {
      ...state,
      users: action.payload,
    };
  },
  [ActionType.SET_USER](state: UserState, action: PayloadAction<IUser>) {
    return {
      ...state,
      user: action.payload,
    };
  },
  [ActionType.SET_USER_TEAMS](
    state: UserState,
    action: PayloadAction<IDataList<IUserTeam>>,
  ) {
    return {
      ...state,
      userTeams: action.payload,
    };
  },
  [ActionType.RESET]() {
    return defaultState;
  },
});
