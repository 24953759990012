import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { DataList } from "@services/api/api.types";
import { DEFAULT_LIMIT } from "@constants";

export default function useLoadData<T>(
  action,
  search?: string,
  additionalParams?: { [key: string]: string },
) {
  const dispatch = useDispatch();
  const [loaded, setLoaded] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);

  const handleLoadData = useCallback(
    (search) => {
      dispatch(
        action(
          {
            limit: DEFAULT_LIMIT,
            offset: 0,
            ...(search && { search }),
            ...(additionalParams || {}),
          },
          () => {
            setLoaded(true);
            setLoading(false);
          },
        ),
      );
    },
    [additionalParams],
  );

  useEffect(() => {
    setLoading(true);
    handleLoadData(search);
  }, [search]);

  const handlePaginate = useCallback(
    (page: number, limit = DEFAULT_LIMIT) => {
      if (loading) {
        return;
      }

      setLoading(true);
      dispatch(
        action(
          {
            limit,
            offset: limit * (page - 1),
            ...(search && { search }),
            ...(additionalParams || {}),
          },
          ({ data }: { data: DataList<T> }) => {
            setLoading(false);
          },
        ),
      );
    },
    [additionalParams, loading],
  );

  return {
    loaded,
    loading,
    handlePaginate,
  };
}
