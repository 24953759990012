import { PayloadAction } from "@reduxjs/toolkit";
import createReducer from "@store/createReducer";
import { IDataList } from "@store/model";
import { ActionType, IInvite } from "@store/invite/model";
import { DEFAULT_LIMIT } from "@constants";

export interface InviteState {
  invites: IDataList<IInvite>;
}

const defaultState: InviteState = {
  invites: {
    total: 0,
    offset: 0,
    limit: DEFAULT_LIMIT,
    items: [],
  },
};

export const inviteReducer = createReducer<InviteState>(defaultState, {
  [ActionType.SET_INVITES](
    state: InviteState,
    action: PayloadAction<IDataList<IInvite>>,
  ) {
    return {
      ...state,
      invites: action.payload,
    };
  },
  [ActionType.RESET]() {
    return defaultState;
  },
});
