import Config from "@config";
import axios, {
  AxiosInstance,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from "axios";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import { setAuth, signOut } from "@store/auth/actions";
import { store } from "@store/store";
import { ApiConfig } from "@services/api/api.types";
import { ApiTokensResponse } from "@services/api/auth/auth.types";
import { LocalStorageKeys, DEFAULT_LANGUAGE } from "@constants";
import { enqueueSnackbar } from "notistack";

export const DEFAULT_API_CONFIG: ApiConfig = {
  baseUrl: Config.API_URL,
  timeout: 10000,
};

export const axiosInstance: AxiosInstance = axios.create({
  baseURL: DEFAULT_API_CONFIG.baseUrl,
});

axiosInstance.interceptors.request.use(
  async (
    config: InternalAxiosRequestConfig,
  ): Promise<InternalAxiosRequestConfig> => {
    const activeWorkspace = store.getState().workspace.activeWorkspace;
    const accessToken = localStorage.getItem(LocalStorageKeys.ACCESS_TOKEN);

    config.headers["Content-Language"] = DEFAULT_LANGUAGE;

    if (accessToken) {
      config.headers.authorization = `Bearer ${accessToken}`;
    }

    if (activeWorkspace) {
      config.headers["x-workspace-id"] = activeWorkspace.workspace.id;
    }

    return config;
  },
);

axiosInstance.interceptors.response.use(null, (error) => {
  const { response } = error;
  if (response?.data?.message) {
    enqueueSnackbar(response.data.message, {
      variant: "error",
      anchorOrigin: { horizontal: "right", vertical: "top" },
    });
  }
});

const refreshAuthLogic = async (failedRequest: any) => {
  try {
    const token = localStorage.getItem(LocalStorageKeys.REFRESH_TOKEN);

    const response: AxiosResponse<ApiTokensResponse> = await axios.post(
      `${DEFAULT_API_CONFIG.baseUrl}/auth/refresh-tokens`,
      { token },
    );

    localStorage.setItem(
      LocalStorageKeys.ACCESS_TOKEN,
      response.data.accessToken,
    );
    localStorage.setItem(
      LocalStorageKeys.REFRESH_TOKEN,
      response.data.refreshToken,
    );
    store.dispatch<any>(setAuth(response.data));
    failedRequest.response.config.headers.authorization = `Bearer ${response.data.accessToken}`;
  } catch {
    store.dispatch<any>(signOut());
    localStorage.removeItem(LocalStorageKeys.ACCESS_TOKEN);
    localStorage.removeItem(LocalStorageKeys.REFRESH_TOKEN);
    window.location.href = `${process.env.REACT_APP_ONBOARDING_URL}/signin`;
  }
};

createAuthRefreshInterceptor(axiosInstance, refreshAuthLogic);
