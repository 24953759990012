import React, { FC } from "react";
import { DEFAULT_LIMIT } from "@constants";
import { Loader } from "@components/Loader";

type PlaceholderProps = {
  headCellsSize: number;
};

export const Placeholder: FC<PlaceholderProps> = (props) => {
  const { headCellsSize } = props;

  return (
    <tr
      style={
        {
          height: `calc(${DEFAULT_LIMIT} * 40px)`,
          "--TableRow-hoverBackground": "transparent",
        } as React.CSSProperties
      }
    >
      <td colSpan={headCellsSize} aria-hidden>
        <Loader />
      </td>
    </tr>
  );
};
