import { ActionType, ISignInPayload, ITokens } from "@store/auth/model";
import {
  ActionCallback,
  ActionCallbackOnly,
  ActionTypeOnly,
  FetchCallback,
  Nullable,
} from "@store/model";

export const signIn = (
  payload: ISignInPayload,
  callback?: FetchCallback,
): ActionCallback<ISignInPayload> => ({
  type: ActionType.SIGN_IN,
  payload,
  callback,
});

export const setAuth = (
  payload: Nullable<ITokens>,
  callback?: FetchCallback,
): ActionCallback<Nullable<ITokens>> => ({
  type: ActionType.SET_AUTH,
  payload,
  callback,
});

export const setLoggedIn = (
  payload: boolean,
  callback?: FetchCallback,
): ActionCallback<boolean> => ({
  type: ActionType.SET_LOGGED_IN,
  payload,
  callback,
});

export const signOut = (callback?: FetchCallback): ActionCallbackOnly => ({
  type: ActionType.SIGN_OUT,
  callback,
});

export const resetAuth = (): ActionTypeOnly => ({
  type: ActionType.RESET,
});
