import React, { ChangeEvent } from "react";
import { Input, InputProps } from "@mui/joy";
import { DEBOUNCE_TIMEOUT } from "@constants";

type DebounceProps = {
  handleDebounce: (value: string) => void;
};

export const DebounceInput = (props: InputProps & DebounceProps) => {
  const { handleDebounce, ...rest } = props;

  const timerRef = React.useRef<number>();

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    timerRef.current = window.setTimeout(() => {
      handleDebounce(event.target.value);
    }, DEBOUNCE_TIMEOUT);
  };

  return <Input {...rest} onChange={handleChange} />;
};
