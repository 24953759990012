import { PayloadAction } from "@reduxjs/toolkit";
import createReducer from "@store/createReducer";
import { IDataList } from "@store/model";
import { ActionType, ITeam, ITeamMember } from "@store/team/model";
import { DEFAULT_LIMIT } from "@constants";

export interface TeamState {
  teams: IDataList<ITeam>;
  teamMembers: IDataList<ITeamMember>;
  teamInfo: ITeam | null;
}

const defaultState: TeamState = {
  teams: {
    total: 0,
    offset: 0,
    limit: DEFAULT_LIMIT,
    items: [],
  },
  teamMembers: {
    total: 0,
    offset: 0,
    limit: DEFAULT_LIMIT,
    items: [],
  },
  teamInfo: null,
};

export const teamReducer = createReducer<TeamState>(defaultState, {
  [ActionType.SET_TEAMS](
    state: TeamState,
    action: PayloadAction<IDataList<ITeam>>,
  ) {
    return {
      ...state,
      teams: action.payload,
    };
  },
  [ActionType.SET_TEAM_MEMBERS](
    state: TeamState,
    action: PayloadAction<IDataList<ITeamMember>>,
  ) {
    return {
      ...state,
      teamMembers: action.payload,
    };
  },
  [ActionType.SET_TEAM_INFO](state: TeamState, action: PayloadAction<ITeam>) {
    return {
      ...state,
      teamInfo: action.payload,
    };
  },
  [ActionType.RESET]() {
    return defaultState;
  },
});
