import { useCallback, useState } from "react";

export default function useDrawer<T>() {
  const [open, setOpen] = useState<boolean>(false);
  const [editing, setEditing] = useState<T | null>(null);

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
    setEditing(undefined);
  }, []);

  const handleEdit = useCallback((data: T) => {
    setEditing(data);
    handleOpen();
  }, []);

  return {
    open,
    editing,
    handleOpen,
    handleEdit,
    handleClose,
  };
}
