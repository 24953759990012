import Card from "@mui/joy/Card";
import { Box, Button, Grid, Stack, styled, Switch, Typography } from "@mui/joy";
import {
  BubbleChartRounded,
  ModeCommentRounded,
  PollRounded,
  RecommendRounded,
  RssFeedRounded,
  SupervisedUserCircleRounded,
} from "@mui/icons-material";
import CardContent from "@mui/joy/CardContent";
import React, {
  FC,
  ReactNode,
  useCallback,
  useMemo,
  useRef,
  useState,
} from "react";
import { useAppSelector } from "@store/hooks";
import { selectActiveWorkspace } from "@store/workspace/selectors";
import { UploadType } from "@constants";
import { UploadInput } from "@components/form/UploadInput";
import {
  Controller,
  FieldError,
  SubmitHandler,
  useForm,
} from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { updateWorkspace } from "@store/workspace/actions";
import { useDispatch } from "react-redux";
import { TextInput } from "@components/form/TextInput";
import { TextareaInput } from "@components/form/TextareaInput";
import { ITimezone, timezones } from "@services/utils/timezones";
import { AutocompleteInput } from "@components/form/AutocompleteInput";

type SettingProps = {
  icon: ReactNode;
  title: string;
  description: string;
};

const Setting: FC<SettingProps> = (props) => {
  const [checked, setChecked] = useState(false);

  return (
    <SettingContainer gap={4} direction="row" py={4}>
      <IconContainer>{props.icon}</IconContainer>
      <Box textAlign="left">
        <Typography fontSize="medium" fontWeight="600" mb={1}>
          {props.title}
        </Typography>
        <Typography fontSize="small">{props.description}</Typography>
      </Box>
      <Switch
        sx={{ ml: "auto" }}
        size="lg"
        color="primary"
        variant="solid"
        checked={checked}
        onChange={(event) => setChecked(event.target.checked)}
      />
    </SettingContainer>
  );
};

const validationSchema = yup
  .object({
    name: yup.string().required("Name is required"),
    email: yup.string().nullable(),
    description: yup.string().nullable(),
    timezone: yup
      .object({ value: yup.string().required("Timezone is required") })
      .required("Timezone is required"),
  })
  .required();

type Inputs = {
  name: string;
  email?: string;
  description?: string;
  timezone: ITimezone;
};

export const OrganizationProfile: FC = () => {
  const [loading, setLoading] = useState(false);
  const activeWorkspace = useAppSelector(selectActiveWorkspace);
  const uploadInputRef = useRef(null);
  const dispatch = useDispatch<any>();
  const timezone = useMemo(() => {
    return timezones.find((tz) => tz.value === activeWorkspace.info.timezone);
  }, [activeWorkspace]);

  const {
    register,
    reset,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>({
    defaultValues: {
      name: activeWorkspace.workspace.name,
      email: activeWorkspace.info.email,
      description: activeWorkspace.info.description,
      timezone,
    },
    // @ts-ignore
    resolver: yupResolver(validationSchema),
  });

  const onSubmit: SubmitHandler<Inputs> = useCallback(async (data) => {
    setLoading(true);

    const logo = await uploadInputRef.current.handleUpload();
    const workspaceData = {
      ...data,
      timezone: data.timezone.value,
      logo,
    };

    dispatch(
      updateWorkspace(workspaceData, () => {
        setLoading(false);
      }),
    );
  }, []);

  return (
    <Card sx={{ bgcolor: "white", p: 0, borderRadius: "xl" }}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <UploadInput
          type={UploadType.LOGO}
          image={activeWorkspace.info.logo}
          ref={uploadInputRef}
        />
        <CardContent sx={{ p: 6 }}>
          <Grid container spacing={6}>
            <Grid lg={6} xs={12}>
              <TextInput
                {...register("name")}
                label="Name"
                placeholder="Select"
                error={!!errors.name}
                errorMessage={errors.name?.message}
                required
              />
            </Grid>
            <Grid lg={6} xs={12}>
              <TextInput
                {...register("email")}
                label="Email"
                error={!!errors.email}
                errorMessage={errors.email?.message}
              />
            </Grid>

            <Grid lg={6} xs={12}>
              <Controller
                name="timezone"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <AutocompleteInput
                    label="Timezone"
                    options={timezones}
                    value={value}
                    onChange={onChange}
                    error={errors.timezone as FieldError}
                    required
                  />
                )}
              />
            </Grid>

            <Grid lg={6} xs={12}>
              <TextareaInput
                {...register("description")}
                label="Description"
                placeholder="Description"
                minRows={4}
              />
            </Grid>

            <Grid xs={12} textAlign="right">
              <Button
                color="primary"
                sx={{ boxShadow: "sm" }}
                loading={loading}
                onClick={handleSubmit(onSubmit)}
              >
                Save Changes
              </Button>
            </Grid>

            {false && (
              <Grid xs={12}>
                <Setting
                  icon={
                    <SupervisedUserCircleRounded
                      color="primary"
                      fontSize="medium"
                    />
                  }
                  title="Allow teams"
                  description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
                />
                <Setting
                  icon={
                    <ModeCommentRounded color="primary" fontSize="medium" />
                  }
                  title="Allow comments"
                  description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
                />
                <Setting
                  icon={<RecommendRounded color="primary" fontSize="medium" />}
                  title="Allow recognitions"
                  description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
                />
                <Setting
                  icon={<PollRounded color="primary" fontSize="medium" />}
                  title="Allow polls"
                  description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
                />
                <Setting
                  icon={
                    <BubbleChartRounded color="primary" fontSize="medium" />
                  }
                  title="Allow teams conversations"
                  description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
                />
                <Setting
                  icon={<RssFeedRounded color="primary" fontSize="medium" />}
                  title="Allow challenges conversations"
                  description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
                />
              </Grid>
            )}
          </Grid>
        </CardContent>
      </Form>
    </Card>
  );
};

const IconContainer = styled(Box)`
  width: 48px;
  height: 48px;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  display: flex;
  background-color: ${({ theme }) => theme.palette.neutral[100]};
`;

const SettingContainer = styled(Stack)`
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: ${({ theme }) => theme.palette.neutral[200]};
`;

const Form = styled("form")`
  display: flex;
  flex-direction: column;
  flex: 1;
`;
