import React, { FC, ReactNode } from "react";
import {
  FormControl,
  FormHelperText,
  FormLabel,
  Stack,
  styled,
} from "@mui/joy";
import { MuiTelInput, MuiTelInputProps } from "mui-tel-input";
import { FieldError } from "react-hook-form";

type PhoneInputProps = {
  label?: string;
  value: string;
  error?: FieldError;
  onChange?: (value: string) => void;
  endDecorator?: ReactNode;
  readOnly?: boolean;
};

export const PhoneInput: FC<PhoneInputProps> = (props) => {
  const { label, value, error, onChange, endDecorator, readOnly } = props;

  return (
    <FormControl error={!!error}>
      {label ? <FormLabel>{label}</FormLabel> : null}
      <Stack direction="row" gap={4} alignItems="center">
        <Container
          defaultCountry="US"
          value={value}
          onChange={onChange}
          placeholder="Enter Phone Number"
          error={!!error}
          disabled={readOnly}
        />
        {endDecorator}
      </Stack>
      {error ? <FormHelperText>{error.message}</FormHelperText> : null}
    </FormControl>
  );
};

const Container = styled(MuiTelInput)<MuiTelInputProps & { error: boolean }>`
  background-color: ${({ theme }) => theme.palette.neutral["100"]};
  border-radius: ${({ theme }) => theme.radius.sm};
  height: 44px;
  flex: 1;

  ${({ theme, error }) =>
    error
      ? `
        background: ${theme.palette.danger["100"]};
        color: ${theme.palette.danger["700"]};
       `
      : null}

  & fieldset {
    border: none;
    border-radius: ${({ theme }) => theme.radius.sm};
  }

  & .Mui-focused {
    outline-style: solid;
    outline-color: ${({ theme, error }) =>
      error ? theme.palette.danger["500"] : theme.palette.primary["700"]};
  }

  & .MuiInputBase-input {
    padding-top: 10px;
    padding-bottom: 10px;
  }
`;
