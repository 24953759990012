import {
  ActionCallback,
  ActionTypeOnly,
  FetchCallback,
  IDataList,
  Nullable,
} from "@store/model";
import {
  ActionType,
  IInvite,
  IInvitesParams,
  ICreateInvitePayload,
  IDeclineInviteParams,
} from "@store/invite/model";

export const getInvites = (
  payload: IInvitesParams,
  callback?: FetchCallback,
): ActionCallback<IInvitesParams> => ({
  type: ActionType.GET_INVITES,
  payload,
  callback,
});

export const createInvite = (
  payload: ICreateInvitePayload,
  callback?: FetchCallback,
): ActionCallback<ICreateInvitePayload> => ({
  type: ActionType.CREATE_INVITE,
  payload,
  callback,
});

export const declineInvite = (
  payload: IDeclineInviteParams,
  callback?: FetchCallback,
): ActionCallback<IDeclineInviteParams> => ({
  type: ActionType.DECLINE_INVITE,
  payload,
  callback,
});

export const setInvites = (
  payload: Nullable<IDataList<IInvite>>,
  callback?: FetchCallback,
): ActionCallback<IDataList<IInvite>> => ({
  type: ActionType.SET_INVITES,
  payload,
  callback,
});

export const resetInvite = (): ActionTypeOnly => ({
  type: ActionType.RESET,
});
