import { WorkspaceType } from "@constants";
import { Pagination } from "@services/api/api.types";

export enum ActionType {
  GET_WORKSPACES = "GET_WORKSPACES",
  GET_WORKSPACE_MANAGERS = "GET_WORKSPACE_MANAGERS",
  UPDATE_WORKSPACE = "UPDATE_WORKSPACE",
  SET_WORKSPACES = "SET_WORKSPACES",
  SET_ACTIVE_WORKSPACE = "SET_ACTIVE_WORKSPACE",
  SET_WORKSPACE_MANAGERS = "SET_WORKSPACE_MANAGERS",
  RESET = "RESET_WORKSPACE",
}

export interface IGetWorkspacesPayload {
  workspaceId?: string;
}

export interface IWorkspaceManagersParams extends Pagination {}

export interface IUpdateWorkspacePayload {
  name: string;
  description?: string;
  logo: string;
  email?: string;
  timezone: string;
}

export interface IWorkspace {
  workspace: {
    id: string;
    active: boolean;
    name: string;
    alias: string;
    type: WorkspaceType;
    createdBy: string;
    createdAt: Date;
    updatedAt: Date;
  };
  info: {
    description: string;
    logo: string;
    email: string;
    timezone: string;
    emailConfirmed: boolean;
    createdAt: Date;
    updatedAt: Date;
  };
  settings: {
    isNotificationsEnabled: boolean;
    isPublicWorkspace: boolean;
    canUserInvite: boolean;
    integrations: string[];
    createdAt: Date;
    updatedAt: Date;
  };
  membership: {
    active: boolean;
    createdAt: Date;
  };
}

export interface IWorkspaceManager {
  id: string;
  name: string;
  active: boolean;
  email: string;
  emailConfirmed: Date;
  lastSeen: Date;
  createdAt: Date;
  managership: {
    active: boolean;
    createdAt: Date;
  };
}
