import {
  ActionCallback,
  ActionTypeOnly,
  FetchCallback,
  IDataList,
  Nullable,
} from "@store/model";
import {
  ActionType,
  ITeam,
  ITeamMember,
  ITeamsParams,
  ITeamInfoParams,
  ITeamMembersParams,
  ICreateTeamPayload,
  IDeleteTeamParams,
  IUpdateTeamPayload,
  IJoinTeamMembersPayload,
} from "@store/team/model";

export const getTeams = (
  payload: ITeamsParams,
  callback?: FetchCallback,
): ActionCallback<ITeamsParams> => ({
  type: ActionType.GET_TEAMS,
  payload,
  callback,
});

export const getTeamInfo = (
  payload: ITeamInfoParams,
  callback?: FetchCallback,
): ActionCallback<ITeamInfoParams> => ({
  type: ActionType.GET_TEAM_INFO,
  payload,
  callback,
});

export const getTeamMembers = (
  payload: ITeamMembersParams,
  callback?: FetchCallback,
): ActionCallback<ITeamMembersParams> => ({
  type: ActionType.GET_TEAM_MEMBERS,
  payload,
  callback,
});

export const createTeam = (
  payload: ICreateTeamPayload,
  callback?: FetchCallback,
): ActionCallback<ICreateTeamPayload> => ({
  type: ActionType.CREATE_TEAM,
  payload,
  callback,
});

export const updateTeam = (
  payload: IUpdateTeamPayload,
  callback?: FetchCallback,
): ActionCallback<IUpdateTeamPayload> => ({
  type: ActionType.UPDATE_TEAM,
  payload,
  callback,
});

export const deleteTeam = (
  payload: IDeleteTeamParams,
  callback?: FetchCallback,
): ActionCallback<IDeleteTeamParams> => ({
  type: ActionType.DELETE_TEAM,
  payload,
  callback,
});

export const joinTeamMembers = (
  payload: IJoinTeamMembersPayload,
  callback?: FetchCallback,
): ActionCallback<IJoinTeamMembersPayload> => ({
  type: ActionType.JOIN_TEAM_MEMBERS,
  payload,
  callback,
});

export const leaveTeamMembers = (
  payload: IJoinTeamMembersPayload,
  callback?: FetchCallback,
): ActionCallback<IJoinTeamMembersPayload> => ({
  type: ActionType.LEAVE_TEAM_MEMBERS,
  payload,
  callback,
});

export const setTeams = (
  payload: Nullable<IDataList<ITeam>>,
  callback?: FetchCallback,
): ActionCallback<IDataList<ITeam>> => ({
  type: ActionType.SET_TEAMS,
  payload,
  callback,
});

export const setTeamInfo = (
  payload: Nullable<ITeam>,
  callback?: FetchCallback,
): ActionCallback<ITeam> => ({
  type: ActionType.SET_TEAM_INFO,
  payload,
  callback,
});

export const setTeamMembers = (
  payload: Nullable<IDataList<ITeamMember>>,
  callback?: FetchCallback,
): ActionCallback<IDataList<ITeamMember>> => ({
  type: ActionType.SET_TEAM_MEMBERS,
  payload,
  callback,
});

export const resetTeam = (): ActionTypeOnly => ({
  type: ActionType.RESET,
});
