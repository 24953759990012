import React, { FC } from "react";
import {
  Button,
  Grid,
  Option,
  Select as JoySelect,
  Stack,
  styled,
  Tab,
  TabList,
  TabPanel,
  Tabs,
  Typography,
} from "@mui/joy";
import { FileDownloadOutlined, SearchOutlined } from "@mui/icons-material";
import { DebounceInput } from "@components/form/DebounceInput";
import { RewardCard } from "@components/RewardCard";
import { RedemptionsTable } from "@components/RedemptionsTable";

export const Rewards: FC = () => {
  return (
    <>
      <Stack
        gap={4}
        direction={{ xs: "column", md: "row" }}
        alignItems={{ xs: "stretch", md: "center" }}
      >
        <Typography level="h2" flex={1}>
          Rewards
        </Typography>
        <Button
          size="md"
          startDecorator={<FileDownloadOutlined />}
          color="neutral"
          variant="plain"
          sx={{ boxShadow: "sm" }}
        >
          How it Works?
        </Button>
      </Stack>
      <Tabs
        aria-label="People tabs"
        defaultValue={0}
        sx={{ "--Tabs-spacing": 0, gap: 6 }}
      >
        <TabList>
          <Tab color="primary">Manage</Tab>
          <Tab color="primary">Redemptions</Tab>
        </TabList>
        <TabPanel value={0}>
          <Stack gap={6} direction={{ sx: "column", md: "row" }} mb={6}>
            <Input
              variant="plain"
              handleDebounce={() => {}}
              placeholder="Search..."
              startDecorator={<SearchOutlined />}
              sx={{ boxShadow: "sm" }}
            />
            <Select
              placeholder="Country of Use"
              variant="plain"
              sx={{ boxShadow: "sm" }}
            >
              <Option value="name">Name</Option>
              <Option value="email">Email</Option>
            </Select>
            <Select
              placeholder="Category"
              variant="plain"
              sx={{ boxShadow: "sm" }}
            >
              <Option value="name">Name</Option>
              <Option value="email">Email</Option>
            </Select>
          </Stack>
          <Grid container spacing={6}>
            <Grid xs={12} lg={4} xl={3}>
              <RewardCard />
            </Grid>
            <Grid xs={12} lg={4} xl={3}>
              <RewardCard />
            </Grid>
            <Grid xs={12} lg={4} xl={3}>
              <RewardCard />
            </Grid>
            <Grid xs={12} lg={4} xl={3}>
              <RewardCard />
            </Grid>
            <Grid xs={12} lg={4} xl={3}>
              <RewardCard />
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value={1}>
          <Stack gap={6} direction="row" mb={6}>
            <Input
              variant="plain"
              handleDebounce={() => {}}
              placeholder="Search..."
              startDecorator={<SearchOutlined />}
              sx={{ boxShadow: "sm" }}
            />
            <Select
              placeholder="Sort By"
              variant="plain"
              sx={{ boxShadow: "sm" }}
            >
              <Option value="name">Name</Option>
              <Option value="email">Email</Option>
            </Select>
          </Stack>
          <RedemptionsTable />
        </TabPanel>
      </Tabs>
    </>
  );
};

const Input = styled(DebounceInput)`
  flex: 1;
`;

const Select = styled(JoySelect)`
  width: 180px;
`;
