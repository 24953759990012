import React, { FC } from "react";
import AspectRatio from "@mui/joy/AspectRatio";
import Card from "@mui/joy/Card";
import CardContent from "@mui/joy/CardContent";
import CardOverflow from "@mui/joy/CardOverflow";
import Typography from "@mui/joy/Typography";
import { Chip, IconButton, Stack } from "@mui/joy";
import { BakeryDining, BarChartOutlined } from "@mui/icons-material";
import Divider from "@mui/joy/Divider";

export const RewardCard: FC = () => {
  return (
    <Card
      sx={{
        flex: 1,
        borderRadius: "xl",
        "--icon-size": "80px",
      }}
    >
      <CardOverflow variant="solid">
        <AspectRatio
          variant="outlined"
          color="warning"
          ratio="1"
          sx={{
            m: "auto 0",
            transform: "translateY(50%)",
            borderRadius: "xl",
            width: "var(--icon-size)",
            bgcolor: "background.surface",
            boxSizing: "border-box",
            position: "relative",
            borderStyle: "solid",
            borderColor: "background.surface",
            borderWidth: 2,
          }}
        >
          <BakeryDining />
        </AspectRatio>
      </CardOverflow>
      <Typography level="title-lg" sx={{ mt: "calc(var(--icon-size) / 2)" }}>
        Free Coffee at Starbucks
      </Typography>
      <CardContent>
        <Typography color="neutral">Voucher</Typography>
        <Stack direction="row" gap={2}>
          <Chip color="success">1 Cup</Chip>
          <Chip>2 Cups</Chip>
        </Stack>
      </CardContent>
      <CardOverflow>
        <Divider inset="context" />
        <IconButton size="lg" sx={{ ml: "auto" }}>
          <BarChartOutlined />
        </IconButton>
      </CardOverflow>
    </Card>
  );
};
