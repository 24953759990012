import { RootState } from "@store";
import { DEFAULT_LIMIT } from "@constants";
import { createSelector } from "@reduxjs/toolkit";
import { IDataList } from "@store/model";
import { IDepartment, IDepartmentMember } from "@store/department/model";

export const selectDepartments = (state: RootState) => {
  return state.department.departments;
};

export const selectDepartmentById = (
  state: RootState,
  departmentId: string,
) => {
  return state.department.departments?.items?.find(
    (department) => department.id === departmentId,
  );
};

export const selectDepartmentMembers = (state: RootState) => {
  return state.department.departmentMembers;
};

export const selectPagination = createSelector(
  (state: RootState) => state.department.departments,
  (departments: IDataList<IDepartment>) => ({
    limit: departments?.limit || DEFAULT_LIMIT,
    offset: departments?.offset || 0,
    totalPages: Math.ceil(departments?.total / departments?.limit) || 0,
    page: Math.ceil(departments?.offset / departments?.limit) + 1 || 0,
  }),
);

export const selectDepartmentMembersPagination = createSelector(
  (state: RootState) => state.department.departmentMembers,
  (departmentMembers: IDataList<IDepartmentMember>) => ({
    limit: departmentMembers?.limit || DEFAULT_LIMIT,
    offset: departmentMembers?.offset || 0,
    totalPages:
      Math.ceil(departmentMembers?.total / departmentMembers?.limit) || 0,
    page:
      Math.ceil(departmentMembers?.offset / departmentMembers?.limit) + 1 || 0,
  }),
);
