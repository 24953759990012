import React, { FC, useCallback, useEffect, useState } from "react";
import {
  Breadcrumbs,
  Sheet,
  styled,
  Link,
  Typography,
  Alert,
  IconButton,
  Grid,
} from "@mui/joy";
import { CheckCircle, CloseRounded } from "@mui/icons-material";
import { ChallengeDrawer } from "@drawers/ChallengeDrawer";
import { useNavigate, useParams } from "react-router";
import { useDispatch } from "react-redux";
import { useAppSelector } from "@store/hooks";
import { selectChallengeInfo } from "@store/challenge/selectors";
import useDrawer from "@/hooks/useDrawer";
import { IChallenge } from "@store/challenge/model";
import { getChallengeInfo } from "@store/challenge/actions";
import { Loader } from "@components/Loader";
import { Leaderboard } from "@components/challenge/Leaderboard";
import { LeaderboardType } from "@constants";
import { Analytics } from "@components/challenge/Analytics";
import { Info } from "@components/challenge/Info";

const teamsEnabled = false;

export const Challenge: FC = () => {
  const { challengeId } = useParams();
  const dispatch = useDispatch<any>();
  const [loading, setLoading] = useState(true);
  const [showChangesSaved, setShowChangesSaved] = useState(false);
  const { open, handleOpen, handleClose } = useDrawer<IChallenge>();
  const challenge = useAppSelector(selectChallengeInfo);
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(
      getChallengeInfo({ challengeId }, ({ success }) => {
        setLoading(false);
      }),
    );
  }, []);

  const handleCallback = useCallback((type?: "edit" | "delete") => {
    if (type === "edit") {
      setShowChangesSaved(true);
    } else if (type === "delete") {
      navigate("/challenges");
    }
    handleClose();
  }, []);

  const handleHideAlert = useCallback(() => {
    setShowChangesSaved(false);
  }, []);

  if (loading) {
    return <Loader />;
  }

  if (!challenge) {
    return null;
  }

  return (
    <>
      <ChallengeDrawer
        open={open}
        onClose={handleCallback}
        editing={challenge}
      />
      <Breadcrumbs aria-label="breadcrumbs">
        <Link color="neutral" href="/challenges">
          Challenges
        </Link>
        <Typography>{challenge.name}</Typography>
      </Breadcrumbs>
      {showChangesSaved ? (
        <Alert
          sx={{ alignItems: "flex-start" }}
          startDecorator={<CheckCircle />}
          variant="soft"
          color="success"
          endDecorator={
            <IconButton
              variant="soft"
              color="success"
              onClick={handleHideAlert}
            >
              <CloseRounded />
            </IconButton>
          }
        >
          <div>
            <Typography fontWeight="600" fontSize="md">
              Changes Saved
            </Typography>
            <Typography fontSize="sm">
              You have successfully updated challenge.
            </Typography>
          </div>
        </Alert>
      ) : null}
      <Info challenge={challenge} onOpen={handleOpen} />
      <Analytics challenge={challenge} />
      <Grid container spacing={6}>
        <Grid xs={12} lg={6}>
          <Leaderboard challenge={challenge} type={LeaderboardType.USER} />
        </Grid>
        {teamsEnabled ? (
          <Grid xs={12} lg={6}>
            <Leaderboard challenge={challenge} type={LeaderboardType.TEAM} />
          </Grid>
        ) : null}
      </Grid>
    </>
  );
};
