import React, { FC, useCallback, useState } from "react";
import { Box, Grid, IconButton, Stack, styled } from "@mui/joy";
import { ViewMode, ViewModeOptions } from "@constants";
import { TeamCard } from "@components/TeamCard";
import { TeamsTable } from "@components/TeamsTable";
import { useAppSelector } from "@store/hooks";
import useLoadData from "@/hooks/useLoadData";
import { getTeams } from "@store/team/actions";
import { selectTeams, selectPagination } from "@/store/team/selectors";
import { ITeam } from "@store/team/model";
import { DebounceInput } from "@components/form/DebounceInput";
import { SearchOutlined } from "@mui/icons-material";
import { Loader } from "@components/Loader";
import { Pagination } from "@components/Pagination";
import { EmptyState } from "@components/EmptyState";

type TeamsProps = {
  onEdit: (team: ITeam) => void;
};

export const Teams: FC<TeamsProps> = (props) => {
  const [search, setSearch] = useState("");
  const [viewMode, setViewMode] = useState<ViewMode>(ViewMode.LIST);

  const { totalPages, page } = useAppSelector(selectPagination);
  const teams = useAppSelector(selectTeams);
  const { loaded, loading, handlePaginate } = useLoadData<ITeam>(
    getTeams,
    search,
  );

  const handleSelectMode = useCallback((mode: ViewMode) => {
    setViewMode(mode);
  }, []);

  const handleEdit = useCallback((team: ITeam) => {
    props.onEdit(team);
  }, []);

  return (
    <>
      <Stack gap={6} direction="row" mb={6}>
        <Input
          variant="plain"
          handleDebounce={setSearch}
          placeholder="Search..."
          startDecorator={<SearchOutlined />}
          sx={{ boxShadow: "sm" }}
        />
        <Box sx={{ borderRadius: "md", boxShadow: "sm" }}>
          {ViewModeOptions.map((mode) => {
            const selected = viewMode === mode.value;
            const Icon = mode.icon;

            return (
              <IconButton
                key={`view-mode-${mode.value}`}
                variant={selected ? "outlined" : "plain"}
                color={selected ? "primary" : "neutral"}
                onClick={() => handleSelectMode(mode.value)}
              >
                <Icon />
              </IconButton>
            );
          })}
        </Box>
      </Stack>
      {loaded && teams?.items?.length === 0 ? (
        <EmptyState description="No teams has been added yet." />
      ) : viewMode === ViewMode.GRID ? (
        loading ? (
          <Loader />
        ) : (
          <>
            <Grid container spacing={6} alignItems="stretch">
              {teams?.items?.map((team) => (
                <Grid
                  xs={12}
                  xl={3}
                  lg={4}
                  md={6}
                  key={`team-${team.id}`}
                  display="flex"
                  alignItems="stretch"
                >
                  <TeamCard team={team} onEdit={handleEdit} />
                </Grid>
              ))}
            </Grid>
            <Stack alignItems="center" mt={6}>
              <Pagination
                count={totalPages}
                page={page}
                onChange={(event, page) => handlePaginate(page)}
                color="primary"
                disabled={loading}
              />
            </Stack>
          </>
        )
      ) : (
        <TeamsTable
          teams={teams}
          onPaginate={handlePaginate}
          onEdit={handleEdit}
          currentPage={page}
          totalPages={totalPages}
        />
      )}
    </>
  );
};

const Input = styled(DebounceInput)`
  flex: 1;
`;
