import React, { FC, useState } from "react";
import moment from "moment";
import useLoadData from "@/hooks/useLoadData";
import { useAppSelector } from "@store/hooks";
import { TableBody, TableContainer } from "@mui/material";
import { Header } from "@components/table/Header";
import { Sheet, Stack, styled, Table, Typography } from "@mui/joy";
import { Footer } from "@components/table/Footer";
import Box from "@mui/joy/Box";
import { CheckCircle, SearchOutlined } from "@mui/icons-material";
import { DebounceInput } from "@components/form/DebounceInput";
import Divider from "@mui/joy/Divider";
import { Placeholder } from "@components/table/Placeholder";
import { EmptyState } from "@components/EmptyState";
import { getWorkspaceManagers } from "@store/workspace/actions";
import {
  selectWorkspaceManagers,
  selectWorkspaceManagersPagination,
} from "@store/workspace/selectors";
import { IWorkspaceManager } from "@store/workspace/model";

const headCells = ["Name", "Email", "Last Seen", "Email Confirmed"];
const headCellsSize = headCells.length + 1;

export const ManagersTable: FC = (props) => {
  const [search, setSearch] = useState("");
  const { loaded, handlePaginate } = useLoadData<IWorkspaceManager>(
    getWorkspaceManagers,
    search,
  );

  const managers = useAppSelector(selectWorkspaceManagers);
  const { totalPages, page } = useAppSelector(
    selectWorkspaceManagersPagination,
  );

  return (
    <Stack gap={6}>
      {loaded && managers?.items?.length === 0 ? (
        <EmptyState description="No managers has been added yet." />
      ) : (
        <Sheet
          variant="outlined"
          sx={{
            width: "100%",
            boxShadow: "sm",
            borderRadius: "xl",
            overflow: "hidden",
          }}
        >
          <Box p={6} sx={{ bgcolor: "white" }}>
            <Input
              variant="plain"
              handleDebounce={setSearch}
              placeholder="Search..."
              startDecorator={<SearchOutlined />}
              sx={{ boxShadow: "sm" }}
            />
          </Box>
          <Divider />
          <TableContainer>
            <Table>
              <Header headCells={headCells} />
              <TableBody>
                {managers?.items?.map((manager) => {
                  return (
                    <tr key={manager.id}>
                      <td>
                        <Typography>{manager.name}</Typography>
                      </td>
                      <td>
                        <Typography>{manager.email}</Typography>
                      </td>
                      <td>
                        {manager.lastSeen
                          ? moment(manager.lastSeen).format("lll")
                          : "Never"}
                      </td>
                      <td>
                        {manager.emailConfirmed ? (
                          <CheckCircle color="success" />
                        ) : null}
                      </td>
                      <td />
                    </tr>
                  );
                })}
                {!managers?.total ? (
                  <Placeholder headCellsSize={headCellsSize} />
                ) : null}
              </TableBody>
              <Footer
                headCellsSize={headCellsSize}
                currentPage={page}
                totalPages={totalPages}
                onPaginate={handlePaginate}
              />
            </Table>
          </TableContainer>
        </Sheet>
      )}
    </Stack>
  );
};
const Input = styled(DebounceInput)`
  flex: 1;
`;
