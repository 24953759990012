import { call, fork, put, select, takeLatest } from "redux-saga/effects";
import {
  getChallengeInfo,
  getChallenges,
  setChallengeActivity,
  setChallengeHistory,
  setChallengeInfo,
  setChallenges,
  setChallengeSummary,
  setChallengeTeamLeaderboard,
  setChallengeUserLeaderboard,
} from "@store/challenge/actions";
import {
  ActionType,
  IChallengeActivityParams,
  IChallengeHistoryParams,
  IChallengeInfoParams,
  IChallengeLeaderboardParams,
  IChallengesParams,
  IChallengeSummaryParams,
  ICreateChallengePayload,
  IDeleteChallengeParams,
  IUpdateChallengePayload,
} from "@store/challenge/model";
import { ActionCallback } from "@store/model";
import * as api from "@services/api/challenge";
import { selectPagination } from "@store/challenge/selectors";

function* getChallengesSaga(): Generator {
  yield takeLatest(
    ActionType.GET_CHALLENGES,
    function* (action: ActionCallback<IChallengesParams>) {
      const { payload, callback } = action;
      try {
        const data = yield call(api.getChallenges, payload);
        yield put(setChallenges(data));

        callback?.({
          success: true,
          data,
        });
      } catch (e) {
        callback?.({
          success: false,
        });
      }
    },
  );
}

function* getChallengeInfoSaga(): Generator {
  yield takeLatest(
    ActionType.GET_CHALLENGE_INFO,
    function* (action: ActionCallback<IChallengeInfoParams>) {
      const { payload, callback } = action;
      try {
        const data = yield call(api.getChallengeInfo, payload);
        yield put(setChallengeInfo(data));

        callback?.({
          success: true,
          data,
        });
      } catch (e) {
        callback?.({
          success: false,
        });
      }
    },
  );
}

function* getChallengeActivitySaga(): Generator {
  yield takeLatest(
    ActionType.GET_CHALLENGE_ACTIVITY,
    function* (action: ActionCallback<IChallengeActivityParams>) {
      const { payload, callback } = action;
      try {
        const data = yield call(api.getChallengeActivity, payload);
        yield put(setChallengeActivity(data));

        callback?.({
          success: true,
          data,
        });
      } catch (e) {
        callback?.({
          success: false,
        });
      }
    },
  );
}

function* getChallengeHistorySaga(): Generator {
  yield takeLatest(
    ActionType.GET_CHALLENGE_HISTORY,
    function* (action: ActionCallback<IChallengeHistoryParams>) {
      const { payload, callback } = action;
      try {
        const data = yield call(api.getChallengeHistory, payload);
        yield put(setChallengeHistory(data));

        callback?.({
          success: true,
          data,
        });
      } catch (e) {
        callback?.({
          success: false,
        });
      }
    },
  );
}

function* getChallengeTeamLeaderboardSaga(): Generator {
  yield takeLatest(
    ActionType.GET_CHALLENGE_TEAM_LEADERBOARD,
    function* (action: ActionCallback<IChallengeLeaderboardParams>) {
      const { payload, callback } = action;
      try {
        const data = yield call(api.getChallengeTeamLeaderboard, payload);
        yield put(setChallengeTeamLeaderboard(data));

        callback?.({
          success: true,
          data,
        });
      } catch (e) {
        callback?.({
          success: false,
        });
      }
    },
  );
}

function* getChallengeUserLeaderboardSaga(): Generator {
  yield takeLatest(
    ActionType.GET_CHALLENGE_USER_LEADERBOARD,
    function* (action: ActionCallback<IChallengeLeaderboardParams>) {
      const { payload, callback } = action;
      try {
        const data = yield call(api.getChallengeUserLeaderboard, payload);
        yield put(setChallengeUserLeaderboard(data));

        callback?.({
          success: true,
          data,
        });
      } catch (e) {
        callback?.({
          success: false,
        });
      }
    },
  );
}

function* getChallengeSummarySaga(): Generator {
  yield takeLatest(
    ActionType.GET_CHALLENGE_SUMMARY,
    function* (action: ActionCallback<IChallengeSummaryParams>) {
      const { payload, callback } = action;
      try {
        const data = yield call(api.getChallengeSummary, payload);
        yield put(setChallengeSummary(data));

        callback?.({
          success: true,
          data,
        });
      } catch (e) {
        callback?.({
          success: false,
        });
      }
    },
  );
}

function* createChallengeSaga(): Generator {
  yield takeLatest(
    ActionType.CREATE_CHALLENGE,
    function* (action: ActionCallback<ICreateChallengePayload>) {
      const { payload, callback } = action;
      const pagination = yield select(selectPagination);
      try {
        yield call(api.createChallenge, payload);
        yield put(getChallenges(pagination));
        callback?.({
          success: true,
        });
      } catch (e) {
        callback?.({
          success: false,
        });
      }
    },
  );
}

function* updateChallengeSaga(): Generator {
  yield takeLatest(
    ActionType.UPDATE_CHALLENGE,
    function* (action: ActionCallback<IUpdateChallengePayload>) {
      const { payload, callback } = action;
      try {
        yield call(api.updateChallenge, payload);
        yield put(
          getChallengeInfo({ challengeId: payload.challengeId }, callback),
        );
      } catch (e) {
        callback?.({
          success: false,
        });
      }
    },
  );
}

function* deleteChallengeSaga(): Generator {
  yield takeLatest(
    ActionType.DELETE_CHALLENGE,
    function* (action: ActionCallback<IDeleteChallengeParams>) {
      const { payload, callback } = action;
      const pagination = yield select(selectPagination);
      try {
        yield call(api.deleteChallenge, payload);
        yield put(getChallenges(pagination));
        callback?.({
          success: true,
        });
      } catch (e) {
        callback?.({
          success: false,
        });
      }
    },
  );
}

export default [
  fork(getChallengesSaga),
  fork(getChallengeInfoSaga),
  fork(getChallengeActivitySaga),
  fork(getChallengeHistorySaga),
  fork(getChallengeTeamLeaderboardSaga),
  fork(getChallengeUserLeaderboardSaga),
  fork(getChallengeSummarySaga),
  fork(createChallengeSaga),
  fork(updateChallengeSaga),
  fork(deleteChallengeSaga),
];
