import { AxiosResponse } from "axios";
import { axiosInstance } from "@services/api/api";
import { ApiSignInPayload, ApiTokensResponse } from "./auth.types";

export const signIn = async (
  data: ApiSignInPayload,
): Promise<ApiTokensResponse> => {
  const response: AxiosResponse<ApiTokensResponse> = await axiosInstance.post(
    `/auth/refresh-tokens`,
    data,
  );

  return response.data;
};
