import { RootState } from "@store";
import { createSelector } from "@reduxjs/toolkit";
import { IDataList } from "@store/model";
import { DEFAULT_LIMIT } from "@constants";
import { IWorkspaceManager } from "@store/workspace/model";

export const selectWorkspaces = (state: RootState) => {
  return state.workspace.workspaces;
};

export const selectActiveWorkspace = (state: RootState) => {
  return state.workspace.activeWorkspace;
};

export const selectWorkspaceManagers = (state: RootState) => {
  return state.workspace.managers;
};

export const selectWorkspaceManagersPagination = createSelector(
  (state: RootState) => state.workspace.managers,
  (workspaceManager: IDataList<IWorkspaceManager>) => ({
    limit: workspaceManager?.limit || DEFAULT_LIMIT,
    offset: workspaceManager?.offset || 0,
    totalPages:
      Math.ceil(workspaceManager?.total / workspaceManager?.limit) || 0,
    page:
      Math.ceil(workspaceManager?.offset / workspaceManager?.limit) + 1 || 1,
  }),
);
