import React, { FC, useCallback } from "react";
import AspectRatio from "@mui/joy/AspectRatio";
import Card from "@mui/joy/Card";
import CardContent from "@mui/joy/CardContent";
import CardOverflow from "@mui/joy/CardOverflow";
import Typography from "@mui/joy/Typography";
import { Avatar, IconButton, Stack } from "@mui/joy";
import { EditOutlined, DeleteOutlined } from "@mui/icons-material";
import Divider from "@mui/joy/Divider";
import { IDepartment } from "@store/department/model";
import pluralize from "pluralize";

type DepartmentCardProps = {
  department: IDepartment;
  onEdit: (department: IDepartment) => void;
};

export const DepartmentCard: FC<DepartmentCardProps> = (props) => {
  const { department, onEdit } = props;

  const handleEdit = useCallback(() => {
    onEdit(department);
  }, [department]);

  return (
    <Card
      sx={{
        textAlign: "center",
        alignItems: "center",
        flex: 1,
        borderRadius: "xl",
        "--icon-size": "100px",
      }}
    >
      <CardOverflow variant="solid">
        <AspectRatio
          variant="outlined"
          ratio="1"
          sx={{
            m: "auto",
            transform: "translateY(50%)",
            borderRadius: "50%",
            width: "var(--icon-size)",
            bgcolor: "background.surface",
            boxSizing: "border-box",
            position: "relative",
            borderStyle: "solid",
            borderColor: "background.surface",
            borderWidth: 4,
          }}
        >
          <Avatar alt={department.name} src={department.logo} />
        </AspectRatio>
      </CardOverflow>
      <Typography level="title-lg" sx={{ mt: "calc(var(--icon-size) / 2)" }}>
        {department.name}
      </Typography>
      <CardContent>
        <Typography color="neutral">
          {department.membersCount}{" "}
          {pluralize("Member", department.membersCount)}
        </Typography>
      </CardContent>
      <CardOverflow>
        <Divider inset="context" />
        <Stack direction="row" gap={2} justifyContent="flex-end">
          <IconButton size="lg" onClick={handleEdit}>
            <EditOutlined />
          </IconButton>
        </Stack>
      </CardOverflow>
    </Card>
  );
};
