import React, { FC } from "react";
import MuiPagination, { PaginationProps } from "@mui/material/Pagination";
import { styled } from "@mui/joy";
import Box from "@mui/joy/Box";

export const Pagination: FC<PaginationProps> = (props) => {
  return (
    <Container>
      <MuiPagination {...props} />
    </Container>
  );
};

const Container = styled(Box)`
  & .MuiPaginationItem-root.Mui-selected {
    background-color: ${({ theme }) => theme.palette.primary["700"]};
    &:hover {
      background-color: ${({ theme }) => theme.palette.primary["800"]};
    }
  }
`;
