import { PayloadAction } from "@reduxjs/toolkit";
import { ActionType, ITokens } from "@store/auth/model";
import createReducer from "@store/createReducer";

export interface AuthState {
  tokens: ITokens;
  loggedIn: boolean;
}

const defaultState: AuthState = {
  tokens: null,
  loggedIn: false,
};

export const authReducer = createReducer<AuthState>(defaultState, {
  [ActionType.SET_AUTH](state: AuthState, action: PayloadAction<ITokens>) {
    return { ...state, tokens: action.payload, loggedIn: !!action.payload };
  },
  [ActionType.SET_LOGGED_IN](state: AuthState, action: PayloadAction<boolean>) {
    return { ...state, loggedIn: action.payload };
  },
  [ActionType.RESET]() {
    return defaultState;
  },
});
