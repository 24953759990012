import React, { FC } from "react";
import { InvitesTable } from "@components/InvitesTable";
import { useAppSelector } from "@store/hooks";
import useLoadData from "@/hooks/useLoadData";
import { getInvites } from "@store/invite/actions";
import { selectInvites, selectPagination } from "@/store/invite/selectors";
import { IInvite } from "@store/invite/model";

type InvitesProps = {};

export const Invites: FC<InvitesProps> = (props) => {
  const { totalPages, page } = useAppSelector(selectPagination);
  const invites = useAppSelector(selectInvites);
  const { loaded, loading, handlePaginate } = useLoadData<IInvite>(getInvites);

  return (
    <InvitesTable
      invites={invites}
      onPaginate={handlePaginate}
      currentPage={page}
      totalPages={totalPages}
    />
  );
};
