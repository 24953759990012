import { AxiosResponse } from "axios";
import { axiosInstance } from "@services/api";
import { ApiUpdateManagerPayload, ApiManagerResponse } from "./manager.types";

export const getManager = async (): Promise<ApiManagerResponse> => {
  const response: AxiosResponse<ApiManagerResponse> =
    await axiosInstance.get("");

  return response.data;
};

export const updateManager = async (
  data: ApiUpdateManagerPayload,
): Promise<ApiManagerResponse> => {
  const response: AxiosResponse<ApiManagerResponse> = await axiosInstance.post(
    `/update`,
    data,
  );

  return response.data;
};
