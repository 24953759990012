import {
  ActionType,
  IChallenge,
  IChallengeActivity,
  IChallengeActivityParams,
  IChallengeHistory,
  IChallengeHistoryParams,
  IChallengeInfoParams,
  IChallengeLeaderboardParams,
  IChallengesParams,
  IChallengeSummary,
  IChallengeSummaryParams,
  IChallengeTeamLeaderboard,
  IChallengeUserLeaderboard,
  ICreateChallengePayload,
  IDeleteChallengeParams,
  IUpdateChallengePayload,
} from "@store/challenge/model";
import {
  ActionCallback,
  ActionTypeOnly,
  FetchCallback,
  IDataList,
  Nullable,
} from "@store/model";

export const getChallenges = (
  payload?: IChallengesParams,
  callback?: FetchCallback,
): ActionCallback<IChallengesParams> => ({
  type: ActionType.GET_CHALLENGES,
  payload,
  callback,
});

export const getChallengeInfo = (
  payload: IChallengeInfoParams,
  callback?: FetchCallback,
): ActionCallback<IChallengeInfoParams> => ({
  payload,
  type: ActionType.GET_CHALLENGE_INFO,
  callback,
});

export const getChallengeActivity = (
  payload: IChallengeActivityParams,
  callback?: FetchCallback,
): ActionCallback<IChallengeActivityParams> => ({
  payload,
  type: ActionType.GET_CHALLENGE_ACTIVITY,
  callback,
});

export const getChallengeHistory = (
  payload: IChallengeHistoryParams,
  callback?: FetchCallback,
): ActionCallback<IChallengeHistoryParams> => ({
  payload,
  type: ActionType.GET_CHALLENGE_HISTORY,
  callback,
});

export const getChallengeTeamLeaderboard = (
  payload: IChallengeLeaderboardParams,
  callback?: FetchCallback,
): ActionCallback<IChallengeLeaderboardParams> => ({
  payload,
  type: ActionType.GET_CHALLENGE_TEAM_LEADERBOARD,
  callback,
});

export const getChallengeUserLeaderboard = (
  payload: IChallengeLeaderboardParams,
  callback?: FetchCallback,
): ActionCallback<IChallengeLeaderboardParams> => ({
  payload,
  type: ActionType.GET_CHALLENGE_USER_LEADERBOARD,
  callback,
});

export const getChallengeSummary = (
  payload: IChallengeSummaryParams,
  callback?: FetchCallback,
): ActionCallback<IChallengeSummaryParams> => ({
  payload,
  type: ActionType.GET_CHALLENGE_SUMMARY,
  callback,
});

export const createChallenge = (
  payload: ICreateChallengePayload,
  callback?: FetchCallback,
): ActionCallback<ICreateChallengePayload> => ({
  type: ActionType.CREATE_CHALLENGE,
  payload,
  callback,
});

export const updateChallenge = (
  payload: IUpdateChallengePayload,
  callback?: FetchCallback,
): ActionCallback<IUpdateChallengePayload> => ({
  type: ActionType.UPDATE_CHALLENGE,
  payload,
  callback,
});

export const deleteChallenge = (
  payload: IDeleteChallengeParams,
  callback?: FetchCallback,
): ActionCallback<IDeleteChallengeParams> => ({
  type: ActionType.DELETE_CHALLENGE,
  payload,
  callback,
});

export const setChallenges = (
  payload: Nullable<IDataList<IChallenge>>,
  callback?: FetchCallback,
): ActionCallback<IDataList<IChallenge>> => ({
  type: ActionType.SET_CHALLENGES,
  payload,
  callback,
});

export const setChallengeInfo = (
  payload: IChallenge,
  callback?: FetchCallback,
): ActionCallback<IChallenge> => ({
  type: ActionType.SET_CHALLENGE_INFO,
  payload,
  callback,
});

export const setChallengeActivity = (
  payload: Array<IChallengeActivity>,
  callback?: FetchCallback,
): ActionCallback<Array<IChallengeActivity>> => ({
  type: ActionType.SET_CHALLENGE_ACTIVITY,
  payload,
  callback,
});

export const setChallengeHistory = (
  payload: Nullable<IDataList<IChallengeHistory>>,
  callback?: FetchCallback,
): ActionCallback<IDataList<IChallengeHistory>> => ({
  type: ActionType.SET_CHALLENGE_HISTORY,
  payload,
  callback,
});

export const setChallengeTeamLeaderboard = (
  payload: Nullable<IDataList<IChallengeTeamLeaderboard>>,
  callback?: FetchCallback,
): ActionCallback<IDataList<IChallengeTeamLeaderboard>> => ({
  type: ActionType.SET_CHALLENGE_TEAM_LEADERBOARD,
  payload,
  callback,
});

export const setChallengeUserLeaderboard = (
  payload: Nullable<IDataList<IChallengeUserLeaderboard>>,
  callback?: FetchCallback,
): ActionCallback<IDataList<IChallengeUserLeaderboard>> => ({
  type: ActionType.SET_CHALLENGE_USER_LEADERBOARD,
  payload,
  callback,
});

export const setChallengeSummary = (
  payload: IChallengeSummary,
  callback?: FetchCallback,
): ActionCallback<IChallengeSummary> => ({
  type: ActionType.SET_CHALLENGE_SUMMARY,
  payload,
  callback,
});

export const resetChallenge = (): ActionTypeOnly => ({
  type: ActionType.RESET,
});
