import { RootState } from "@store";
import { DEFAULT_LIMIT } from "@constants";
import { createSelector } from "@reduxjs/toolkit";
import { IDataList } from "@store/model";
import { ITeam, ITeamMember } from "@store/team/model";

export const selectTeams = (state: RootState) => {
  return state.team.teams;
};

export const selectTeamById = (state: RootState, teamId: string) => {
  return state.team.teams?.items?.find((team) => team.id === teamId);
};

export const selectTeamMembers = (state: RootState) => {
  return state.team.teamMembers;
};

export const selectPagination = createSelector(
  (state: RootState) => state.team.teams,
  (teams: IDataList<ITeam>) => ({
    limit: teams?.limit || DEFAULT_LIMIT,
    offset: teams?.offset || 0,
    totalPages: Math.ceil(teams?.total / teams?.limit) || 0,
    page: Math.ceil(teams?.offset / teams?.limit) + 1 || 0,
  }),
);

export const selectTeamMembersPagination = createSelector(
  (state: RootState) => state.team.teamMembers,
  (teamMembers: IDataList<ITeamMember>) => ({
    limit: teamMembers?.limit || DEFAULT_LIMIT,
    offset: teamMembers?.offset || 0,
    totalPages: Math.ceil(teamMembers?.total / teamMembers?.limit) || 0,
    page: Math.ceil(teamMembers?.offset / teamMembers?.limit) + 1 || 0,
  }),
);
