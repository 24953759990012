import BaseConfig from "./config.base";
import ProdConfig from "./config.prod";
import DevConfig from "./config.dev";

let ExtraConfig = DevConfig;

if (process.env.NODE_ENV === "production") {
  ExtraConfig = ProdConfig;
}

const Config = { ...BaseConfig, ...ExtraConfig };

export default Config;
