import { call, fork, put, select, takeLatest } from "redux-saga/effects";
import { ActionCallback } from "@store/model";
import {
  getUsers,
  getUserTeams,
  setUser,
  setUsers,
  setUserTeams,
} from "@store/user/actions";
import {
  ActionType,
  IDropUserParams,
  IJoinUserTeamsPayload,
  ISetUserDepartmentPayload,
  IUserParams,
  IUsersParams,
  IUserTeamsParams,
} from "@store/user/model";
import * as api from "@services/api/user";
import * as teamApi from "@services/api/team";
import * as departmentApi from "@services/api/department";
import {
  selectPagination,
  selectUserTeamsPagination,
} from "@store/user/selectors";

function* getUsersSaga(): Generator {
  yield takeLatest(
    ActionType.GET_USERS,
    function* (action: ActionCallback<IUsersParams>) {
      const { payload, callback } = action;
      try {
        const data = yield call(api.getUsers, payload);

        yield put(setUsers(data));

        callback?.({
          success: true,
          data,
        });
      } catch (e) {
        callback?.({
          success: false,
        });
      }
    },
  );
}

function* getUserSaga(): Generator {
  yield takeLatest(
    ActionType.GET_USER,
    function* (action: ActionCallback<IUserParams>) {
      const { payload, callback } = action;
      try {
        const data = yield call(api.getUser, payload);

        yield put(setUser(data));

        callback?.({ success: true, data });
      } catch (e) {
        callback?.({ success: false });
      }
    },
  );
}

function* getUserTeamsSaga(): Generator {
  yield takeLatest(
    ActionType.GET_USER_TEAMS,
    function* (action: ActionCallback<IUserTeamsParams>) {
      const { payload, callback } = action;
      try {
        const data = yield call(api.getUserTeams, payload);

        yield put(setUserTeams(data));

        callback?.({ success: true, data });
      } catch (e) {
        callback?.({ success: false });
      }
    },
  );
}

function* setUserDepartmentSaga(): Generator {
  yield takeLatest(
    ActionType.SET_USER_DEPARTMENT,
    function* (action: ActionCallback<ISetUserDepartmentPayload>) {
      const { payload, callback } = action;
      const pagination = yield select(selectPagination);
      try {
        yield call(departmentApi.joinDepartmentUsers, {
          id: payload.departmentId,
          users: [payload.userId],
        });

        yield put(getUsers(pagination, callback));
      } catch (e) {
        callback?.({ success: false });
      }
    },
  );
}

function* joinUserTeamsSaga(): Generator {
  yield takeLatest(
    ActionType.JOIN_USER_TEAMS,
    function* (action: ActionCallback<IJoinUserTeamsPayload>) {
      const { payload, callback } = action;
      const pagination = yield select(selectUserTeamsPagination);
      try {
        yield call(api.joinUserTeams, payload);

        yield put(
          getUserTeams({ ...pagination, userId: payload.userId }, callback),
        );
      } catch (e) {
        callback?.({ success: false });
      }
    },
  );
}

function* leaveUserTeamsSaga(): Generator {
  yield takeLatest(
    ActionType.LEAVE_USER_TEAMS,
    function* (action: ActionCallback<IJoinUserTeamsPayload>) {
      const { payload, callback } = action;
      const pagination = yield select(selectUserTeamsPagination);
      try {
        yield call(teamApi.leaveTeamUsers, {
          id: payload.teams[0],
          users: [payload.userId],
        });

        yield put(
          getUserTeams({ ...pagination, userId: payload.userId }, callback),
        );
      } catch (e) {
        callback?.({ success: false });
      }
    },
  );
}

function* dropUserSaga(): Generator {
  yield takeLatest(
    ActionType.DROP_USER,
    function* (action: ActionCallback<IDropUserParams>) {
      const { payload, callback } = action;
      const pagination = yield select(selectPagination);
      try {
        yield call(api.dropUser, payload);

        yield put(getUsers(pagination, callback));
      } catch (e) {
        callback?.({ success: false });
      }
    },
  );
}

export default [
  fork(getUsersSaga),
  fork(getUserSaga),
  fork(getUserTeamsSaga),
  fork(setUserDepartmentSaga),
  fork(joinUserTeamsSaga),
  fork(leaveUserTeamsSaga),
  fork(dropUserSaga),
];
