import * as React from "react";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import Typography from "@mui/joy/Typography";
import Textarea, { TextareaProps } from "@mui/joy/Textarea";
import { FormHelperText } from "@mui/joy";

interface JoyTextareaProps extends TextareaProps {
  label?: React.ReactNode;
  TextareaProps?: {
    ref?: React.Ref<any>;
    endAdornment?: React.ReactNode;
    startAdornment?: React.ReactNode;
  };
  formControlSx?: TextareaProps["sx"];
  errorMessage?: string;
}

type JoyTextareaComponent = ((
  props: JoyTextareaProps & React.RefAttributes<HTMLDivElement>,
) => React.JSX.Element) & { propTypes?: any };

export const TextareaInput = React.forwardRef(
  (props: JoyTextareaProps, ref: React.Ref<HTMLDivElement>) => {
    const {
      disabled,
      required,
      id,
      error,
      label,
      TextareaProps: { ref: containerRef, startAdornment, endAdornment } = {},
      endDecorator,
      startDecorator,
      slotProps,
      errorMessage,
      ...other
    } = props;

    return (
      <FormControl
        disabled={disabled}
        id={id}
        ref={ref}
        sx={{ flex: 1 }}
        error={error}
      >
        <FormLabel>
          {label}
          {required ? <Typography color="danger">*</Typography> : null}
        </FormLabel>
        <Textarea
          ref={ref}
          disabled={disabled}
          variant="soft"
          size="lg"
          endDecorator={
            <React.Fragment>
              {endAdornment}
              {endDecorator}
            </React.Fragment>
          }
          slotProps={{
            ...slotProps,
            root: { ...slotProps?.root, ref: containerRef },
          }}
          {...other}
        />
        {errorMessage ? <FormHelperText>{errorMessage}</FormHelperText> : null}
      </FormControl>
    );
  },
) as JoyTextareaComponent;
