import pluralize from "pluralize";
import React from "react";
import { Avatar, Stack, styled, Typography } from "@mui/joy";
import { IChallengeLeaderboard } from "@store/challenge/model";

const mapPlaceToMedal = {
  1: "/assets/images/firstPlaceMedal.png",
  2: "/assets/images/secondPlaceMedal.png",
  3: "/assets/images/thirdPlaceMedal.png",
};

type TopWinnerProps = {
  leaderboard?: IChallengeLeaderboard;
  unit: string;
};

export const TopWinner = (props: TopWinnerProps) => {
  const { leaderboard, unit } = props;

  if (!leaderboard) {
    return null;
  }

  return (
    <Stack alignItems="center" gap={1}>
      <Avatar size="lg" src={leaderboard.image} />
      <MedalImage src={mapPlaceToMedal[leaderboard.order]} />
      <Typography fontWeight="500">{leaderboard.name}</Typography>
      <Typography color="neutral">
        {leaderboard.averageValue} {pluralize(unit, leaderboard.averageValue)}
      </Typography>
    </Stack>
  );
};

const MedalImage = styled("img")`
  width: 24px;
  height: 24px;
`;
