export enum ActionType {
  GET_MANAGER = "GET_MANAGER",
  UPDATE_MANAGER = "UPDATE_MANAGER",
  SET_MANAGER = "SET_MANAGER",
  RESET = "RESET_MANAGER",
}

export interface IUpdateManagerPayload {
  name?: string;
  password?: string;
  newPassword?: string;
}

export interface IManager {
  id: string;
  name: string;
  active: boolean;
  email: string;
  emailConfirmed: Date;
  lastSeen: Date;
  createdAt: Date;
}
