import React, { FC, useEffect, useState } from "react";
import { IChallenge } from "@store/challenge/model";
import { Card, Stack, Typography } from "@mui/joy";
import { capitalize } from "lodash";
import { BarChart } from "@mui/x-charts";
import { useAppSelector } from "@store/hooks";
import { selectChallengeActivity } from "@store/challenge/selectors";
import { useDispatch } from "react-redux";
import { getChallengeActivity } from "@store/challenge/actions";
import moment from "moment";
import { DatasetType } from "@mui/x-charts/models/seriesType/config";
import { History } from "@components/challenge/History";

type AnalyticsProps = {
  challenge: IChallenge;
};

export const Analytics: FC<AnalyticsProps> = (props) => {
  const { challenge } = props;
  const dispatch = useDispatch<any>();
  const [loading, setLoading] = useState(true);
  const activity = useAppSelector(selectChallengeActivity);

  useEffect(() => {
    dispatch(
      getChallengeActivity(
        { challengeId: challenge.challengeId },
        ({ success }) => {
          setLoading(false);
        },
      ),
    );
  }, []);

  return (
    <Stack gap={6}>
      <Stack
        direction={{ xs: "column", md: "row" }}
        justifyContent={{ xs: "stretch", md: "space-between" }}
        gap={2}
      >
        <Typography fontSize={30} fontWeight="700">
          Analytic
        </Typography>
        <History challenge={challenge} />
      </Stack>
      <Card sx={{ bgcolor: "white", borderRadius: "xl", p: 6, gap: 6 }}>
        <Typography fontSize={16} fontWeight="600">
          Activity (in {capitalize(challenge.unit)})
        </Typography>
        <BarChart
          loading={loading}
          grid={{ horizontal: true }}
          dataset={(activity as DatasetType<any>) || []}
          margin={{
            left: 60,
          }}
          xAxis={[
            {
              scaleType: "band",
              dataKey: "date",
              hideTooltip: true,
              valueFormatter: (value: string) => moment(value).format("ll"),
            },
          ]}
          series={[{ dataKey: "value" }]}
          leftAxis={{
            disableLine: true,
            tickFontSize: 14,
            tickSize: 0,
          }}
          bottomAxis={{
            disableLine: true,
            tickFontSize: 14,
            tickSize: 0,
          }}
          height={300}
          borderRadius={12}
          colors={["#7A5AF8"]}
          slotProps={{
            bar: {
              width: 6,
            },
          }}
        />
      </Card>
    </Stack>
  );
};
