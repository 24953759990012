import React, { FC, useCallback } from "react";
import { Stack, Tab, TabList, TabPanel, Tabs, Typography } from "@mui/joy";
import { OrganizationProfile } from "@components/OrganizationProfile";
import { ManagerProfile } from "@components/ManagerProfile";
import { ManagersTable } from "@components/ManagersTable";

export const Organization: FC = () => {
  return (
    <>
      <Stack gap={4} direction="row" alignItems="center">
        <Typography level="h2" flex={1}>
          Organization
        </Typography>
      </Stack>
      <Tabs
        aria-label="People tabs"
        defaultValue={0}
        sx={{ "--Tabs-spacing": 0, gap: 6 }}
      >
        <TabList>
          <Tab color="primary">Organization Profile</Tab>
          <Tab color="primary">Manager Profile</Tab>
          <Tab color="primary">Workspace Managers</Tab>
        </TabList>
        <TabPanel value={0}>
          <OrganizationProfile />
        </TabPanel>
        <TabPanel value={1}>
          <ManagerProfile />
        </TabPanel>
        <TabPanel value={2}>
          <ManagersTable />
        </TabPanel>
      </Tabs>
    </>
  );
};
