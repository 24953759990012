import { call, fork, put, select, takeLatest } from "redux-saga/effects"
import { setLoggedIn, signOut } from "@store/auth/actions"
import { ActionCallback, ActionCallbackOnly } from "@store/model"
import { setOtpReference, setProfile } from "@store/profile/actions"
import { ActionType, IConfirmPayload, IProfilePayload } from "@store/profile/model"
import {
  confirmEmailConfirm,
  confirmEmailRequest,
  deleteProfileConfirm,
  deleteProfileRequest,
  getProfile,
  saveProfile,
} from "@services/api/profile"

function* getProfileSaga(): Generator {
  yield takeLatest(ActionType.GET_PROFILE, function* (action: ActionCallbackOnly) {
    const { callback } = action
    try {
      const data = yield call(getProfile)

      yield put(setProfile(data))
      yield put(setLoggedIn(true))

      callback?.({
        success: true,
        data,
      })
    } catch (e) {
      callback?.({
        success: false,
      })
    }
  })
}

function* saveProfileSaga(): Generator {
  yield takeLatest(ActionType.SAVE_PROFILE, function* (action: ActionCallback<IProfilePayload>) {
    const { payload, callback } = action
    try {
      const data = yield call(saveProfile, payload)

      yield put(setProfile(data))

      callback?.({
        success: true,
        data,
      })
    } catch (e) {
      callback?.({
        success: false,
      })
    }
  })
}

function* deleteProfileRequestSaga(): Generator {
  yield takeLatest(ActionType.DELETE_PROFILE_REQUEST, function* (action: ActionCallbackOnly) {
    const { callback } = action
    try {
      const data = yield call(deleteProfileRequest)

      yield put(setOtpReference(data))

      callback?.({
        success: true,
        data,
      })
    } catch (e) {
      callback?.({
        success: false,
      })
    }
  })
}

function* deleteProfileConfirmSaga(): Generator {
  yield takeLatest(
    ActionType.DELETE_PROFILE_CONFIRM,
    function* (action: ActionCallback<IConfirmPayload>) {
      const { payload, callback } = action
      try {
        const { otpReference } = yield select(({ profile }) => profile)
        const data = yield call(deleteProfileConfirm, {
          ...payload,
          otpReference,
        })

        yield put(signOut())

        callback?.({
          success: true,
          data,
        })
      } catch (e) {
        callback?.({
          success: false,
        })
      }
    },
  )
}

function* confirmEmailRequestSaga(): Generator {
  yield takeLatest(ActionType.CONFIRM_EMAIL_REQUEST, function* (action: ActionCallbackOnly) {
    const { callback } = action
    try {
      const data = yield call(confirmEmailRequest)

      yield put(setOtpReference(data))

      callback?.({
        success: true,
        data,
      })
    } catch (e) {
      callback?.({
        success: false,
      })
    }
  })
}

function* confirmEmailConfirmSaga(): Generator {
  yield takeLatest(
    ActionType.CONFIRM_EMAIL_CONFIRM,
    function* (action: ActionCallback<IConfirmPayload>) {
      const { payload, callback } = action
      try {
        const { otpReference } = yield select(({ profile }) => profile)
        const data = yield call(confirmEmailConfirm, {
          ...payload,
          otpReference,
        })

        callback?.({
          success: true,
          data,
        })
      } catch (e) {
        callback?.({
          success: false,
        })
      }
    },
  )
}

export default [
  fork(getProfileSaga),
  fork(saveProfileSaga),
  fork(deleteProfileRequestSaga),
  fork(deleteProfileConfirmSaga),
  fork(confirmEmailRequestSaga),
  fork(confirmEmailConfirmSaga),
]
