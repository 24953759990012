import React, { FC, useCallback, useState } from "react";
import { Box, Grid, IconButton, Stack, styled } from "@mui/joy";
import { ViewMode, ViewModeOptions } from "@constants";
import { DepartmentCard } from "@components/DepartmentCard";
import { DepartmentsTable } from "@components/DepartmentsTable";
import { useAppSelector } from "@store/hooks";
import useLoadData from "@/hooks/useLoadData";
import { getDepartments } from "@store/department/actions";
import {
  selectDepartments,
  selectPagination,
} from "@/store/department/selectors";
import { IDepartment } from "@store/department/model";
import { DebounceInput } from "@components/form/DebounceInput";
import { SearchOutlined } from "@mui/icons-material";
import { Loader } from "@components/Loader";
import { Pagination } from "@components/Pagination";
import { EmptyState } from "@components/EmptyState";

type DepartmentsProps = {
  onEdit: (department: IDepartment) => void;
};

export const Departments: FC<DepartmentsProps> = (props) => {
  const [search, setSearch] = useState("");
  const [viewMode, setViewMode] = useState<ViewMode>(ViewMode.LIST);

  const { totalPages, page } = useAppSelector(selectPagination);
  const departments = useAppSelector(selectDepartments);
  const { loaded, loading, handlePaginate } = useLoadData<IDepartment>(
    getDepartments,
    search,
  );

  const handleSelectMode = useCallback((mode: ViewMode) => {
    setViewMode(mode);
  }, []);

  const handleEdit = useCallback((department: IDepartment) => {
    props.onEdit(department);
  }, []);

  return (
    <>
      <Stack gap={6} direction="row" mb={6}>
        <Input
          variant="plain"
          handleDebounce={setSearch}
          placeholder="Search..."
          startDecorator={<SearchOutlined />}
          sx={{ boxShadow: "sm" }}
        />
        <Box sx={{ borderRadius: "md", boxShadow: "sm" }}>
          {ViewModeOptions.map((mode) => {
            const selected = viewMode === mode.value;
            const Icon = mode.icon;

            return (
              <IconButton
                key={`view-mode-${mode.value}`}
                variant={selected ? "outlined" : "plain"}
                color={selected ? "primary" : "neutral"}
                onClick={() => handleSelectMode(mode.value)}
              >
                <Icon />
              </IconButton>
            );
          })}
        </Box>
      </Stack>
      {loaded && departments?.items?.length === 0 ? (
        <EmptyState description="No departments has been added yet." />
      ) : viewMode === ViewMode.GRID ? (
        loading ? (
          <Loader />
        ) : (
          <>
            <Grid container spacing={6} alignItems="stretch">
              {departments?.items?.map((department) => (
                <Grid
                  xs={12}
                  xl={3}
                  lg={4}
                  md={6}
                  key={`department-${department.id}`}
                  display="flex"
                  alignItems="stretch"
                >
                  <DepartmentCard department={department} onEdit={handleEdit} />
                </Grid>
              ))}
            </Grid>
            <Stack alignItems="center" mt={6}>
              <Pagination
                count={totalPages}
                page={page}
                onChange={(event, page) => handlePaginate(page)}
                color="primary"
                disabled={loading}
              />
            </Stack>
          </>
        )
      ) : (
        <DepartmentsTable
          departments={departments}
          onPaginate={handlePaginate}
          onEdit={handleEdit}
          currentPage={page}
          totalPages={totalPages}
        />
      )}
    </>
  );
};

const Input = styled(DebounceInput)`
  flex: 1;
`;
