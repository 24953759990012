import { PayloadAction } from "@reduxjs/toolkit"
import { Reducer } from "redux"

export default function createReducer<S>(initialState: S, handlers: any): Reducer<S> {
  const r = (state: S = initialState, action: PayloadAction<S>): S => {
    // eslint-disable-next-line no-prototype-builtins
    if (handlers.hasOwnProperty(action.type)) {
      return handlers[action.type](state, action)
    }
    return state
  }

  return r as Reducer<S>
}
