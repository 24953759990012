export interface ITimezone {
  value: string;
  label: string;
}

export const timezones = [
  {
    label: "(GMT-11:00) Pacific/Midway",
    value: "Pacific/Midway",
  },
  {
    label: "(GMT-11:00) US/Samoa",
    value: "US/Samoa",
  },
  {
    label: "(GMT-10:00) US/Hawaii",
    value: "US/Hawaii",
  },
  {
    label: "(GMT-09:00) US/Alaska",
    value: "US/Alaska",
  },
  {
    label: "(GMT-08:00) US/Pacific",
    value: "US/Pacific",
  },
  {
    label: "(GMT-08:00) America/Tijuana",
    value: "America/Tijuana",
  },
  {
    label: "(GMT-07:00) US/Arizona",
    value: "US/Arizona",
  },
  {
    label: "(GMT-07:00) US/Mountain",
    value: "US/Mountain",
  },
  {
    label: "(GMT-07:00) America/Chihuahua",
    value: "America/Chihuahua",
  },
  {
    label: "(GMT-07:00) America/Mazatlan",
    value: "America/Mazatlan",
  },
  {
    label: "(GMT-06:00) America/Mexico_City",
    value: "America/Mexico_City",
  },
  {
    label: "(GMT-06:00) America/Monterrey",
    value: "America/Monterrey",
  },
  {
    label: "(GMT-06:00) Canada/Saskatchewan",
    value: "Canada/Saskatchewan",
  },
  {
    label: "(GMT-06:00) US/Central",
    value: "US/Central",
  },
  {
    label: "(GMT-05:00) US/Eastern",
    value: "US/Eastern",
  },
  {
    label: "(GMT-05:00) US/East-Indiana",
    value: "US/East-Indiana",
  },
  {
    label: "(GMT-05:00) America/Bogota",
    value: "America/Bogota",
  },
  {
    label: "(GMT-05:00) America/Lima",
    value: "America/Lima",
  },
  {
    label: "(GMT-04:30) America/Caracas",
    value: "America/Caracas",
  },
  {
    label: "(GMT-04:00) Canada/Atlantic",
    value: "Canada/Atlantic",
  },
  {
    label: "(GMT-04:00) America/La_Paz",
    value: "America/La_Paz",
  },
  {
    label: "(GMT-04:00) America/Santiago",
    value: "America/Santiago",
  },
  {
    label: "(GMT-03:30) Canada/Newfoundland",
    value: "Canada/Newfoundland",
  },
  {
    label: "(GMT-03:00) America/Buenos_Aires",
    value: "America/Buenos_Aires",
  },
  {
    label: "(GMT-03:00) Greenland",
    value: "Greenland",
  },
  {
    label: "(GMT-02:00) Atlantic/Stanley",
    value: "Atlantic/Stanley",
  },
  {
    label: "(GMT-01:00) Atlantic/Azores",
    value: "Atlantic/Azores",
  },
  {
    label: "(GMT-01:00) Atlantic/Cape_Verde",
    value: "Atlantic/Cape_Verde",
  },
  {
    label: "(GMT) Africa/Casablanca",
    value: "Africa/Casablanca",
  },
  {
    label: "(GMT) Europe/Dublin",
    value: "Europe/Dublin",
  },
  {
    label: "(GMT) Europe/Lisbon",
    value: "Europe/Lisbon",
  },
  {
    label: "(GMT) Europe/London",
    value: "Europe/London",
  },
  {
    label: "(GMT) Africa/Monrovia",
    value: "Africa/Monrovia",
  },
  {
    label: "(UTC+01:00) Europe/Amsterdam",
    value: "Europe/Amsterdam",
  },
  {
    label: "(UTC+01:00) Europe/Belgrade",
    value: "Europe/Belgrade",
  },
  {
    label: "(UTC+01:00) Europe/Berlin",
    value: "Europe/Berlin",
  },
  {
    label: "(UTC+01:00) Europe/Bratislava",
    value: "Europe/Bratislava",
  },
  {
    label: "(UTC+01:00) Europe/Brussels",
    value: "Europe/Brussels",
  },
  {
    label: "(UTC+01:00) Europe/Budapest",
    value: "Europe/Budapest",
  },
  {
    label: "(UTC+01:00) Europe/Copenhagen",
    value: "Europe/Copenhagen",
  },
  {
    label: "(UTC+01:00) Europe/Ljubljana",
    value: "Europe/Ljubljana",
  },
  {
    label: "(UTC+01:00) Europe/Madrid",
    value: "Europe/Madrid",
  },
  {
    label: "(UTC+01:00) Europe/Paris",
    value: "Europe/Paris",
  },
  {
    label: "(UTC+01:00) Europe/Prague",
    value: "Europe/Prague",
  },
  {
    label: "(UTC+01:00) Europe/Rome",
    value: "Europe/Rome",
  },
  {
    label: "(UTC+01:00) Europe/Sarajevo",
    value: "Europe/Sarajevo",
  },
  {
    label: "(UTC+01:00) Europe/Skopje",
    value: "Europe/Skopje",
  },
  {
    label: "(UTC+01:00) Europe/Stockholm",
    value: "Europe/Stockholm",
  },
  {
    label: "(UTC+01:00) Europe/Vienna",
    value: "Europe/Vienna",
  },
  {
    label: "(UTC+01:00) Europe/Warsaw",
    value: "Europe/Warsaw",
  },
  {
    label: "(UTC+01:00) Europe/Zagreb",
    value: "Europe/Zagreb",
  },
  {
    label: "(UTC+02:00) Europe/Athens",
    value: "Europe/Athens",
  },
  {
    label: "(UTC+02:00) Europe/Bucharest",
    value: "Europe/Bucharest",
  },
  {
    label: "(UTC+02:00) Africa/Cairo",
    value: "Africa/Cairo",
  },
  {
    label: "(UTC+02:00) Africa/Harare",
    value: "Africa/Harare",
  },
  {
    label: "(UTC+02:00) Europe/Helsinki",
    value: "Europe/Helsinki",
  },
  {
    label: "(UTC+02:00) Europe/Istanbul",
    value: "Europe/Istanbul",
  },
  {
    label: "(UTC+02:00) Asia/Jerusalem",
    value: "Asia/Jerusalem",
  },
  {
    label: "(UTC+02:00) Europe/Kiev",
    value: "Europe/Kiev",
  },
  {
    label: "(UTC+02:00) Europe/Minsk",
    value: "Europe/Minsk",
  },
  {
    label: "(UTC+02:00) Europe/Riga",
    value: "Europe/Riga",
  },
  {
    label: "(UTC+02:00) Europe/Sofia",
    value: "Europe/Sofia",
  },
  {
    label: "(UTC+02:00) Europe/Tallinn",
    value: "Europe/Tallinn",
  },
  {
    label: "(UTC+02:00) Europe/Vilnius",
    value: "Europe/Vilnius",
  },
  {
    label: "(UTC+03:00) Asia/Baghdad",
    value: "Asia/Baghdad",
  },
  {
    label: "(UTC+03:00) Asia/Kuwait",
    value: "Asia/Kuwait",
  },
  {
    label: "(UTC+03:00) Africa/Nairobi",
    value: "Africa/Nairobi",
  },
  {
    label: "(UTC+03:00) Asia/Riyadh",
    value: "Asia/Riyadh",
  },
  {
    label: "(UTC+03:30) Asia/Tehran",
    value: "Asia/Tehran",
  },
  {
    label: "(UTC+04:00) Europe/Moscow",
    value: "Europe/Moscow",
  },
  {
    label: "(UTC+04:00) Asia/Baku",
    value: "Asia/Baku",
  },
  {
    label: "(UTC+04:00) Europe/Volgograd",
    value: "Europe/Volgograd",
  },
  {
    label: "(UTC+04:00) Asia/Muscat",
    value: "Asia/Muscat",
  },
  {
    label: "(UTC+04:00) Asia/Tbilisi",
    value: "Asia/Tbilisi",
  },
  {
    label: "(UTC+04:00) Asia/Yerevan",
    value: "Asia/Yerevan",
  },
  {
    label: "(UTC+04:30) Asia/Kabul",
    value: "Asia/Kabul",
  },
  {
    label: "(UTC+05:00) Asia/Karachi",
    value: "Asia/Karachi",
  },
  {
    label: "(UTC+05:00) Asia/Tashkent",
    value: "Asia/Tashkent",
  },
  {
    label: "(UTC+05:30) Asia/Kolkata",
    value: "Asia/Kolkata",
  },
  {
    label: "(UTC+05:45) Asia/Kathmandu",
    value: "Asia/Kathmandu",
  },
  {
    label: "(UTC+06:00) Asia/Yekaterinburg",
    value: "Asia/Yekaterinburg",
  },
  {
    label: "(UTC+06:00) Asia/Almaty",
    value: "Asia/Almaty",
  },
  {
    label: "(UTC+06:00) Asia/Dhaka",
    value: "Asia/Dhaka",
  },
  {
    label: "(UTC+07:00) Asia/Novosibirsk",
    value: "Asia/Novosibirsk",
  },
  {
    label: "(UTC+07:00) Asia/Bangkok",
    value: "Asia/Bangkok",
  },
  {
    label: "(UTC+07:00) Asia/Jakarta",
    value: "Asia/Jakarta",
  },
  {
    label: "(UTC+08:00) Asia/Krasnoyarsk",
    value: "Asia/Krasnoyarsk",
  },
  {
    label: "(UTC+08:00) Asia/Chongqing",
    value: "Asia/Chongqing",
  },
  {
    label: "(UTC+08:00) Asia/Hong_Kong",
    value: "Asia/Hong_Kong",
  },
  {
    label: "(UTC+08:00) Asia/Kuala_Lumpur",
    value: "Asia/Kuala_Lumpur",
  },
  {
    label: "(UTC+08:00) Australia/Perth",
    value: "Australia/Perth",
  },
  {
    label: "(UTC+08:00) Asia/Singapore",
    value: "Asia/Singapore",
  },
  {
    label: "(UTC+08:00) Asia/Taipei",
    value: "Asia/Taipei",
  },
  {
    label: "(UTC+08:00) Asia/Ulaanbaatar",
    value: "Asia/Ulaanbaatar",
  },
  {
    label: "(UTC+08:00) Asia/Urumqi",
    value: "Asia/Urumqi",
  },
  {
    label: "(UTC+09:00) Asia/Irkutsk",
    value: "Asia/Irkutsk",
  },
  {
    label: "(UTC+09:00) Asia/Seoul",
    value: "Asia/Seoul",
  },
  {
    label: "(UTC+09:00) Asia/Tokyo",
    value: "Asia/Tokyo",
  },
  {
    label: "(UTC+09:30) Australia/Adelaide",
    value: "Australia/Adelaide",
  },
  {
    label: "(UTC+09:30) Australia/Darwin",
    value: "Australia/Darwin",
  },
  {
    label: "(UTC+10:00) Asia/Yakutsk",
    value: "Asia/Yakutsk",
  },
  {
    label: "(UTC+10:00) Australia/Brisbane",
    value: "Australia/Brisbane",
  },
  {
    label: "(UTC+10:00) Australia/Canberra",
    value: "Australia/Canberra",
  },
  {
    label: "(UTC+10:00) Pacific/Guam",
    value: "Pacific/Guam",
  },
  {
    label: "(UTC+10:00) Australia/Hobart",
    value: "Australia/Hobart",
  },
  {
    label: "(UTC+10:00) Australia/Melbourne",
    value: "Australia/Melbourne",
  },
  {
    label: "(UTC+10:00) Pacific/Port_Moresby",
    value: "Pacific/Port_Moresby",
  },
  {
    label: "(UTC+10:00) Australia/Sydney",
    value: "Australia/Sydney",
  },
  {
    label: "(UTC+11:00) Asia/Vladivostok",
    value: "Asia/Vladivostok",
  },
  {
    label: "(UTC+12:00) Asia/Magadan",
    value: "Asia/Magadan",
  },
  {
    label: "(UTC+12:00) Pacific/Auckland",
    value: "Pacific/Auckland",
  },
  {
    label: "(UTC+12:00) Pacific/Fiji",
    value: "Pacific/Fiji",
  },
] as ITimezone[];
