import React, { FC, useMemo } from "react";
import { IChallenge } from "@store/challenge/model";
import {
  Box,
  Button,
  Chip,
  LinearProgress,
  Stack,
  styled,
  Typography,
} from "@mui/joy";
import { EditOutlined } from "@mui/icons-material";
import moment from "moment";
import {
  calculateProgress,
  getChallengeProgress,
} from "@services/utils/challenge";
import Card from "@mui/joy/Card";

type InfoProps = {
  challenge: IChallenge;
  onOpen: () => void;
};

export const Info: FC<InfoProps> = (props) => {
  const { challenge, onOpen } = props;
  const {
    state,
    image,
    name,
    description,
    repeat,
    backgroundColor,
    startAt,
    endAt,
  } = challenge;
  const { progress, message, color } = useMemo(
    () => getChallengeProgress(state, calculateProgress(startAt, endAt)),
    [state, startAt, endAt],
  );

  return (
    <Card
      sx={{ bgcolor: "white", borderRadius: "xl", p: { xs: 4, md: 6 }, gap: 3 }}
    >
      <Stack direction={{ xs: "column", md: "row" }} gap={6}>
        <ChallengeImageContainer
          sx={{
            backgroundColor: backgroundColor,
            borderRadius: "xl",
          }}
        >
          <ChallengeImage src={image} loading="lazy" alt={name} />
        </ChallengeImageContainer>
        <Box flex="1">
          <Stack gap={3} direction="row" alignItems="center">
            <Box flex="1">
              <Chip color={color} variant="solid">
                {message}
              </Chip>
            </Box>
            <Button
              size="md"
              startDecorator={<EditOutlined />}
              color="primary"
              sx={{ boxShadow: "sm" }}
              onClick={onOpen}
            >
              Edit
            </Button>
          </Stack>
          <Stack gap={3}>
            <Typography level="h1">{challenge.name}</Typography>
            <Stack gap={2} direction="row" alignItems="center">
              <Typography>
                {moment(startAt).format("MMM DD, YYYY")}
                {endAt ? ` - ${moment(endAt).format("MMM DD, YYYY")}` : null}
              </Typography>
              <Separator sx={{ bgcolor: "background.level3" }} />
              <Typography>{repeat ? "Recurring" : "One-off"}</Typography>
            </Stack>
            <LinearProgress
              color={color}
              determinate
              variant="soft"
              value={progress}
              thickness={6}
            />
            <Typography>{description}</Typography>
          </Stack>
        </Box>
      </Stack>
    </Card>
  );
};

const Separator = styled(Box)`
  width: 6px;
  height: 6px;
  border-radius: 50%;
`;

const ChallengeImageContainer = styled(Box)`
  width: 186px;
  height: 186px;
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ theme }) => theme.breakpoints.down("md")} {
    width: 100%;
  }
`;

const ChallengeImage = styled("img")`
  width: 128px;
  object-fit: contain;
  margin: 0 auto;
`;
