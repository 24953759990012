import React, { FC, useCallback, useState } from "react";
import { Autocomplete, CircularProgress, ListItem, styled } from "@mui/joy";
import { CalendarTodayOutlined } from "@mui/icons-material";
import useLoadData from "@/hooks/useLoadData";
import { IChallenge, IChallengeHistory } from "@store/challenge/model";
import {
  getChallengeHistory,
  getChallengeSummary,
  setChallengeSummary,
} from "@store/challenge/actions";
import { useAppSelector } from "@store/hooks";
import {
  selectChallengeHistory,
  selectHistoryPagination,
} from "@store/challenge/selectors";
import useInfiniteScroll from "react-infinite-scroll-hook";
import moment from "moment";
import { isEmpty } from "lodash";
import { useDispatch } from "react-redux";

type HistoryProps = {
  challenge: IChallenge;
};

export const History: FC<HistoryProps> = (props) => {
  const { challenge } = props;
  const [loadingSummary, setLoadingSummary] = useState(false);
  const dispatch = useDispatch<any>();

  const history = useAppSelector(selectChallengeHistory);
  const { loaded, loading, handlePaginate } = useLoadData<IChallengeHistory>(
    getChallengeHistory,
    undefined,
    { challengeId: challenge.challengeId },
  );
  const { limit, offset, page } = useAppSelector(selectHistoryPagination);
  const hasNextPage = history?.total > offset + limit;

  const [sentryRef, { rootRef }] = useInfiniteScroll({
    loading,
    hasNextPage,
    onLoadMore: () => {
      handlePaginate(page + 1, limit);
    },
  });

  const handleRenderSentry = useCallback(() => {
    return hasNextPage || loading ? (
      <ListItem ref={sentryRef} key="sentry">
        <Loader size="sm" />
      </ListItem>
    ) : null;
  }, [hasNextPage, loading]);

  const handleRenderOption = useCallback((optionProps, option) => {
    const { id } = option;

    if (id === "sentry") {
      return handleRenderSentry();
    }

    return (
      <ListItem {...optionProps} key={option.challengeItemId}>
        {option.current ? "Current: " : ""}
        {moment(option.startAt).format("MMM DD")}
        {option.endAt ? ` - ${moment(option.endAt).format("MMM DD")}` : null}
      </ListItem>
    );
  }, []);

  const handleFilterOptions = useCallback(
    (filterOptions) => {
      return [
        { ...challenge, current: true },
        ...filterOptions,
        ...(hasNextPage ? [{ id: "sentry" }] : []),
      ];
    },
    [hasNextPage, challenge],
  );

  const handleSelectOption = useCallback(
    (event, option) => {
      if (option.challengeItemId === challenge.challengeItemId) {
        dispatch(setChallengeSummary(null));
      } else {
        setLoadingSummary(true);
        dispatch(
          getChallengeSummary(
            {
              challengeId: option.challengeId,
              challengeItemId: option.challengeItemId,
            },
            () => {
              setLoadingSummary(false);
            },
          ),
        );
      }
    },
    [challenge],
  );

  return (
    <Autocomplete
      sx={{ minWidth: 320 }}
      slotProps={{
        listbox: {
          ref: rootRef,
        },
        input: {
          readOnly: true,
        },
      }}
      filterOptions={handleFilterOptions}
      renderOption={handleRenderOption}
      options={history?.items || []}
      getOptionLabel={(option) => {
        if (isEmpty(option)) {
          return `Current: ${moment(challenge.startAt).format("MMM DD")}${challenge.endAt ? ` - ${moment(challenge.endAt).format("MMM DD")}` : ""}`;
        }

        return `${option.current ? "Current: " : ""}${moment(option.startAt).format("MMM DD")}${option.endAt ? ` - ${moment(option.endAt).format("MMM DD")}` : ""}`;
      }}
      startDecorator={<CalendarTodayOutlined />}
      endDecorator={loadingSummary ? <Loader size="sm" /> : null}
      defaultValue={[]}
      onChange={handleSelectOption}
      disableClearable
    />
  );
};

const Loader = styled(CircularProgress)`
  margin: 0 auto;
`;
