import { Pagination } from "@services/api/api.types";
import { InviteStatus } from "@constants";

export enum ActionType {
  GET_INVITES = "GET_INVITES",
  CREATE_INVITE = "CREATE_INVITE",
  DECLINE_INVITE = "DECLINE_INVITE",
  SET_INVITES = "SET_INVITES",
  RESET = "RESET_INVITE",
}

export interface IInvitesParams extends Pagination {
  status?: InviteStatus;
  managerId?: string;
  userId?: string;
  phoneNumber?: string;
}

export interface IDeclineInviteParams {
  inviteId: string;
}

export interface ICreateInvitePayload
  extends Array<{
    userId?: string;
    phoneNumber?: string;
    message?: string;
    actions: {
      departmentId?: string;
      teamIds?: string[];
      challengeIds?: string[];
    };
  }> {}

export interface IInvite {
  id: string;
  manager: {
    id: string;
    name: string;
    email: string;
  };
  workspace: {
    id: string;
    name: string;
    description: string;
    logo: string;
  };
  user: {
    id: string;
    phoneNumber: string;
    firstName: string;
    lastName: string;
    avatar: string;
  };
  message: string;
  status: InviteStatus;
  createdAt: Date;
  updatedAt: Date;
}
