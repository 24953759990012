import { AxiosResponse } from "axios";
import { axiosInstance } from "@services/api";
import {
  ApiGetTeamsParams,
  ApiTeamResponse,
  ApiTeamsResponse,
  ApiTeamUsersResponse,
  ApiGetTeamInfoParams,
  ApiGetTeamUsersParams,
  ApiDeleteTeamParams,
  ApiCreateTeamPayload,
  ApiUpdateTeamPayload,
  ApiJoinTeamUsersPayload,
  ApiJoinTeamUsersResponse,
} from "./team.types";

export const getTeams = async (
  params: ApiGetTeamsParams,
): Promise<ApiTeamsResponse> => {
  const response: AxiosResponse<ApiTeamsResponse> = await axiosInstance.get(
    `/team`,
    {
      params,
    },
  );

  return response.data;
};

export const getTeamInfo = async (
  params: ApiGetTeamInfoParams,
): Promise<ApiTeamResponse> => {
  const response: AxiosResponse<ApiTeamResponse> = await axiosInstance.get(
    `/team/${params.teamId}`,
    {
      params,
    },
  );

  return response.data;
};

export const getTeamUsers = async (
  params: ApiGetTeamUsersParams,
): Promise<ApiTeamUsersResponse> => {
  const { teamId, ...restParams } = params;

  const response: AxiosResponse<ApiTeamUsersResponse> = await axiosInstance.get(
    `/team/${teamId}/users`,
    {
      params: restParams,
    },
  );

  return response.data;
};

export const createTeam = async (
  data: ApiCreateTeamPayload,
): Promise<undefined> => {
  const response: AxiosResponse<undefined> = await axiosInstance.post(
    `/team`,
    data,
  );

  return response.data;
};

export const updateTeam = async (
  data: ApiUpdateTeamPayload,
): Promise<undefined> => {
  const { teamId, ...restData } = data;

  const response: AxiosResponse<undefined> = await axiosInstance.put(
    `/team/${teamId}`,
    restData,
  );

  return response.data;
};

export const deleteTeam = async (
  params: ApiDeleteTeamParams,
): Promise<undefined> => {
  const response: AxiosResponse<undefined> = await axiosInstance.delete(
    `/team/${params.teamId}`,
    {
      params,
    },
  );

  return response.data;
};

export const joinTeamUsers = async (
  data: ApiJoinTeamUsersPayload,
): Promise<ApiJoinTeamUsersResponse> => {
  const { id, users } = data;
  const response: AxiosResponse<ApiJoinTeamUsersResponse> =
    await axiosInstance.post(`/team/${id}/join`, users);

  return response.data;
};

export const leaveTeamUsers = async (
  data: ApiJoinTeamUsersPayload,
): Promise<ApiJoinTeamUsersResponse> => {
  const { id, users } = data;
  const response: AxiosResponse<ApiJoinTeamUsersResponse> =
    await axiosInstance.post(`/team/${id}/leave`, users);

  return response.data;
};
