import { RootState } from "@store";
import { DEFAULT_LIMIT } from "@constants";
import { createSelector } from "@reduxjs/toolkit";
import { IDataList } from "@store/model";
import { IUser, IUserTeam } from "@store/user/model";

export const selectUsers = (state: RootState) => {
  return state.user.users;
};

export const selectUserById = (state: RootState, userId: string) => {
  return state.user.users?.items?.find((user) => user.user.id === userId);
};

export const selectUserTeams = (state: RootState) => {
  return state.user.userTeams;
};

export const selectPagination = createSelector(
  (state: RootState) => state.user.users,
  (users: IDataList<IUser>) => ({
    limit: users?.limit || DEFAULT_LIMIT,
    offset: users?.offset || 0,
    totalPages: Math.ceil(users?.total / users?.limit) || 0,
    page: Math.ceil(users?.offset / users?.limit) + 1 || 1,
  }),
);

export const selectUserTeamsPagination = createSelector(
  (state: RootState) => state.user.userTeams,
  (userTeams: IDataList<IUserTeam>) => ({
    limit: userTeams?.limit || DEFAULT_LIMIT,
    offset: userTeams?.offset || 0,
    totalPages: Math.ceil(userTeams?.total / userTeams?.limit) || 0,
    page: Math.ceil(userTeams?.offset / userTeams?.limit) + 1 || 1,
  }),
);
