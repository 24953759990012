import { call, fork, put, select, takeLatest } from "redux-saga/effects";
import { ActionCallback } from "@store/model";
import {
  setTeams,
  setTeamInfo,
  getTeams,
  setTeamMembers,
  getTeamMembers,
} from "@store/team/actions";
import {
  ActionType,
  ITeamsParams,
  ITeamInfoParams,
  ICreateTeamPayload,
  IDeleteTeamParams,
  IUpdateTeamPayload,
  ITeamMembersParams,
  IJoinTeamMembersPayload,
} from "@store/team/model";
import * as api from "@services/api/team";
import { selectTeamMembersPagination } from "@store/team/selectors";

function* getTeamsSaga(): Generator {
  yield takeLatest(
    ActionType.GET_TEAMS,
    function* (action: ActionCallback<ITeamsParams>) {
      const { payload, callback } = action;
      try {
        const data = yield call(api.getTeams, payload);

        yield put(setTeams(data));

        callback?.({
          success: true,
          data,
        });
      } catch (e) {
        callback?.({
          success: false,
        });
      }
    },
  );
}

function* getTeamInfoSaga(): Generator {
  yield takeLatest(
    ActionType.GET_TEAM_INFO,
    function* (action: ActionCallback<ITeamInfoParams>) {
      const { payload, callback } = action;
      try {
        const data = yield call(api.getTeamInfo, payload);

        yield put(setTeamInfo(data));

        callback?.({ success: true, data });
      } catch (e) {
        callback?.({ success: false });
      }
    },
  );
}

function* getTeamMembersSaga(): Generator {
  yield takeLatest(
    ActionType.GET_TEAM_MEMBERS,
    function* (action: ActionCallback<ITeamMembersParams>) {
      const { payload, callback } = action;
      try {
        const data = yield call(api.getTeamUsers, payload);

        yield put(setTeamMembers(data));

        callback?.({
          success: true,
          data,
        });
      } catch (e) {
        callback?.({
          success: false,
        });
      }
    },
  );
}

function* createTeamSaga(): Generator {
  yield takeLatest(
    ActionType.CREATE_TEAM,
    function* (action: ActionCallback<ICreateTeamPayload>) {
      const { payload, callback } = action;
      try {
        yield call(api.createTeam, payload);

        yield put(getTeams({ offset: 0, limit: 10 }));
        callback?.({
          success: true,
        });
      } catch (e) {
        callback?.({
          success: false,
        });
      }
    },
  );
}

function* updateTeamSaga(): Generator {
  yield takeLatest(
    ActionType.UPDATE_TEAM,
    function* (action: ActionCallback<IUpdateTeamPayload>) {
      const { payload, callback } = action;
      try {
        yield call(api.updateTeam, payload);

        yield put(getTeams({ offset: 0, limit: 10 }));
        callback?.({
          success: true,
        });
      } catch (e) {
        callback?.({
          success: false,
        });
      }
    },
  );
}

function* deleteTeamSaga(): Generator {
  yield takeLatest(
    ActionType.DELETE_TEAM,
    function* (action: ActionCallback<IDeleteTeamParams>) {
      const { payload, callback } = action;
      try {
        yield call(api.deleteTeam, payload);

        yield put(getTeams({ offset: 0, limit: 10 }));
        callback?.({
          success: true,
        });
      } catch (e) {
        callback?.({
          success: false,
        });
      }
    },
  );
}

function* joinTeamMembersSaga(): Generator {
  yield takeLatest(
    ActionType.JOIN_TEAM_MEMBERS,
    function* (action: ActionCallback<IJoinTeamMembersPayload>) {
      const { payload, callback } = action;
      const pagination = yield select(selectTeamMembersPagination);
      try {
        yield call(api.joinTeamUsers, payload);

        yield put(
          getTeamMembers({ teamId: payload.id, ...pagination }, callback),
        );
      } catch (e) {
        callback?.({
          success: false,
        });
      }
    },
  );
}

function* leaveTeamMembersSaga(): Generator {
  yield takeLatest(
    ActionType.LEAVE_TEAM_MEMBERS,
    function* (action: ActionCallback<IJoinTeamMembersPayload>) {
      const { payload, callback } = action;
      const pagination = yield select(selectTeamMembersPagination);
      try {
        yield call(api.leaveTeamUsers, payload);

        yield put(
          getTeamMembers({ teamId: payload.id, ...pagination }, callback),
        );
      } catch (e) {
        callback?.({
          success: false,
        });
      }
    },
  );
}

export default [
  fork(getTeamsSaga),
  fork(getTeamInfoSaga),
  fork(getTeamMembersSaga),
  fork(createTeamSaga),
  fork(updateTeamSaga),
  fork(deleteTeamSaga),
  fork(joinTeamMembersSaga),
  fork(leaveTeamMembersSaga),
];
