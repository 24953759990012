import { Gender } from "@constants"

export enum ActionType {
  GET_PROFILE = "GET_PROFILE",
  SAVE_PROFILE = "SAVE_PROFILE",
  DELETE_PROFILE_REQUEST = "DELETE_PROFILE_REQUEST",
  DELETE_PROFILE_CONFIRM = "DELETE_PROFILE_CONFIRM",
  CONFIRM_EMAIL_REQUEST = "CONFIRM_EMAIL_REQUEST",
  CONFIRM_EMAIL_CONFIRM = "CONFIRM_EMAIL_CONFIRM",
  SET_OTP_REFERENCE = "SET_OTP_REFERENCE",
  SET_PROFILE = "SET_PROFILE",
  RESET = "RESET_PROFILE",
}

export interface IConfirmPayload {
  otpCode: string
}

export interface IProfilePayload {
  firstName: string
  lastName: string
  avatar?: string
  email: string
  gender: Gender
  dateOfBirth: string
}

export interface IOtpReference {
  otpReference: string
}

export interface IProfile {
  firstName: string
  lastName: string
  avatar: string
  email: string
  gender: Gender
  dateOfBirth: string
  emailConfirmed: Date
  createdAt: Date
  updatedAt: Date
}

export interface IUser {
  id: string
  phoneNumber: string
  lastSeen: Date
  createdAt: Date
}

export interface IDepartment {
  name: string
  description: string
  logo: string
  id: string
  workspaceId: string
  createdAt: Date
  updatedAt: Date
}

export interface IFullProfile {
  profile: IProfile
  user: IUser
  department: IDepartment
}
