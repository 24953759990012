import { AxiosResponse } from "axios";
import { axiosInstance } from "@services/api";
import {
  ApiGetInvitesParams,
  ApiInvitesResponse,
  ApiDeclineInviteParams,
  ApiCreateInvitePayload,
} from "./invite.types";

export const getInvites = async (
  params: ApiGetInvitesParams,
): Promise<ApiInvitesResponse> => {
  const response: AxiosResponse<ApiInvitesResponse> = await axiosInstance.get(
    `/invite`,
    {
      params,
    },
  );

  return response.data;
};

export const createInvite = async (
  data: ApiCreateInvitePayload,
): Promise<undefined> => {
  const response: AxiosResponse<undefined> = await axiosInstance.post(
    `/invite`,
    data,
  );

  return response.data;
};

export const declineInvite = async (
  params: ApiDeclineInviteParams,
): Promise<undefined> => {
  const response: AxiosResponse<undefined> = await axiosInstance.post(
    `/invite/${params.inviteId}/decline`,
    {
      params,
    },
  );

  return response.data;
};
