import { RootState } from "@store";
import { DEFAULT_LIMIT, LeaderboardType } from "@constants";
import { createSelector } from "@reduxjs/toolkit";
import { IDataList } from "@store/model";
import { IChallenge, IChallengeHistory } from "@store/challenge/model";

export const selectChallenges = (state: RootState) => {
  return state.challenge.challenges;
};

export const selectChallengeInfo = (state: RootState) => {
  return state.challenge.challengeInfo;
};

export const selectChallengeUserLeaderboard = (state: RootState) => {
  return state.challenge.userLeaderboard;
};

export const selectChallengeTeamLeaderboard = (state: RootState) => {
  return state.challenge.teamLeaderboard;
};

const mapTypeToSelector = {
  [LeaderboardType.USER]: selectChallengeUserLeaderboard,
  [LeaderboardType.TEAM]: selectChallengeTeamLeaderboard,
};

export const selectChallengeLeaderboard = (
  state: RootState,
  type: LeaderboardType,
) => {
  if (state.challenge.summary) {
    if (type === LeaderboardType.USER) {
      return {
        items: state.challenge.summary.participants,
        limit: 0,
        offset: 0,
        total: 0,
      };
    }

    if (type === LeaderboardType.TEAM) {
      return {
        items: state.challenge.summary.teams,
        limit: 0,
        offset: 0,
        total: 0,
      };
    }
  }

  return mapTypeToSelector[type](state);
};

export const selectChallengeActivity = (state: RootState) => {
  if (state.challenge.summary) {
    return state.challenge.summary.activity;
  }

  return state.challenge.activity;
};

export const selectChallengeHistory = (state: RootState) => {
  return state.challenge.history;
};

export const selectPagination = createSelector(
  (state: RootState) => state.challenge.challenges,
  (challenges: IDataList<IChallenge>) => ({
    limit: challenges?.limit || DEFAULT_LIMIT,
    offset: challenges?.offset || 0,
    totalPages: Math.ceil(challenges?.total / challenges?.limit) || 0,
    page: Math.ceil(challenges?.offset / challenges?.limit) + 1 || 0,
  }),
);

export const selectHistoryPagination = createSelector(
  (state: RootState) => state.challenge.history,
  (history: IDataList<IChallengeHistory>) => ({
    limit: history?.limit || DEFAULT_LIMIT,
    offset: history?.offset || 0,
    totalPages: Math.ceil(history?.total / history?.limit) || 0,
    page: Math.ceil(history?.offset / history?.limit) + 1 || 0,
  }),
);

export const selectChallengeById = (state: RootState, challengeId: string) => {
  return state.challenge.challenges?.items?.find(
    (challenge) => challenge.challengeId === challengeId,
  );
};
