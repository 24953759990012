import moment from "moment";
import {
  IChallengeLeaderboard,
  IChallengeTeamLeaderboard,
  IChallengeUserLeaderboard,
} from "@store/challenge/model";
import pluralize from "pluralize";
import { ChallengeState } from "@constants";
import { DefaultColorPalette } from "@mui/joy/styles/types/colorSystem";

export const calculateProgress = (startAt: Date, endAt: Date): number => {
  const startDate = moment(startAt);
  const endDate = moment(endAt);
  const now = moment();

  const totalDuration = endDate.diff(startDate, "minutes");
  const elapsed = now.diff(startDate, "minutes");

  let percentage = (elapsed / totalDuration) * 100;
  percentage = Math.min(Math.max(percentage, 0), 100);

  return percentage;
};

export const mapChallengeUserLeaderboard = (
  data: IChallengeUserLeaderboard[],
): IChallengeLeaderboard[] =>
  data.map((user) => ({
    name: `${user.firstName} ${user.lastName}`,
    order: user.userOrder,
    value: user.userValue,
    averageValue: user.userAverageValue,
    image: user.avatar,
    sub: user.department?.name,
  }));

export const mapChallengeTeamLeaderboard = (
  data: IChallengeTeamLeaderboard[],
): IChallengeLeaderboard[] =>
  data.map((team) => ({
    name: team.name,
    order: team.teamOrder,
    value: team.teamValue,
    averageValue: team.teamAverageValue,
    image: team.logo,
    sub: `${team.membersCount} ${pluralize("Member", team.membersCount)}`,
  }));

export const getChallengeProgress = (
  state: ChallengeState,
  progress: number,
): { color: DefaultColorPalette; progress: number; message: string } => {
  switch (state) {
    case ChallengeState.FINISHED:
      return {
        color: "success",
        progress: 100,
        message: "Completed",
      };
    case ChallengeState.IN_PROGRESS:
      return {
        color: "warning",
        progress,
        message: "In Progress",
      };
    case ChallengeState.PENDING:
    default:
      return {
        color: "neutral",
        progress: 0,
        message: "Pending",
      };
  }
};
