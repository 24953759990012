import * as React from "react";
import { styled } from "@mui/joy/styles";
import { useSlotProps } from "@mui/base/utils";
import Stack, { StackProps } from "@mui/joy/Stack";
import FormControl from "@mui/joy/FormControl";
import {
  DateRangePicker,
  DateRangePickerProps,
} from "@mui/x-date-pickers-pro/DateRangePicker";
import { unstable_useMultiInputDateRangeField as useMultiInputDateRangeField } from "@mui/x-date-pickers-pro/MultiInputDateRangeField";
import {
  BaseMultiInputFieldProps,
  DateRange,
  DateRangeValidationError,
  UseDateRangeFieldProps,
  MultiInputFieldSlotTextFieldProps,
  RangeFieldSection,
} from "@mui/x-date-pickers-pro";
import { TextInput } from "@components/form/TextInput";
import { Moment } from "moment";

const MultiInputJoyDateRangeFieldRoot = styled(
  React.forwardRef((props: StackProps, ref: React.Ref<HTMLDivElement>) => (
    <Stack
      ref={ref}
      gap={6}
      direction={{ sx: "column", md: "row" }}
      alignItems={{ sx: "stretch", md: "center" }}
      {...props}
    />
  )),
  {
    name: "MuiMultiInputDateRangeField",
    slot: "Root",
    overridesResolver: (props, styles) => styles.root,
  },
)({});

interface JoyMultiInputDateRangeFieldProps
  extends UseDateRangeFieldProps<Moment>,
    BaseMultiInputFieldProps<
      DateRange<Moment>,
      Moment,
      RangeFieldSection,
      DateRangeValidationError
    > {}

type JoyMultiInputDateRangeFieldComponent = ((
  props: JoyMultiInputDateRangeFieldProps & React.RefAttributes<HTMLDivElement>,
) => React.JSX.Element) & { propTypes?: any };

const JoyMultiInputDateRangeField = React.forwardRef(
  (props: JoyMultiInputDateRangeFieldProps, ref: React.Ref<HTMLDivElement>) => {
    const {
      slotProps,
      value,
      defaultValue,
      format,
      onChange,
      readOnly,
      disabled,
      onError,
      shouldDisableDate,
      minDate,
      maxDate,
      disableFuture,
      disablePast,
      selectedSections,
      onSelectedSectionsChange,
      className,
    } = props;

    const { inputRef: startInputRef, ...startTextFieldProps } = useSlotProps({
      elementType: FormControl,
      externalSlotProps: slotProps?.textField,
      ownerState: { ...props, position: "start" },
    }) as MultiInputFieldSlotTextFieldProps;

    const { inputRef: endInputRef, ...endTextFieldProps } = useSlotProps({
      elementType: FormControl,
      externalSlotProps: slotProps?.textField,
      ownerState: { ...props, position: "end" },
    }) as MultiInputFieldSlotTextFieldProps;

    const {
      startDate: { ref: startRef, ...startDateProps },
      endDate: { ref: endRef, ...endDateProps },
    } = useMultiInputDateRangeField<Moment, MultiInputFieldSlotTextFieldProps>({
      sharedProps: {
        value,
        defaultValue,
        format,
        onChange,
        readOnly,
        disabled,
        onError,
        shouldDisableDate,
        minDate,
        maxDate,
        disableFuture,
        disablePast,
        selectedSections,
        onSelectedSectionsChange,
      },
      startTextFieldProps,
      endTextFieldProps,
      startInputRef,
      endInputRef,
    });

    return (
      <MultiInputJoyDateRangeFieldRoot ref={ref} className={className}>
        <TextInput
          {...startDateProps}
          label="Start Date"
          slotProps={{
            input: {
              ref: startRef,
            },
          }}
        />
        <TextInput
          {...endDateProps}
          label="End Date"
          slotProps={{
            input: {
              ref: endRef,
            },
          }}
        />
      </MultiInputJoyDateRangeFieldRoot>
    );
  },
) as JoyMultiInputDateRangeFieldComponent;

export const JoyDateRangePicker = React.forwardRef(
  (props: DateRangePickerProps<Moment>, ref: React.Ref<HTMLDivElement>) => {
    return (
      <DateRangePicker
        ref={ref}
        {...props}
        slots={{ ...props?.slots, field: JoyMultiInputDateRangeField }}
      />
    );
  },
);
