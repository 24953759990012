import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import TagManager from "react-gtm-module";
import { PersistGate } from "redux-persist/integration/react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { Experimental_CssVarsProvider as MaterialCssVarsProvider } from "@mui/material/styles";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import {
  CssVarsProvider,
  THEME_ID,
  extendTheme as extendJoyTheme,
  styled,
  Sheet,
} from "@mui/joy";
import { ConfirmProvider } from "mui-joy-confirm";
import { SnackbarProvider } from "notistack";

import reportWebVitals from "@/reportWebVitals";
import { persistor, store } from "@store";

import { Sidebar } from "@components/Sidebar";
import { Challenges } from "@containers/Challenges";
import { Challenge } from "@containers/Challenge";
import { Rewards } from "@containers/Rewards";
import { Reward } from "@containers/Reward";
import { Organization } from "@containers/Organization";
import { People } from "@containers/People";
import { AuthWrapper } from "@containers/Auth";

import "@fontsource/inter";
import "@/index.css";
import { Tabs } from "@components/Tabs";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

const joyTheme = extendJoyTheme({
  spacing: 4,
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1900,
    },
  },
  colorSchemes: {
    light: {
      palette: {
        primary: {
          50: "#F4F3FF",
          100: "#EBE9FE",
          200: "#D9D6FE",
          300: "#BDB4FE",
          400: "#9B8AFB",
          500: "#7A5AF8",
          600: "#6938EF",
          700: "#5925DC",
          800: "#4A1FB8",
          900: "#3E1C96",
        },
        neutral: {
          50: "#F9FAFB",
          100: "#F3F4F6",
          200: "#E5E7EB",
          300: "#D2D6DB",
          400: "#9DA4AE",
          500: "#6C737F",
          600: "#4D5761",
          700: "#384250",
          800: "#1F2A37",
          900: "#111927",
        },
        danger: {
          50: "#FEF3F2",
          100: "#FEE4E2",
          200: "#FECDCA",
          300: "#FDA29B",
          400: "#F97066",
          500: "#F04438",
          600: "#D92D20",
          700: "#B42318",
          800: "#912018",
          900: "#7A271A",
        },
        success: {
          50: "#ECFDF3",
          100: "#D1FADF",
          200: "#A6F4C5",
          300: "#6CE9A6",
          400: "#32D583",
          500: "#12B76A",
          600: "#039855",
          700: "#027A48",
          800: "#05603A",
          900: "#054F31",
        },
        warning: {
          50: "#FFFAEB",
          100: "#FEF0C7",
          200: "#FEDF89",
          300: "#FEC84B",
          400: "#FDB022",
          500: "#F79009",
          600: "#DC6803",
          700: "#B54708",
          800: "#93370D",
          900: "#7A2E0E",
        },
        text: {
          icon: "#384250",
          primary: "#111927",
          secondary: "#384250",
          tertiary: "#6C737F",
        },
        common: {
          white: "#FFFFFF",
          black: "#000000",
        },
      },
    },
  },
  components: {
    JoyTabList: {
      styleOverrides: {
        root: ({ theme }) => ({
          gap: theme.spacing(6),
        }),
      },
    },
    JoyTab: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          color: theme.vars.palette.text.tertiary,
          gap: 6,
          backgroundColor: "transparent !important",
          [`&:hover, &[aria-selected="true"]`]: {
            color: theme.vars.palette.primary[600],
          },
        }),
      },
    },
    JoyLinearProgress: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          "&:before": {
            ...(ownerState.color === "warning" && {
              background: theme.vars.palette.warning[600],
            }),
            ...(ownerState.color === "success" && {
              background: theme.vars.palette.success[600],
            }),
          },
        }),
      },
    },
    JoyDrawer: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          ".MuiDrawer-content": {
            ...(ownerState.size === "md" && {
              width: 640,
              [theme.breakpoints.down("md")]: {
                width: "100%",
              },
            }),
          },
        }),
      },
    },
    JoyIconButton: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          backgroundColor: "#FFFFFF",
        }),
      },
    },
    JoyCard: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          backgroundColor: "#FFFFFF",
        }),
      },
    },
    JoyTable: {
      styleOverrides: {
        root: ({ theme, ownerState }) => ({
          backgroundColor: "#FFFFFF",
          "--TableCell-paddingX": theme.spacing(4),
          "--TableCell-paddingY": theme.spacing(4),
          "--TableCell-headBackground": theme.palette.neutral["50"],
        }),
      },
    },
  },
  shadow: {
    xs: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
    sm: "0px 1px 3px 0px rgba(16, 24, 40, 0.10)",
    md: "0px 4px 10px -2px rgba(16, 24, 40, 0.10)",
    lg: "0px 12px 16px -4px rgba(16, 24, 40, 0.10)",
    xl: "0px 24px 20px -4px rgba(16, 24, 40, 0.10)",
  },
});

const Container = styled(Sheet)`
  flex: 1;
  box-sizing: border-box;
  max-width: calc(100% - 260px);
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(6)};
  padding: ${({ theme }) => theme.spacing(8)}
    ${({ theme }) => theme.spacing(14)};

  ${({ theme }) => theme.breakpoints.down("lg")} {
    max-width: calc(100% - 200px);
  }

  ${({ theme }) => theme.breakpoints.down("md")} {
    max-width: 100%;
    padding: ${({ theme }) => theme.spacing(22)}
      ${({ theme }) => theme.spacing(4)} ${({ theme }) => theme.spacing(20)};
  }
`;

const tagManagerArgs = {
  gtmId: "GTM-PNBSRRRG",
};

TagManager.initialize(tagManagerArgs);

root.render(
  <MaterialCssVarsProvider>
    <CssVarsProvider theme={{ [THEME_ID]: joyTheme }}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <BrowserRouter>
              <ConfirmProvider>
                <SnackbarProvider>
                  <AuthWrapper>
                    <Sidebar />
                    <Container>
                      <Routes>
                        <Route path="/challenges" element={<Challenges />} />
                        <Route
                          path="/challenges/:challengeId"
                          element={<Challenge />}
                        />
                        <Route path="/people" element={<People />} />
                        <Route path="/rewards" element={<Rewards />} />
                        <Route path="/rewards/:id" element={<Reward />} />
                        <Route
                          path="/organization"
                          element={<Organization />}
                        />
                        <Route
                          path="*"
                          element={<Navigate to="/challenges" />}
                        />
                      </Routes>
                    </Container>
                    <Tabs />
                  </AuthWrapper>
                </SnackbarProvider>
              </ConfirmProvider>
            </BrowserRouter>
          </PersistGate>
        </Provider>
      </LocalizationProvider>
    </CssVarsProvider>
  </MaterialCssVarsProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
