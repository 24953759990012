import { PayloadAction } from "@reduxjs/toolkit";
import createReducer from "@store/createReducer";
import { IDataList } from "@store/model";
import {
  ActionType,
  IWorkspace,
  IWorkspaceManager,
} from "@store/workspace/model";

export interface WorkspaceState {
  workspaces: IDataList<IWorkspace>;
  activeWorkspace: IWorkspace;
  managers: IDataList<IWorkspaceManager>;
}

const defaultState: WorkspaceState = {
  workspaces: null,
  activeWorkspace: null,
  managers: null,
};

export const workspaceReducer = createReducer<WorkspaceState>(defaultState, {
  [ActionType.SET_WORKSPACES](
    state: WorkspaceState,
    action: PayloadAction<IDataList<IWorkspace>>,
  ) {
    return { ...state, workspaces: action.payload };
  },
  [ActionType.SET_ACTIVE_WORKSPACE](
    state: WorkspaceState,
    action: PayloadAction<IWorkspace>,
  ) {
    return { ...state, activeWorkspace: action.payload };
  },
  [ActionType.SET_WORKSPACE_MANAGERS](
    state: WorkspaceState,
    action: PayloadAction<IDataList<IWorkspaceManager>>,
  ) {
    return { ...state, managers: action.payload };
  },
  [ActionType.RESET]() {
    return defaultState;
  },
});
