import { AxiosResponse } from "axios";
import { axiosInstance } from "@services/api";
import {
  ApiWorkspacesResponse,
  ApiUpdateWorkspacePayload,
  ApiGetWorkspaceManagersParams,
  ApiWorkspaceResponse,
  ApiWorkspaceManagersResponse,
} from "./workspace.types";

export const getWorkspaces = async (): Promise<ApiWorkspacesResponse> => {
  const response: AxiosResponse<ApiWorkspacesResponse> =
    await axiosInstance.get(`/workspace`);

  return response.data;
};

export const getWorkspaceManagers = async (
  params: ApiGetWorkspaceManagersParams,
): Promise<ApiWorkspaceManagersResponse> => {
  const response: AxiosResponse<ApiWorkspaceManagersResponse> =
    await axiosInstance.get(`/workspace/managers`, {
      params,
    });

  return response.data;
};

export const updateWorkspace = async (
  data: ApiUpdateWorkspacePayload,
): Promise<ApiWorkspaceResponse> => {
  const response: AxiosResponse<ApiWorkspaceResponse> =
    await axiosInstance.post(`/workspace`, data);

  return response.data;
};
