import React, { FC, useCallback, useMemo } from "react";
import Card from "@mui/joy/Card";
import CardContent from "@mui/joy/CardContent";
import CardOverflow from "@mui/joy/CardOverflow";
import Divider from "@mui/joy/Divider";
import Typography from "@mui/joy/Typography";
import {
  Avatar,
  AvatarGroup,
  Box,
  IconButton,
  LinearProgress,
  Stack,
  styled,
} from "@mui/joy";
import { BarChartOutlined, EditOutlined } from "@mui/icons-material";
import { IChallenge } from "@store/challenge/model";
import moment from "moment";
import {
  calculateProgress,
  getChallengeProgress,
} from "@services/utils/challenge";
import { useNavigate } from "react-router";

type ChallengeCardProps = {
  challenge: IChallenge;
  onEdit: (challenge: IChallenge) => void;
};

export const ChallengeCard: FC<ChallengeCardProps> = (props) => {
  const { challenge, onEdit } = props;
  const navigate = useNavigate();

  const {
    challengeId,
    name,
    image,
    backgroundColor,
    participants,
    participantsCount,
    closed,
    startAt,
    endAt,
    repeat,
    state,
  } = challenge;

  const handleOpen = useCallback(() => {
    navigate(`/challenges/${challengeId}`);
  }, [challengeId]);

  const handleEdit = useCallback(() => {
    onEdit(challenge);
  }, [challengeId]);

  const { progress, color } = useMemo(
    () => getChallengeProgress(state, calculateProgress(startAt, endAt)),
    [state, startAt, endAt],
  );

  return (
    <Container onClick={handleOpen}>
      <CardOverflow sx={{ backgroundColor: backgroundColor, padding: 4 }}>
        <ChallengeImage src={image} loading="lazy" alt={challenge.name} />
      </CardOverflow>
      <CardContent sx={{ px: 2, py: 1, gap: 2 }}>
        <Typography level="title-lg" fontWeight="600">
          {name}
        </Typography>
        <Stack direction="row" alignItems="center" gap={2}>
          <Typography level="body-md">Frequency</Typography>
          <Separator sx={{ bgcolor: "background.level3" }} />
          <Typography level="body-md">
            {repeat ? "Recurring" : "One-off"}
          </Typography>
        </Stack>
        <LinearProgress
          color={color}
          determinate
          variant="soft"
          value={progress}
          thickness={6}
          sx={{
            flex: 0,
          }}
        />
        <Typography level="body-md">
          {moment(startAt).format("MMM DD, YYYY")}
          {endAt ? ` - ${moment(endAt).format("MMM DD, YYYY")}` : null}
        </Typography>
        <AvatarGroup size="sm" sx={{ ml: "auto" }}>
          {participants.map((participant) => (
            <Avatar
              key={`challenge-${challengeId}-participant-${participant.userId}`}
              alt={`${participant.firstName} ${participant.lastName}`}
              src={participant.avatar}
            />
          ))}
          {participants?.length < participantsCount ? (
            <Avatar>+{participantsCount - participants?.length}</Avatar>
          ) : null}
        </AvatarGroup>
      </CardContent>
      <CardOverflow
        sx={{ cursor: "default" }}
        onClick={(e) => e.stopPropagation()}
      >
        <Divider inset="context" />
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="flex-end"
          gap={2}
        >
          <IconButton size="lg" onClick={handleOpen}>
            <BarChartOutlined />
          </IconButton>
          <IconButton size="lg" onClick={handleEdit}>
            <EditOutlined />
          </IconButton>
        </Stack>
      </CardOverflow>
    </Container>
  );
};

const Container = styled(Card)`
  flex: 1;
  height: 100%;
  box-sizing: border-box;
  border-radius: ${({ theme }) => theme.radius.xl};
  cursor: pointer;
`;

const Separator = styled(Box)`
  width: 6px;
  height: 6px;
  border-radius: 50%;
`;

const ChallengeImage = styled("img")`
  width: 128px;
  object-fit: contain;
  margin: 0 auto;
`;
