import { PayloadAction } from "@reduxjs/toolkit"
import createReducer from "@store/createReducer"
import {
  ActionType,
  IDepartment,
  IFullProfile,
  IOtpReference,
  IProfile,
  IUser,
} from "@store/profile/model"

export interface ProfileState {
  profile: IProfile
  user: IUser
  department: IDepartment
  otpReference: string
}

const defaultState: ProfileState = {
  profile: null,
  user: null,
  department: null,
  otpReference: null,
}

export const profileReducer = createReducer<ProfileState>(defaultState, {
  [ActionType.SET_OTP_REFERENCE](state: ProfileState, action: PayloadAction<IOtpReference>) {
    return { ...state, otpReference: action.payload.otpReference }
  },
  [ActionType.SET_PROFILE](state: ProfileState, action: PayloadAction<IFullProfile>) {
    return {
      ...state,
      profile: action.payload.profile,
      user: action.payload.user,
      department: action.payload.department,
    }
  },
  [ActionType.RESET]() {
    return defaultState
  },
})
